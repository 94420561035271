<template>
    <div>
        <Navigation />
        <Loader :loading="loading.isLoading" />
        <NotificationBox :isVisible="notify.isVisible" :type="notify.type" :title="notify.title" :message="notify.message"
            @notificationIsVisible="notifyIsVisible()" />
        <div v-if="isCheckoutPopupIsVisible">
            <CheckoutPopup :itemsData="itemsData" :subTotal="subTotal" :discount="discount" :total="total"
                @closeCheckoutPopup="closeCheckoutPopup()" />
        </div>
        <div class="main bg-gradient">
            <ItemLoadingSkeleton v-if="loading.isItemDataLoading" />
            <div class="top-container res-px" id="item-col-light" :style="{ display: itemData === null ? 'none' : '' }">
                <div class="image-content-wrapper container">
                    <div class="images-container">
                        <div class="image-preview-box">
                            <div class="bg-circle item-col-bg"></div>
                            <img class="img-preview" :src="mainImg" alt="">
                        </div>
                        <div class="sub-images-wrapper">
                            <button @click="selectMainImg(1)" class="btn-sub-images">
                                <div class="sub-img-box">
                                    <img class="img-sub" :src="itemData?.img_path_1" alt="">
                                </div>
                                <div v-if="selectedImgIndex === 1" class="sub-img-select-indicator"></div>
                            </button>
                            <button @click="selectMainImg(2)" class="btn-sub-images">
                                <div class="sub-img-box">
                                    <img class="img-sub" :src="itemData?.img_path_2" alt="">
                                </div>
                                <div v-if="selectedImgIndex === 2" class="sub-img-select-indicator"></div>
                            </button>
                            <button @click="selectMainImg(3)" class="btn-sub-images">
                                <div class="sub-img-box">
                                    <img class="img-sub" :src="itemData?.img_path_3" alt="">
                                </div>
                                <div v-if="selectedImgIndex === 3" class="sub-img-select-indicator"></div>
                            </button>
                            <button @click="selectMainImg(4)" class="btn-sub-images">
                                <div class="sub-img-box">
                                    <img class="img-sub" :src="itemData?.img_path_4" alt="">
                                </div>
                                <div v-if="selectedImgIndex === 4" class="sub-img-select-indicator"></div>
                            </button>
                        </div>
                    </div>
                    <div class="content-container">
                        <!-- <div class="share-container">
                            <button class="btn-share-icon">
                                <font-awesome-icon class="f-icon" :icon="['fa', 'share-alt']" />
                            </button>
                            <div class="share-social-btns-wrapper">

                            </div>
                        </div> -->
                        <p class="txt-title">{{ itemData?.name }}</p>
                        <!-- <p class="txt-type">{{ itemData?.type }}</p> -->
                        <p class="txt-price item-col-txt"><span class="txt-lkr">LKR.</span>{{ Math.round(itemData?.price -
                            (itemData?.price * itemData?.discount / 100)).toLocaleString() }}</p>
                        <div class="size-wrapper">
                            <p v-if="itemData?.sizes !== 'all'" class="txt-select-size">select a size:</p>
                            <div class="sizes-wraper">
                                <button v-for="(item, index) in itemData?.sizes" :key="index" @click="sizeSelect(index)"
                                    :class="selectedSizeIndex === index ? 'btn-size-selected' : 'btn-size'" id="btnSize">{{
                                        item.size
                                    }}</button>
                            </div>
                        </div>
                        <p v-if="selectedSizeStock !== null">Stock: {{ selectedSizeStock }}</p>
                        <div class="quantity-box item-col-bg"
                            :class="itemData?.sizes !== 'all' && selectedSizeIndex === null ? 'el-dissabled' : ''">
                            <div></div>
                            <p class="txt-quantity">Quantity</p>
                            <div class="qty-btns-wrapper">
                                <button
                                    :class="itemData?.sizes !== 'all' && selectedSizeIndex === null ? 'el-dissabled' : ''"
                                    @click="decreaseQuantity()">
                                    <font-awesome-icon class="f-icon" :icon="['fa', 'minus']" />
                                </button>
                                <div class="item-col-bg">{{ quantity }}</div>
                                <button
                                    :class="itemData?.sizes !== 'all' && selectedSizeIndex === null ? 'el-dissabled' : ''"
                                    @click="increaseQuantity()">
                                    <font-awesome-icon class="f-icon" :icon="['fa', 'plus']" />
                                </button>
                            </div>
                        </div>
                        <div class="cart-buy-btns-wrapper">
                            <button @click="addToCart()" class="btn-add-to-cart item-col-bg"
                                :class="itemData?.sizes !== 'all' && selectedSizeIndex === null ? 'el-dissabled' : ''">
                                <font-awesome-icon class="f-icon" :icon="['fa', 'cart-plus']" />
                            </button>
                            <button @click="buyNow()" class="btn-buy-now item-col-bg"
                                :class="itemData?.sizes !== 'all' && selectedSizeIndex === null ? 'el-dissabled' : ''">Buy
                                Now</button>
                            <button @click="customizeClick()"
                                class="btn-buy-now item-col-bg" :class="itemData?.category !== 'shoes' ? 'el-dissabled' : ''">Customize</button>
                        </div>
                        <button class="btn-download-psd item-col-bg" :class="itemData?.psd === null ? 'el-dissabled' : ''">Download Customizable PSD</button>
                    </div>
                </div>
            </div>
            <div class="bottom-container res-px">
                <ItemDescriptionFAQSkeleton v-if="isQNALoading" />
                <div v-if="!isQNALoading" class="item-desc-faq-wrapper">
                    <div class="description-wrapper">
                        <p class="txt-lg">Product Description</p><br>
                        <div v-html="itemData?.description"></div>
                    </div>
                    <div ref="QnA" class="faq-wrapper">
                        <p class="txt-lg">Questions about product</p>
                        <div class="hor-line"></div>
                        <p v-if="QNAs.length === 0" class="txt-no-question">No Any Questions</p>
                        <div v-for="(item, index) in QNAs" :key="index" class="qa-box">
                            <!-- <p class="txt-name"></p> -->
                            <p class="txt-question">Q) {{ item.question }}</p>
                            <p class="txt-answer txt-sm">A) {{ item.answer }}</p>
                        </div>
                        <div class="load-more-add-q-btn-wrapper">
                            <div class="add-question-wrapper">
                                <input v-model="question" type="text" name="" id="" placeholder="Type your question here">
                                <button @click="addQuestion()">Ask</button>
                            </div>
                            <!-- <button class="btn-load-all-questions">Load all...</button> -->
                        </div>
                        <p v-if="errors.question !== ''" class="txt-error txt-sm">{{ errors.question }}</p>
                    </div>
                </div>
                <div ref="mostBought" class="recommanded-items-wrapper">
                    <p class="txt-lg">90% People Also Bought</p><br>
                    <SamlpesSkeleton v-if="loading.isMostBoughtItemsLoading === true" />
                    <div class="rec-items-box">
                        <Samples :items="recommandedItems" :btnVisible="false" :categoryLink="'/'" />
                    </div>
                </div>
                <!-- <div ref="testObs" class="pre-designed-wrapper">
                    <p class="txt-lg">Pre-Design Shoes</p><br>
                    <Samples :items="preDesigned" :btnVisible="false" :categoryLink="'/'" />
                </div> -->
            </div>
        </div>
    </div>
</template>

<script>
/* eslint-disable */
import { useHead } from '@vueuse/head';
import Navigation from '../components/common/navigation-com.vue';
import { getItemData, addToCart, getFAQ, addFAQ, getRecommandedItems, getFeaturedItems } from '../services/api';
import Loader from '../components/common/loading-com.vue';
import NotificationBox from '../components/common/notification-box-com.vue';
import { setToken, createObserver } from '../utils/helper';
import CheckoutPopup from '../components/common/checkout-popup-com.vue';
import ItemCard from '../components/common/item-card-com.vue';
import Samples from '../components/common/samples-com.vue';
import ItemLoadingSkeleton from '../components/skeleton/item-preview/item-preview-imgs-content-skel.vue';
import ItemDescriptionFAQSkeleton from '../components/skeleton/item-preview/description-faq-skel.vue';
import SamlpesSkeleton from '../components/skeleton/samples-skel.vue';

export default {

    data() {
        return {
            loading: {
                isLoading: false,
                isItemDataLoading: false,
                isQNALoading: false,
                isMostBoughtItemsLoading: true,
                isPreDesigningItemsLoading: false,
            },
            notify: { isVisible: false, type: '', title: '', message: '' },
            token: null,
            itemData: null,
            mainImg: null,
            selectedImgIndex: 1,
            selectedSize: null,
            selectedSizeStock: null,
            selectedSizeIndex: null,
            quantity: 1,
            isCheckoutPopupIsVisible: false,
            subTotal: 0,
            discount: 0,
            discount: 0,
            itemsData: [],
            recommandedItems: [],
            preDesignShoes: [],
            QNAs: [],
            question: '',
            errors: {
                question: '',
            }
        }
    },

    components: {
        Navigation,
        Loader,
        NotificationBox,
        CheckoutPopup,
        ItemCard,
        Samples,
        ItemLoadingSkeleton,
        ItemDescriptionFAQSkeleton,
        SamlpesSkeleton,
    },

    mounted() {
        this.token = setToken();
        this.getItemData();
        createObserver(this.$refs.QnA, () => this.getQNA());
        createObserver(this.$refs.mostBought, () => this.getRecommandedItems());
        this.setMetaData();
    },

    methods: {
        setMetaData() {
            if (this.itemData) {
                useHead({
                    title: `SHOP | ${this.itemData.name}`,
                    meta: [
                        {
                            name: 'description',
                            content: ``
                        },
                    ],
                });
            } else {
                useHead({
                    title: `SHOP | CUSTOM SNEAKERS`,
                    meta: [
                        {
                            name: 'description',
                            content: ``
                        },
                    ],
                });
            }
        },

        notifyIsVisible(value) {
            this.notify = { isVisible: value, type: '', title: '', message: '' };
        },

        colorApply(colorDark, lightColor) {
            const itemColorDList = document.querySelectorAll('.item-col-bg');
            itemColorDList.forEach((itemColorD) => {
                itemColorD.style.backgroundColor = colorDark;
            });

            const itemColorDListFont = document.querySelectorAll('.item-col-txt');
            itemColorDListFont.forEach((itemColorDFont) => {
                itemColorDFont.style.color = colorDark;
            });

            const itemColorLight = document.getElementById('item-col-light');
            itemColorLight.style.backgroundColor = lightColor;

        },

        async getItemData() {
            try {
                this.loading.isItemDataLoading = true;
                const itemId = this.$route.params.itemId;
                const response = await getItemData(itemId);
                this.itemData = response.data;
                this.mainImg = this.itemData.img_path_1;
                this.colorApply(this.itemData.color_code.colorDark, this.itemData.color_code.colorLight);
                this.loading.isItemDataLoading = false;
            } catch (error) {
                this.$router.push({ name: 'PageNotFound' });
            }
        },

        async getQNA() {
            const itemId = this.$route.params.itemId;
            try {
                this.isQNALoading = true;
                const response = await getFAQ(itemId);
                this.QNAs = response.data;
                this.isQNALoading = false;
            } catch (error) {
                this.isQNALoading = false;
                this.QNAs = [];
            }
        },

        async getRecommandedItems() {
            try {
                this.loading.isMostBoughtItemsLoading = true;
                const response = await getRecommandedItems();
                this.recommandedItems = response.data;
                this.loading.isMostBoughtItemsLoading = false;
            } catch (error) {
                this.recommandedItems = [];
                this.loading.isMostBoughtItemsLoading = false;
            }
        },

        async getFeaturedItems() {
            try {
                const response = await getFeaturedItems('');
            } catch (error) {

            }
        },

        selectMainImg(index) {
            if (index === 2 && this.itemData.img_path_2 !== null) {
                this.selectedImgIndex = 2;
                this.mainImg = this.itemData.img_path_2;
            } else if (index === 3 && this.itemData.img_path_3 !== null) {
                this.selectedImgIndex = 3;
                this.mainImg = this.itemData.img_path_3;
            } else if (index === 4 && this.itemData.img_path_4 !== null) {
                this.selectedImgIndex = 4;
                this.mainImg = this.itemData.img_path_4;
            } else {
                this.selectedImgIndex = 1;
                this.mainImg = this.itemData.img_path_1;
            }
        },

        async sizeSelect(index) {
            this.selectedSizeIndex = index;
            this.selectedSize = await this.itemData.sizes[index].size;
            this.selectedSizeStock = await this.itemData.sizes[index].stock;
        },

        increaseQuantity() {
            if (this.itemData.sizes !== 'all') {
                if (this.selectedSizeIndex !== null) {
                    if (this.itemData.sizes[this.selectedSizeIndex].stock > this.quantity) {
                        this.quantity += 1;
                    }
                }
            } else {
                this.quantity += 1;
            }
        },

        decreaseQuantity() {
            if (this.itemData.sizes !== 'all') {
                if (this.selectedSizeIndex !== null) {
                    if (this.quantity > 1) {
                        this.quantity -= 1;
                    }
                }
            } else {
                if (this.quantity > 1) {
                    this.quantity -= 1;
                }
            }
        },

        async addToCart() {

            if (this.token === null) {
                this.$router.push('/login');
            } else {
                if (this.itemData.sizes !== 'all') {
                    if (this.selectedSize !== null && this.selectedSizeStock !== null) {
                        this.loading.isLoading = true
                        const postData = { userId: this.token, itemId: this.itemData.id, quantity: this.quantity, size: this.selectedSize };
                        try {
                            const response = await addToCart(postData);
                            this.loading.isLoading = false;
                            this.notify = { isVisible: true, type: 'success', title: response.data.title, message: response.data.message };
                        } catch (error) {
                            this.loading.isLoading = false;
                            this.notify = { isVisible: true, type: 'error', title: error.response.data.title, message: error.response.data.message };
                        }
                    }
                } else {
                    this.loading.isLoading = true
                    const postData = { userId: this.token, itemId: this.itemData.id, quantity: this.quantity, size: null };
                    try {
                        const response = await addToCart(postData);
                        this.loading.isLoading = false;
                        this.notify = { isVisible: true, type: 'success', title: response.data.title, message: response.data.message };
                    } catch (error) {
                        this.loading.isLoading = false;
                        this.notify = { isVisible: true, type: 'error', title: error.response.data.title, message: error.response.data.message };
                    }
                }
            }
        },

        buyNow() {
            if (this.token === null) {
                this.$router.push('/login');
            } else {
                if (this.selectedSize !== null && this.selectedSizeStock !== null) {
                    const itemsData = [{
                        type: 'item',
                        id: null,
                        userId: this.token,
                        itemId: this.itemData.id,
                        customizeId: null,
                        itemName: this.itemData.name,
                        size: this.selectedSize,
                        quantity: this.quantity,
                        discount: this.itemData.discount,
                        price: this.itemData.price
                    }];
                    this.itemsData = itemsData;
                    this.subTotal = this.itemData.price * this.quantity;
                    this.total = Math.round(this.itemData.price - (this.itemData.price * (this.itemData.discount / 100))) * this.quantity;
                    this.discount = this.subTotal - this.total
                    this.isCheckoutPopupIsVisible = true;
                }
            }
        },

        customizeClick() {
            if(this.itemData.category === 'shoes') {
                this.$router.push({ path: '/customize/', query: { id: this.itemData.id } })
            }
        },

        closeCheckoutPopup(value) {
            this.isCheckoutPopupIsVisible = value;
        },

        async addQuestion() {
            if (this.token !== null) {
                if (this.question === '') {
                    this.errors.question = 'Question is required!';
                } else {
                    this.loading.isLoading = true;
                    this.errors.question = '';
                    const postData = {
                        userId: this.token,
                        itemId: this.itemData.id,
                        question: this.question,
                    }
                    try {
                        const response = await addFAQ(postData);
                        this.question = '';
                        this.notify = { isVisible: true, type: 'success', title: response.data.title, message: response.data.message };
                    } catch (error) {
                        this.notify = { isVisible: true, type: 'error', title: error.response.data.title, message: error.response.data.message };
                    }
                    this.loading.isLoading = false;
                }
            } else {
                this.$router.push('/login');
            }
        }

    },
}
</script>
<style scoped>
.txt-error {
    padding-top: 3px;
    padding-left: 3px;
    color: red;
}

.el-dissabled {
    opacity: .5;
    cursor: not-allowed;
}

.main {
    min-height: 100vh;
}

.top-container {
    padding-top: 170px;
    padding-bottom: 50px;
}

.image-content-wrapper {
    display: flex;
}

.images-container {
    width: 40%;
    margin-right: 50px;
    flex-shrink: 0;
}

.image-preview-box {
    width: 100%;
    padding-bottom: 100%;
    margin-right: 50px;
    margin-bottom: 30px;
    position: relative;
}

.bg-circle {
    width: 80%;
    height: 80%;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.img-preview {
    max-width: 100%;
    max-height: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.sub-images-wrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.btn-sub-images {
    width: 22%;
    padding-bottom: 24%;
    border: none;
    position: relative;
    display: flex;
    justify-content: center;
    background-color: transparent;
}

.sub-img-box {
    width: 100%;
    height: 90%;
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.img-sub {
    max-width: 100%;
    max-height: 100%;
}

.sub-img-select-indicator {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    background-color: var(--primary-col);
}

.content-container {
    width: 100%;
}

.share-container {
    margin-bottom: 0px;
    display: flex;
    justify-content: flex-end;
    position: relative;
}

.btn-share-icon {
    border: none;
    background-color: transparent;
}

.btn-share-icon .f-icon {
    width: 40px;
    height: 40px;
}

.share-social-btns-wrapper {
    width: 50px;
    height: 100px;
    background-color: white;
    position: absolute;
    right: 0;
    top: 100%;
}

.txt-title {
    font-size: 60px;
    font-family: 'MontserratSemiBold';
    font-weight: 900;
}

.txt-type {
    font-size: 40px;
    font-weight: 900;
}

.txt-price {
    font-size: 60px;
    font-weight: 900;
}

.txt-lkr {
    font-size: 30px;
}

.size-wrapper {
    margin-top: 50px;
}

.sizes-wraper {
    display: flex;
    flex-wrap: wrap;
}

.btn-size {
    margin-right: 20px;
    margin-bottom: 20px;
    font-size: 40px;
    font-weight: 900;
    border: none;
    background-color: transparent;
}

.btn-size-selected {
    margin-right: 20px;
    margin-bottom: 20px;
    font-size: 40px;
    font-weight: 900;
    border: none;
    background-color: transparent;
    color: var(--primary-col);
}

.quantity-box {
    max-width: 300px;
    margin-top: 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
}

.txt-quantity {
    font-size: 20px;
    font-weight: 900;
    color: white;
}

.qty-btns-wrapper {
    height: 40px;
    display: flex;
    align-items: center;
}

.qty-btns-wrapper button {
    width: 20px;
    height: 40px;
    border: none;
    background-color: white;
}

.qty-btns-wrapper :nth-child(1) {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
}

.qty-btns-wrapper :nth-child(2) {
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 900;
    color: white;
}

.qty-btns-wrapper :nth-child(3) {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
}

.qty-btns-wrapper button .f-icon {
    width: 10px;
    height: 10px;
}

.cart-buy-btns-wrapper {
    margin-top: 50px;
    display: flex;
}

.btn-add-to-cart,
.btn-buy-now {
    height: 50px;
    margin-right: 10px;
    border: none;
    border-radius: 10px;
}

.btn-add-to-cart {
    width: 50px;
    margin-right: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.btn-buy-now {
    padding: 0 20px;
    color: white;
    font-weight: 900;
}

.btn-add-to-cart .f-icon {
    color: white;
}

.btn-download-psd {
    height: 50px;
    margin-top: 10px;
    padding: 0 20px;
    border: none;
    border-radius: 10px;
    color: white;
    font-weight: 900;
}

.item-desc-faq-wrapper {
    margin: 30px 0;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 50px;
    align-items: start;
}

.faq-wrapper {
    max-height: 50vh;
    padding: 30px 10px;
    border-radius: 10px;
    background-color: white;
}

.hor-line {
    width: 100%;
    height: 1px;
    margin: 3px 0 20px 0;
    background-color: rgba(255, 166, 0, 0.243);
}

.txt-no-question {
    margin: 60px 0;
    text-align: center;
}

.qa-box {
    margin-bottom: 20px;
}

.txt-name {
    margin-left: 20px;
    margin-bottom: 5px;
}

.txt-question {
    font-weight: 900;
    margin-bottom: 5px;
}

.load-more-add-q-btn-wrapper {
    display: grid;
    grid-template-columns: auto 70px;
    gap: 20px;
}

.add-question-wrapper {
    display: flex;
}

.add-question-wrapper input {
    max-width: 700px;
    width: 100%;
    height: 30px;
    padding-left: 5px;
    border: 1px solid rgba(0, 0, 0, 0.144);
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
}

.add-question-wrapper button {
    width: 50px;
    flex-shrink: 0;
    border: none;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    color: white;
    background-color: var(--primary-col);
}

.btn-load-all-questions {
    border: none;
    background-color: transparent;
    display: flex;
    align-items: center;
    color: blue;
}

.pre-designed-wrapper,
.recommanded-items-wrapper {
    padding: 50px 0;
}

@media only screen and (max-width: 1000px) {
    .txt-title {
        font-size: 25px;
        margin-bottom: 10px;
    }

    .txt-type {
        font-size: 17px;
        margin-bottom: 20px;
    }

    .txt-price {
        font-size: 30px;
    }

    .txt-lkr {
        font-size: 17px;
    }

    .size-wrapper {
        margin-top: 30px;
    }

    .txt-select-size {
        font-size: 13px;
    }

    .btn-size {
        font-size: 20px;
    }

    .btn-size-selected {
        font-size: 20px;
    }
}

@media only screen and (max-width: 768px) {

    .top-container {
        padding-top: 90px;
    }

    .image-content-wrapper {
        flex-direction: column;
    }

    .images-container {
        max-width: 500px;
        margin: auto;
        margin-bottom: 20px;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        position: relative;

    }

    .bg-circle {
        max-width: 80%;
        max-height: 80%;
        width: 100%;
        height: 100%;
    }

    .image-preview-box {
        margin-right: 0;
    }

    .item-desc-faq-wrapper {
        display: flex;
        flex-direction: column;
        align-items: stretch;
    }

}
</style>