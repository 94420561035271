<template>
    <div class="description-faq-main">
        <div class="description-wrapper">
            <div class="title skeleton-animation"></div>
            <div class="content skeleton-animation"></div>
            <div class="content skeleton-animation"></div>
            <div class="content skeleton-animation"></div>
            <div class="content skeleton-animation"></div>
            <div class="content skeleton-animation"></div>
        </div>
        <div class="faq-wrapper">
            <div class="title skeleton-animation"></div>
            <div class="qna-wrapper">
                <div class="question skeleton-animation"></div>
                <div class="question-sm skeleton-animation"></div>
                <div class="answer skeleton-animation"></div>
                <div class="answer skeleton-animation"></div>
                <div class="answer-sm skeleton-animation"></div>
            </div>
            <div class="qna-wrapper">
                <div class="question skeleton-animation"></div>
                <div class="question-sm skeleton-animation"></div>
                <div class="answer skeleton-animation"></div>
                <div class="answer skeleton-animation"></div>
                <div class="answer-sm skeleton-animation"></div>
            </div>
            <div class="input skeleton-animation"></div>
        </div>
    </div>
</template>
<script>
export default {

}
</script>
<style scoped>
.description-faq-main {
    margin: 30px 0;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 50px;
}

.title {
    max-width: 300px;
    height: 40px;
    margin-bottom: 40px;
}

.content {
    height: 20px;
    margin-bottom: 10px;
}

.faq-wrapper {
    padding: 10px;
    border-radius: 10px;
    background-color: white;
}

.qna-wrapper{
    margin-bottom: 40px;
}

.question {
    height: 20px;
    margin-bottom: 10px;
}

.answer {
    height: 15px;
    margin-bottom: 10px;
}

.question-sm{
    max-width: 300px;
    height: 20px;
    margin-bottom: 10px;
}

.answer-sm{
    max-width: 400px;
    height: 15px;
    margin-bottom: 10px;
}

.input{
    max-width: 430px;
    height: 30px;
}

@media only screen and (max-width: 768px) {
    .description-faq-main{
        display: flex;
        flex-direction: column;
    }
}
</style>