<template>
  <div class="bg-gradient">
    <Navigation :showTransition="false" />
    <Loader :loading="isLoading" />
    <NotificationBox :isVisible="notify.isVisible" :type="notify.type" :title="notify.title" :message="notify.message"
      @notificationIsVisible="notifyIsVisible()" />
    <div class="main">
      <div class="container res-px">
        <div class="drop-content">
          <div class="content-1 res-px py">
            <TitleDouble>
              <template v-slot:title-back>
                <h2>LIMITED EDITIONS</h2>
              </template>
              <template v-slot:title-front>
                <h2>CUSTOM DROPS</h2>
              </template>
            </TitleDouble>
          </div>
          <div v-if="isLoading === false && drops.length === 0" class="empty-items">
            <img src="../assets/images/empty-null samples/shop-no-items.webp" alt="">
          </div>
          <!--  -->
          <div v-else>
            <div v-for="(drop, index) in drops" :key="index" style="margin-bottom: 30px; position: relative;">
              <div v-if="drop.isExpired === true"
                style="max-width: 400px; width: 100%; height: 400px; display: flex; justify-content: center; align-items: center; position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%);">
                <img style="max-width: 100%; max-height: 100%;" src="../assets/images/common/expired.png" alt="">
              </div>
              <div class="drop-wrapper" :style="drop.isExpired === true ? 'opacity: 0.3' : 'opacity: 1'">
                <div class="content-2">
                  <div class="drop-shoe-container">
                    <img :src="drop.img_path_1" class="drop-shoe-img" />
                  </div>
                  <h1 class="shoe-model">{{ drop.name }}</h1>
                  <hr>
                </div>
                <div class="content-3">
                  <!-- <h1 class="drop-limit">{{ drop.limit }}</h1> -->
                  <div class="time">
                    <div class="wrapper-time-background ">
                      <img src="@/assets/images/drop/time-background.png" class="background-image " />
                    </div>
                    <div class="container-countdown res-px">
                      <div class="date">
                        <div class="countdown">
                          <label class="expair">{{ drop.endDateCountdown.days }}</label>
                          <label class="exp" id="day">Days</label>
                        </div>
                        <span>:</span>
                        <div class="countdown">
                          <label class="expair">{{ drop.endDateCountdown.hours }}</label>
                          <label class="exp" id="hours">Hours</label>
                        </div>
                        <span>:</span>
                        <div class="countdown">
                          <label class="expair">{{ drop.endDateCountdown.minutes }}</label>
                          <label class="exp" id="minutes">Minutes</label>
                        </div>
                        <span>:</span>
                        <div class="countdown">
                          <label class="expair">{{ drop.endDateCountdown.seconds }}</label>
                          <label class="exp" id="seconds">Seconds</label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="Snatch-button">
                  <BtnTxtMdRound :buttonText="'Snatch'" @custom-click="popupOpen(index)" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <DropPopup v-if="isPopupVisible" :itemData="selectedDropItem" @drop-popup-close="dropPopupClose"/>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import { useHead } from '@vueuse/head';
import Navigation from '../components/common/navigation-com.vue';
import Loader from '../components/common/loading-com.vue';
import NotificationBox from '../components/common/notification-box-com.vue';
import TitleDouble from '../components/common/title-double-com.vue';
import BtnTxtMdRound from '../components/common/btn-txt-md-round-com.vue';
import DropPopup from '../components/drop/drop-popup-com.vue';
import { getDrops } from '@/services/api';

export default {

  data() {
    return {
      notify: { isVisible: false, type: '', title: '', message: '' },
      drops: [],
      selectedDropItem: {},
      intervalId: null,
      isPopupVisible: false,
      isLoading: false
    }
  },

  components: {
    Navigation,
    NotificationBox,
    Loader,
    TitleDouble,
    BtnTxtMdRound,
    DropPopup,
  },

  mounted() {
    this.setMetaData();
    this.getDropsData();
  },

  methods: {
    setMetaData() {
      useHead({
        title: `DROPS | CUSTOM SNEAKERS`,
        meta: [
          {
            name: 'description',
            content: ``
          },
        ],
      });
    },

    async getDropsData() {
      this.isLoading = true;
      try {
        const response = await getDrops();
        this.drops = response.data;

        this.drops.forEach(drop => {
          drop.dropCountdown = this.countdown(drop.end);
          setInterval(() => {
            const endDateTime = new Date(drop.end).getTime();
            const currentDateTime = new Date().getTime();
            const timeLeft = endDateTime - currentDateTime;

            if (timeLeft > 0) {
              drop.endDateCountdown = {
                days: String(Math.floor(timeLeft / (1000 * 60 * 60 * 24))).padStart(2, '0'),
                hours: String(Math.floor((timeLeft % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))).padStart(2, '0'),
                minutes: String(Math.floor((timeLeft % (1000 * 60 * 60)) / (1000 * 60))).padStart(2, '0'),
                seconds: String(Math.floor((timeLeft % (1000 * 60)) / 1000)).padStart(2, '0')
              }
            }
          }, 1000);
        });
      } catch (error) {
        this.notify = { isVisible: true, type: 'error', title: error.response.data.title, message: error.response.data.message };
      }
      this.isLoading = false;
    },

    countdown(endAt) {
      setInterval(() => {
        const endDateTime = new Date(endAt).getTime();
        const currentDateTime = new Date().getTime();
        const timeLeft = endDateTime - currentDateTime;

        if (timeLeft > 0) {
          const endDateCountdown = {
            days: Math.floor(timeLeft / (1000 * 60 * 60 * 24)),
            hours: Math.floor((timeLeft % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)),
            minutes: Math.floor((timeLeft % (1000 * 60 * 60)) / (1000 * 60)),
            seconds: Math.floor((timeLeft % (1000 * 60)) / 1000)
          }

          return endDateCountdown;
        }
      }, 1000);
    },

    notifyIsVisible(value) {
      this.notify = { isVisible: value, type: '', title: '', message: '' };
    },

    async popupOpen(index) {
      this.selectedDropItem = await this.drops[index];
      this.isPopupVisible = true;
    },

    dropPopupClose() {
      this.isPopupVisible = false;
    }
  },
  // beforeUnmount() {
  //   this.drops.forEach((drop) => {
  //     clearInterval(drop.intervalId);
  //   });
  // },
}
</script>

<style scoped>
.empty-items {
  display: flex;
  justify-content: center;
  align-items: center;
}

.empty-items img {
  max-width: 100%;
}

.blur {
  filter: blur(5px);
}

.content-2 {
  text-align: center;
  margin-top: 50px;
  padding: 10px;
}

.drop-shoe-img {
  max-width: 100%;
  max-height: 100%;
  width: 400px;
}

.shoe-model {
  margin-top: 20px;
  font-size: 40px;
}

.shoe-name {
  font-weight: 100;
}

hr {
  max-width: 100%;
  margin: 0 auto;
  margin-top: 10px;
}

.content-3 {
  text-align: center;
  margin-top: 20px;

}

.drop-limit {
  font-size: 24px;
  text-align: center;
}

.expair {
  font-size: 50px;
}

.exp {
  font-size: 10px;
}

.time {
  max-height: 100%;
  margin-top: 20px;
  padding: 10px;
}

.btn {
  padding: 10px;
  border: none;
  border-radius: 20px;
  background-color: orange;
  color: white;
  width: 150px;
  font-size: 15px;
}

.Snatch-button {
  text-align: center;
  margin-top: 50px;
}

.background-image {
  max-width: 100%;
  max-height: 100%;
  object-fit: cover;
}

.wrapper-time-background {
  max-width: 100%;
}

.drop-wrapper {
  background-color: rgba(0, 0, 0, 0.068);
  max-width: 600px;
  width: auto;
  max-height: 100%;
  padding: 10px;
  padding-right: 100px;
  padding-left: 100px;
  padding-bottom: 50px;
  margin-bottom: 50px;
  margin-top: 30px;
  border-radius: 50px;
  border: 6px solid white;
  margin: 0 auto;
}

.drop-shoe-container {
  max-width: 100%;
  max-width: 100%;
}

.container-countdown {
  display: block;
  color: #fff;
}

.date,
.name-countdown {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
}

.name-countdown {
  background-color: red;

}

.date {
  margin-top: -100px;
  max-width: 100%;
}

span {
  font-size: 50px;
  padding-bottom: 20px;
}

.countdown {
  display: flex;
  flex-direction: column;
}

#day {
  padding: 0
}

.expair,
.exp {
  text-align: center;
  margin-bottom: 5px;
}

.drop-content {
  min-height: 100vh;
  padding-bottom: 50px;
}

@media only screen and (max-width: 575px) {
  .drop-wrapper {
    max-height: 100%;
    padding-left: 0;
    padding-right: 0;
  }

  .shoe-model {
    font-size: 30px;
    margin-left: 0
  }

  hr {
    max-width: 100%;
    margin: 0 20px;
    margin-top: 10px;
  }

  .drop-limit {
    font-size: 24px;
  }

  .date {
    margin-top: -90px;
  }

  .Snatch-button {
    padding-bottom: 50px;
  }
}

@media only screen and (min-width: 350px) and (max-width: 650px) {
  .date {
    margin-top: -80px;
  }

  .expair,
  span {
    font-size: 40px
  }

}

@media only screen and (min-width: 576px) and (max-width: 670px) {
  .date {
    margin-top: -80px;
  }

}

@media only screen and (max-width: 350px) {
  .date {
    margin-top: -60px;
  }

  .expair,
  span {
    font-size: 30px
  }
}
</style>