<template>
  <div @click="dropPopupClose()" class="parent">
    <div v-if="isCheckoutPopupIsVisible">
      <CheckoutPopup :itemsData="itemsData" :subTotal="subTotal" :discount="discount" :total="total"
        @closeCheckoutPopup="closeCheckoutPopup()" />
    </div>
    <div @click.stop class="form">
      <div class="close-wrapper">
        <button @click="dropPopupClose()">
          <font-awesome-icon class="f-icon" :icon="['fa', 'close']" />
        </button>
      </div>
      <div class="item-details-description-wrapper">
        <div class="imgs-details-wrapper">
          <div class="imgs-wrapper">
            <div class="box-img-sel">
              <img :src="selectedImg" alt="">
            </div>
            <div class="sub-imgs-wrapper">
              <div @click="selectImg(1)" class="box-sub-img"
                :class="selectedImg === itemData.img_path_1 ? 'box-sub-img-selected' : ''">
                <img :src="itemData.img_path_1" alt="">
              </div>
              <div @click="selectImg(2)" class="box-sub-img"
                :class="selectedImg === itemData.img_path_2 ? 'box-sub-img-selected' : ''">
                <img v-if="itemData.img_path_2" :src="itemData.img_path_2" alt="">
                <font-awesome-icon v-else class="i-img" :icon="['fa', 'image']" />
              </div>
              <div @click="selectImg(3)" class="box-sub-img"
                :class="selectedImg === itemData.img_path_3 ? 'box-sub-img-selected' : ''">
                <img v-if="itemData.img_path_3" :src="itemData.img_path_3" alt="">
                <font-awesome-icon v-else class="i-img" :icon="['fa', 'image']" />
              </div>
              <div @click="selectImg(4)" class="box-sub-img"
                :class="selectedImg === itemData.img_path_4 ? 'box-sub-img-selected' : ''">
                <img v-if="itemData.img_path_4" :src="itemData.img_path_4" alt="">
                <font-awesome-icon v-else class="i-img" :icon="['fa', 'image']" />
              </div>
            </div>
          </div>
          <div>
            <p class="p-name">{{ itemData.name }}</p>
            <p class="p-price">LKR {{ (itemData.total).toLocaleString() }}</p>
            <p v-if="itemData.sizes !== 'all' && !selectedSize" class="p-select-size">Select a size:</p>
            <div v-if="itemData.sizes !== 'all'" class="sizes-wrapper">
              <p v-for="(item, index) in itemData?.sizes" :key="index" @click="selectSize(index)" class="p-size"
                :class="item.size == selectedSize ? 'p-size-selected' : ''">{{ item.size }}</p>
            </div>
            <p v-if="selectedSizeStock" class="p-remaining">Remiaining {{ selectedSizeStock }}</p>
            <div v-if="itemData.sizes === 'all' || (itemData.sizes !== 'all' && selectedSize)" class="qty-wrapper">
              <p class="p-quantity">Quantity</p>
              <div class="box-qty">
                <button @click="decreaseQuantity()">-</button>
                <p>{{ selectedQty }}</p>
                <button @click="increaseQuantity()">+</button>
              </div>
            </div>
            <button v-if="(itemData.sizes !== 'all' && selectedSize) || itemData.sizes === 'all'" class="btn-buy-now" @click="buyNow()">Buy
              Now</button>
          </div>
        </div>
        <p>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Cumque necessitatibus sequi voluptatem esse nemo
          distinctio accusamus, reprehenderit iusto, numquam natus autem consequuntur dicta perspiciatis provident optio
          maxime deleniti quia debitis!</p>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import { setToken } from '../../utils/helper';
import CheckoutPopup from '../../components/common/checkout-popup-com.vue';
export default {

  props: {
    itemData: {
      type: Object,
    },
  },

  data() {
    return {
      token: null,
      isCheckoutPopupIsVisible: false,
      selectedImg: this.itemData.img_path_1,
      selectedSize: null,
      selectedSizeIndex: null,
      selectedSizeStock: null,
      selectedQty: 1,
      itemsData: null,
      subTotal: null,
      discount: null,
      total: null,

    }
  },

  components: {
    CheckoutPopup,
  },

  mounted() {
    this.token = setToken();
  },

  methods: {
    selectImg(index) {
      if (index === 1) {
        this.selectedImg = this.itemData.img_path_1;
      } else if (index === 2 && this.itemData.img_path_2) {
        this.selectedImg = this.itemData.img_path_2
      } else if (index === 3 && this.itemData.img_path_3) {
        this.selectedImg = this.itemData.img_path_3
      } else if (index === 4 && this.itemData.img_path_4) {
        this.selectedImg = this.itemData.img_path_4
      } else {
        return;
      }
    },

    selectSize(index) {
      this.selectedSizeIndex = index;
      this.selectedSize = this.itemData.sizes[index].size;
      this.selectedSizeStock = this.itemData.sizes[index].stock;
    },

    increaseQuantity() {
      if (this.itemData.sizes !== 'all') {
        if (this.selectedSizeIndex !== null) {
          if (this.itemData.sizes[this.selectedSizeIndex].stock > this.selectedQty) {
            this.selectedQty += 1;
          }
        }
      } else {
        this.selectedQty += 1;
      }
    },

    decreaseQuantity() {
      if (this.itemData.sizes !== 'all') {
        if (this.selectedSizeIndex !== null) {
          if (this.selectedQty > 1) {
            this.selectedQty -= 1;
          }
        }
      } else {
        if (this.selectedQty > 1) {
          this.selectedQty -= 1;
        }
      }
    },

    buyNow() {
      if (this.token === null) {
        this.$router.push('/login');
      } else {
        if ((this.itemData.sizes !== 'all' && this.selectedSize !== null && this.selectedSizeStock !== null || this.selectedSizeStock !== 0) || this.itemData.sizes === 'all') {
          const itemsData = [{
            type: 'drop',
            id: null,
            userId: this.token,
            itemId: this.itemData.id,
            customizeId: null,
            itemName: this.itemData.name,
            size: this.selectedSize,
            quantity: this.selectedQty,
            discount: this.itemData.discount,
            price: this.itemData.price
          }];
          this.itemsData = itemsData;
          this.subTotal = this.itemData.price;
          this.total = this.itemData.total;
          this.discount = (this.itemData.price / 100) * this.itemData.discount;
          this.isCheckoutPopupIsVisible = true;          
        }
      }
    },

    closeCheckoutPopup(value) {
            this.isCheckoutPopupIsVisible = value;
            this.dropPopupClose();
        },

    dropPopupClose() {
      this.$emit('drop-popup-close');
    }
  },
}



</script>

<style scoped>
.parent {
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.305);
}

.form {
  max-width: 700px;
  width: 100%;
  padding: 30px;
  border-radius: 20px;
  background-color: white;
}

.close-wrapper {
  height: 20px;
  display: flex;
  justify-content: flex-end;
}

.close-wrapper button {
  width: 20px;
  height: 20px;
  background-color: transparent;
  border: none;
}

.item-details-description-wrapper {
  max-height: 60vh;
  overflow-y: auto;
}

.imgs-details-wrapper {
  max-height: 100%;
  margin-bottom: 20px;
  display: flex;
  gap: 10px;
}

.imgs-wrapper {
  max-width: none;
  width: 250px;
}

.box-img-sel {
  width: 250px;
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.box-img-sel img {
  max-width: 100%;
  max-height: 100%;
}

.sub-imgs-wrapper {
  margin-top: 10px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 10px;
}

.box-sub-img {
  height: 50px;
  border: 2px solid transparent;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.box-sub-img-selected {
  border: 2px solid var(--primary-col);
}

.box-sub-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.i-img {
  width: 30px;
  height: 30px;
  color: rgba(0, 0, 0, 0.293);
}

.p-name {
  margin-bottom: 5px;
  font-size: 30px;
  font-weight: bold;
}

.p-price {
  margin-bottom: 20px;
  font-size: 20px;
  font-weight: bold;
}

.p-select-size {
  margin-bottom: 5px;
  font-size: small;
  color: red;
}

.sizes-wrapper {
  margin-bottom: 20px;
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
}

.p-size {
  font-size: 20px;
  font-weight: bold;
  cursor: pointer;
}

.p-size-selected {
  color: var(--primary-col);
}

.p-remaining {
  margin-bottom: 10px;
  font-size: small;
}

.qty-wrapper {
  margin-bottom: 20px;
  border-radius: 5px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 20px;
}

.p-quantity {
  font-size: small;
}

.box-qty {
  display: flex;
  align-items: center;
  gap: 5px;
}

.box-qty button {
  flex-shrink: 0;
  width: 15px;
  height: 23px;
  border: none;
  background-color: var(--primary-col);
  color: white;
}

.box-qty button:nth-child(1) {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.box-qty button:nth-child(3) {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

.box-qty p {
  font-size: small;
}

.btn-buy-now {
  padding: 10px 30px;
  border: 2px solid var(--primary-col);
  border-radius: 10px;
  color: white;
  background-color: var(--primary-col);
  font-weight: bold;
  transition: all 0.3s ease-in-out;
}

.btn-buy-now:hover {
  background-color: white;
  color: var(--primary-col);
}

@media only screen and (max-width: 660px) {

  .form {
    padding: 10px;
  }

  .p-name {
    font-size: 25px;
  }

  .imgs-details-wrapper {
    flex-direction: column;
  }

  .imgs-wrapper {
    max-width: 300px;
    width: 100%;
    margin: 0 auto;
  }

  .box-img-sel {
    width: 100%;
  }
}
</style>