<template>
    <div>
        <div class="main" @mousemove="handleMouseMove">
            <div v-if="token === null" class="box-left-navi">
                <div class="left-navi">
                    <div class="box-links">
                        <router-link to="/about">ABOUT</router-link>
                        <router-link to="/login">LOGIN</router-link>
                        <router-link to="/signup" style="color: var(--primary-col);">REGISTER</router-link>
                    </div>
                    <div class="box-socials">
                        <a href="https://www.facebook.com/customsneakers.lk" target="_blank" rel="noopener noreferrer">
                            <div class="box-icon">
                                <img src="../../assets/images/icons/fb-white.png" alt="">
                            </div>
                        </a>
                        <a href="https://www.instagram.com/customsneakers.lk" target="_blank" rel="noopener noreferrer">
                            <div class="box-icon">
                                <img src="../../assets/images/icons/insta-white.png" alt="">
                            </div>
                        </a>
                        <a href="https://www.linkedin.com/company/custiom-sneakers/about/" target="_blank"
                            rel="noopener noreferrer">
                            <div class="box-icon">
                                <img src="../../assets/images/icons/linkedin-white.png" alt="">
                            </div>
                        </a>
                    </div>
                </div>
            </div>
            <div class="bg-img-nike"></div>
            <div class="box-middle">
                <img ref="custom" class="img-custom" src="../../assets/images/home/banners/custom.webp" alt="">
                <div ref="shoe" class="box-shoe">
                    <img src="../../assets/images/home/banners/shoe.webp" alt="">
                </div>
                <img ref="sneakers" class="img-sneakers" src="../../assets/images/home/banners/sneakers.webp" alt="">
            </div>
            <div class="box-bottom">
                <div class="banner-container">
                    <div class="box-gradient"></div>
                    <bannerItemsSliderCom v-if="items.length > 0" :items="items" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import bannerItemsSliderCom from './banner-items-slider-com.vue';
import { getBannerSliderItems } from '@/services/api';

export default {

    data() {
        return {
            items: [],
            token: null,
            parallaxX: 0,
            parallaxY: 0,
        }
    },

    components: {
        bannerItemsSliderCom,
    },

    mounted() {
        this.getItems();
        this.getUserToken();
    },

    methods: {

        async getItems() {
            try {
                const response = await getBannerSliderItems();
                this.items = response.data;
            } catch (error) {
                console.log(error.message);
            }
        },

        getUserToken() {
            if (localStorage.getItem('token') === null || localStorage.getItem('token') === '') {
                this.token = null;
            } else {
                this.token = localStorage.getItem('token');
            }
        },

        handleMouseMove(event) {
            if (window.innerWidth >= 768) {
                this.parallaxX = event.pageX;
                this.parallaxY = event.pageY;

                const custom = this.$refs.custom;
                const shoe = this.$refs.shoe;
                const sneakers = this.$refs.sneakers;

                custom.style.transform = `translate(${(this.parallaxX / window.innerWidth) * 100}px, ${(this.parallaxY / window.innerHeight) * 100}px)`;
                // custom.style.left = '5%';

                shoe.style.transform = `translate(${(this.parallaxX / window.innerWidth) * 30}px, ${(this.parallaxY / window.innerHeight) * 30}px) rotate(${Math.floor(Math.random() * 31) - 15}deg)`;
                // shoe.style.left = '20%';
                // shoe.style.top = '-10%';

                sneakers.style.transform = `translate(${(this.parallaxX / window.innerWidth) * -100}px, ${(this.parallaxY / window.innerHeight) * -100}px)`;
                // sneakers.style.left = '5%';
            }
        },
    }
};

</script>

<style scoped>
.main {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-position: center center;
    overflow: hidden;
    position: relative;
    background-color: black;
}

.box-left-navi {
    width: 70px;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10;
}

.left-navi {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 150px;
}

.box-links {
    margin-bottom: 50px;
    display: flex;
    gap: 30%;
    font-weight: bold;
    transform: rotate(-90deg);
}

.box-links-sub {
    display: flex;
    gap: 20px;
}

.box-socials {
    display: flex;
    gap: 10px;
    transform: rotate(-90deg);
}

.box-icon {
    width: 30px;
    height: 30px;
    flex-shrink: 0;
    transform: rotate(90deg);
}

.bg-img-nike {
    width: 80%;
    height: 80%;
    background-image: url('../../assets/images/home/banners/nike.webp');
    background-size: contain;
    background-repeat: no-repeat;
    opacity: .2;
    position: absolute;
    top: 0;
    right: 0;
}

.box-middle {
    max-width: 700px;
    width: 100%;
    height: 60vh;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

.box-shoe {
    width: 90%;
    position: relative;
    z-index: 1;
}

.box-shoe img {
    max-width: 100%;
    position: relative;
    /* z-index: 1; */

}

.img-custom {
    max-width: 500px;
    position: absolute;
    top: 10%;
    left: 0%;
    z-index: 2;
}

.img-sneakers {
    max-width: 450px;
    position: absolute;
    bottom: 20%;
    right: 0%;
    z-index: 0;
}

.img-custom,
.box-shoe,
.img-sneakers {
    transition: all 5s ease-out;
}

.box-bottom {
    width: 100%;
    height: 200px;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    position: absolute;
    bottom: 50px;
}

.banner-container {
    max-width: 80%;
    width: 100%;
    position: relative;
}

.box-gradient {
    width: 90px;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: linear-gradient(90deg, black, transparent);
    z-index: 10;
}

a {
    color: white;
}

a.router-link-exact-active {
    color: orange;
}

@media only screen and (max-width: 1200px) {
    .bg-img-nike {
        width: 100%;
        background-image: url('../../assets/images/home/banners/nike-sm.png');
        display: flex;
        background-position: center;
        align-items: center;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

}

@media only screen and (max-width: 600px) {
    .banner-container {
        max-width: 100%;
    }

    .img-custom {
        max-width: 400px;
    }

    .img-sneakers {
        max-width: 400px;
    }

    .box-left-navi {
        display: none;
    }
}

@media only screen and (max-width: 400px) {
    .img-custom {
        max-width: 300px;
    }

    .img-sneakers {
        max-width: 300px;
    }
}
</style>
