<template>
    <router-view></router-view>
    <Footer />
</template>

<script>
import Footer from './components/common/footer-com.vue';

export default {
    data() {
    return {

    };
  },
  methods: {
    
  },
    components: {
        Footer,
    }
}
</script>
<style scoped>
</style>
 