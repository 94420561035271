<template>
    <div>
        <Navigation />
        <Loader :loading="isLoading" />
        <div class="main bg-gradient res-px">
            <NotificationBox :isVisible="notify.isVisible" :type="notify.type" :title="notify.title"
                :message="notify.message" @notificationIsVisible="notifyIsVisible()" />
            <div class="form-box res-px py">
                <p class="txt-title">Login</p>
                <div class="inp-box">
                    <p class="txt-label">Email</p>
                    <input v-model="email" :class="errors.email === '' ? 'inp' : 'inp-error'" type="text" name="" id="" placeholder="example@gmail.com">
                    <p v-if="errors.email" class="txt-error txt-sm">{{ errors.email }}</p>
                </div>
                <div class="inp-box">
                    <p class="txt-label">Password</p>
                    <input v-model="password" :class="errors.password === '' ? 'inp' : 'inp-error'" :type="isShowPassword === true ? 'text' : 'password'" name="" id=""
                        placeholder="your password here....">
                    <p v-if="errors.password" class="txt-error txt-sm">{{ errors.password }}</p>
                    <p class="txt-error txt-sm"></p>
                </div>
                <div class="show-pw-wrapper">
                    <input v-model="isShowPassword" type="checkbox" name="" id="">
                    <p>Show Password</p>
                </div>
                <BtnTxtMdRound :buttonText="'Login'" @custom-click="login()" />
                <div class="auth-opt-link-wrapper">
                    <p>Don't have an account?</p>
                    <router-link to="/signup" class="col-primary">Register</router-link>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
/* eslint-disable */
import { useHead } from '@vueuse/head';
import Navigation from '../components/common/navigation-com.vue';
import BtnTxtMdRound from '../components/common/btn-txt-md-round-com.vue';
import { login } from '../services/api';
import NotificationBox from '../components/common/notification-box-com.vue';
import Loader from '../components/common/loading-com.vue'

export default {
    data() {
        return {
            email: '',
            password: '',
            isShowPassword: false,
            errors: {
                email: '',
                password: '',
            },
            notify: { isVisible: false, type: '', title: '', message: '' },
            isLoading: false
        }

    },


    components: {
        Navigation,
        BtnTxtMdRound,
        NotificationBox,
        Loader,
    },

    mounted() {
        this.setMetaData();
    },

    methods: {
        setMetaData() {
            useHead({
                title: `LOGIN | CUSTOM SNEAKERS`,
                meta: [
                    {
                        name: 'description',
                        content: ``
                    },
                ],
            });
        },

        validation() {

            if (this.email === '') {
                this.errors.email = 'Email is Required!'
            } else if (!/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(this.email)) {
                this.errors.email = 'Invalid Email Format!';
            } else {
                this.errors.email = '';
            }

            if(this.password === '') {
                this.errors.password = 'Password is Required!';
            } else if(this.password.length < 6) {
                this.errors.password = 'Password Required more than 5 characters!';
            } else {
                this.errors.password = '';
            }
        },

        notifyIsVisible(value) {
            this.notify = { isVisible: value, type: '', title: '', message: '' };
        },

        async login() {
            this.validation();

            if (this.errors.email === '' && this.errors.password === '') {
                this.isLoading = true;
                try {
                    const postData = { email: this.email, password: this.password };
                    const response = await login(postData);
                    localStorage.setItem('token', response.data.token);
                    this.isLoading = false;
                    this.$router.push('/');
                } catch (error) {
                    this.isLoading = false;
                    this.notify = { isVisible: true, type: 'error', title: error.response.data.title, message: error.response.data.message };
                }
            }
        },
    },
}
</script>

<style scoped>
.inp {
    padding-left: 10px;
    height: 35px;
    border: 1px solid rgb(203, 203, 203);
    border-radius: 5px;
}

.inp-error{
    padding-left: 10px;
    height: 35px;
    border: 1px solid red;
    border-radius: 5px;
}

.main {
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.form-box {
    max-width: 500px;
    width: 100%;
    margin-top: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: white;
}

.txt-title {
    font-size: 20px;
    margin-bottom: 40px;
    font-weight: 900;
}

.inp-box {
    width: 100%;
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
}

.txt-error {
    color: red;
}

.show-pw-wrapper {
    width: 100%;
    margin-bottom: 30px;
    display: flex;
    align-items: center;
}

.show-pw-wrapper input {
    margin-right: 10px;
}

.auth-opt-link-wrapper {
    margin-top: 20px;
    display: flex;
    align-items: center;
}

.auth-opt-link-wrapper p {
    margin-right: 10px;
}</style>