<template>
    <hr>
    <div class="main" :style="{ gridTemplateColumns: `repeat(${slidePerView}, 1fr)` }">
        <div v-for="index in slidePerView" :key="index" class="review-box">
            <div class="img skeleton-animation"></div>
            <div class="name skeleton-animation"></div>
            <div class="stars skeleton-animation"></div>
            <div class="content skeleton-animation"></div>
            <div class="content skeleton-animation"></div>
            <div class="content skeleton-animation"></div>
            <div class="content skeleton-animation"></div>
            <div class="content skeleton-animation"></div>
        </div>
    </div>
</template>
<script>
export default {

    computed: {
        slidePerView() {
            if (window.innerWidth >= 1024) {
                return 3;
            } else if (window.innerWidth >= 768) {
                return 2;
            } else {
                return 1;
            }
        }
    },
}
</script>
<style scoped>
.main {
    width: 100%;
    margin-top: 50px;
    margin-bottom: 50px;
    display: grid;
    gap: 30px;
    align-items: stretch;
}

.review-box{
    padding: 0 30px 30px 30px;
    background-color: white;
    border-radius: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
}

.img{
    width: 100px;
    height: 100px;
    border: 2px solid white;
    border-radius: 50%;
    position: absolute;
    top: -50px;
}

.name{
    width: 100px;
    height: 30px;
    margin-top: 80px;
}

.stars {
    width: 150px;
    height: 25px;
    margin-top: 20px;
    margin-bottom: 40px;
}

.content{
    height: 20px;
    margin-bottom: 5px;
}
</style>