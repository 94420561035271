<template>
    <div class="bg-gradient">
        <Navigation />
        <div class="main container res-px">
            <div class="content-1">
                <h2 class="head">Terms and Conditions</h2>
                <p class="para">Please read these Terms and Conditions carefully before using Custom Sneakers. By accessing
                    or
                    using the Website, you agree to be bound by these Terms and Conditions.</p>
                <div class="content-2">
                    <h2 class="head">Refunds Policy</h2>
                    <ul class="list">
                        <li class="items">All Sales Are Final: Once a purchase is made, it is considered final. We do not
                            offer
                            refunds,
                            exchanges, or returns for change of mind, wrong size, or other similar reasons.</li>

                        <li class="items">Damaged or Defective Products: In the event that you receive a damaged or
                            defective
                            product, we
                            will gladly assist you. Please refer to our Refunds Policy page for more information on how to
                            address such issues.</li>

                        <li class="items">Non-Delivery: If you have not received your order within the estimated delivery
                            timeframe, please
                            refer to our Refunds Policy page for more information on how to address non-delivery issues.
                        </li>

                        <li class="items">Exceptions: Our refund policy does not apply to items purchased through
                            promotions,
                            flash sales,
                            or clearance sales, which are considered final sale and ineligible for refunds or exchanges
                            unless
                            explicitly stated otherwise.</li>

                        <li class="items">Unauthorized Returns: Any products returned without our prior authorization will
                            not
                            be accepted,
                            and no refund or exchange will be provided. Please refer to our Refunds Policy page for more
                            information on our return process.</li>
                    </ul>
                    <p class="paragraph">Change of Terms: We reserve the right to modify or update these Terms and
                        Conditions at
                        any time
                        without prior notice. Changes will be effective immediately upon posting on the Website. It is your
                        responsibility to review these Terms and Conditions periodically to stay informed of any updates.
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { useHead } from '@vueuse/head';
import Navigation from '../components/common/navigation-com.vue';
export default {

    data() {
        return {

        }
    },

    components: {
        Navigation,
    },

    mounted() {
        this.setMetaData();
    },

    methods: {
        setMetaData() {
            useHead({
                title: `TERMS & CONDITIONS `,
                meta: [
                    {
                        name: 'description',
                        content: ``
                    },
                ],
            });
        },
    }
}
</script>

<style scoped>

.main{
    min-height: 100vh;
}

.para {
    font-size: 20px;
    margin-top: 30px;
}

.content-1 {
    padding-top: 170px;
}

.content-2 {
    padding-top: 50px;
}

.list {
    margin-top: 30px;
}

.items {
    margin-top: 20px;
}

.paragraph {
    margin-top: 50px;
    padding-bottom: 100px;
}

@media only screen and (max-width: 768px) {

    .content-1 {
        padding-top: 130px;
    }

    .list{
        padding: 10px;
    }
}</style>