<template>
  <div>
    <Navigation />
    <div class="main  bg-gradient res-px">
      <h1>Help & Support </h1>
      <div class="content-1">
        <h2 class="head">General Questions </h2>
        <h2 class="que">What is Custom Sneakers?</h2>
        <p class="para">Custom Sneakers is your go-to destination for one-of-a-kind, personalized footwear. With an array
          of
          styles to choose from, we oﬀer you the unique opportunity to bring your vision to life on a pair
          of shoes.</p>

        <h2 class="que">How do I place an order?</h2>
        <p class="para">To order your custom sneakers, simply choose a base model from our collection, select your size,
          and download your customizable PSD ﬁle add your design and go to customize ﬁll the and upload
          your customized design. </p>
      </div>

      <div class="content-2">
        <h2 class="head">Customization</h2>
        <h2 class="que">Can I customize any shoe model?</h2>
        <p class="para">We have a wide selection of shoe models available for customization. Please check our ‘Customize’
          section to see all available options.</p>

        <h2 class="que">How do I know what size to order?</h2>
        <p class="para">Refer to our sizing guide which provides detailed information on how to measure your foot and
          choose the correct size for the style you're interested in.</p>

        <h2 class="que">What if I have a special customization request?</h2>
        <p class="para">For special requests, please contact our design team through the 'Contact Us' or ‘Customize’ forms
          are available on the customization page for restorations, Pre-Existing shoes and for customization
          inquiries. We'll do our best to accommodate your ideas. </p>
      </div>

      <div class="content-3">
        <h2 class="head">Orders and Shipping </h2>
        <h2 class="que">How long will it take to receive my custom sneakers?</h2>
        <p class="para">Custom sneakers are typically ready to ship within 3-4 weeks from the order date. Shipping times
          may vary depending on your location and design.</p>

        <h2 class="que">Can I track my order? </h2>
        <p class="para">Yes, once your order is shipped, we will send you a tracking number via email.</p>

        <h2 class="que">Do you ship internationally? </h2>
        <p class="para">Yes, we oﬀer international shipping. Additional charges and longer delivery times may apply.</p>
      </div>

      <div class="content-4">
        <h2 class="head">Returns and Exchanges</h2>
        <h2 class="que">What is your return policy?</h2>
        <p class="para">Please read our return policy here.</p>

        <h2 class="que">Can I cancel or modify my order?</h2>
        <p class="para">Orders can be modiﬁed or canceled within 24 hours of placement. After this period, we cannot
          guarantee changes as the customization process may have already begun. </p>
      </div>

      <div class="content-5">
        <h2 class="head">Customer Support </h2>
        <h2 class="que">How do I contact Customer Support?</h2>
        <p class="para">Our Customer Support team is available to assist you with any inquiries. You can reach us via the
          'Contact Us' page. Please allow up to 24 hours for a response.</p>

        <h2 class="que">I have a problem with my order, what should I do?</h2>
        <p class="para">If there's an issue with your order, please contact us immediately with your order details and a
          description of the problem. We're here to help ensure your satisfaction.</p>
      </div>
      <p class="para" id="thank">Thank you for choosing Custom Sneakers. We're excited to help you step up your shoe game!
      </p>
    </div>
  </div>
</template>

<script>
import { useHead } from '@vueuse/head';
import Navigation from '../components/common/navigation-com.vue';
export default {

  data() {
    return {

    }
  },

  components: {
    Navigation,
  },

  mounted() {
    this.setMetaData();
  },

  methods: {
    setMetaData() {
      useHead({
        title: `HELP | CUSTOM SNEAKERS`,
        meta: [
          {
            name: 'description',
            content: ``
          },
        ],
      });
    },
  }
}
</script>

<style scoped>
.main {
  padding-bottom: 50px;
}

h1 {
  padding-top: 150px;
}

.head {
  margin-top: 50px;
  font-weight: 500;
  font-size: 28px
}

.que {
  font-size: 22px;
  padding-top: 30px;
  margin-bottom: 10px;
}

#thank {
  margin-top: 40px
}
</style>