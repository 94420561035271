<template>
  <div>
    <transition :name="showTransition === true ? 'navi-trans' : ''">
      <div v-if="navigationMainIsVisible" :class="showTransition === true ? 'main-fixed' : 'main'">
        <div class="top"
          :style="isNavibarTransparent === true && scrolledPosition < 200 ? 'background-color: transparent' : 'background-color: black'">
          <div class="logo-cart-wrapper container res-px">
            <router-link to="/">
              <img class="img-logo" src="../../assets/images/logo/logo.webp" alt="">
            </router-link>
            <div class="router-link-wrapper">
              <router-link to="/">HOME</router-link>
              <router-link to="/shop">SHOP</router-link>
              <router-link to="/customize">CUSTOMIZE</router-link>
              <router-link to="/drops">DROPS</router-link>
              <router-link v-if="token !== null" to="/about">ABOUT</router-link>
            </div>
            <div class="profile-card-btn-wrapper">
              <button @click="this.$router.push('/overview')" v-if="token !== null" class="link-user btn-round">
                <font-awesome-icon class="f-icon" :icon="['fa', 'user']" />
              </button>
              <div v-if="token === null" class="login-register-link-wrapper">
                <router-link class="link-login-register" to="/signup">Register</router-link>
                &nbsp; | &nbsp;
                <router-link class="link-login-register" to="/login">Login</router-link>
              </div>
              <div v-if="token !== null" class="wish-cart-wrapper">
                <!-- <button class="btn-round">
                  <font-awesome-icon class="f-icon" :icon="['fa', 'heart']" />
                </button> -->
                <button @click="this.$router.push('/cart')" class="btn-round">
                  <img src="../../assets/images/icons/cart.webp" alt="">
                </button>
              </div>
            </div>
            <button @click="mobNavigationIsVisible = true;" class="btn-bars">
              <font-awesome-icon class="f-icon" :icon="['fa', 'bars']" />
            </button>
          </div>
        </div>
        <!-- <div class="bottom" :style="isNavibarTransparent === true ? 'background-color: transparent' : 'background-color: white'">
          <div class="router-link-wrapper">
            <router-link to="/">Home</router-link>
            <router-link to="/shop">Shop</router-link>
            <router-link to="/customize">Customize</router-link>
            <router-link to="/drops">Drops</router-link>
            <router-link to="/about">About</router-link>
          </div>
        </div> -->
      </div>
    </transition>
    <transition name="mob-navi-trans">
      <div @click="mobNavigationIsVisible = false;" v-if="mobNavigationIsVisible" class="mobile-navigation-container">
        <div @click.stop class="mobile-navigation">
          <button @click="mobNavigationIsVisible = false;" class="btn-close">
            <font-awesome-icon class="f-icon-close" :icon="['fa', 'close']" />
          </button>
          <div class="routes-wrapper">
            <router-link to="/">
              <img class="img-logo" src="../../assets/images/logo/logo.webp" alt="">
            </router-link>
            <br><br>
            <router-link class="mob-link" to="/">HOME</router-link>
            <router-link class="mob-link" to="/shop">SHOP</router-link>
            <router-link class="mob-link" to="/customize">CUSTOMIZE</router-link>
            <router-link class="mob-link" to="/drops">DROPS</router-link>
            <router-link class="mob-link" to="/about">ABOUT</router-link><br>
            <div class="mob-profile-card-btn-wrapper">
              <div @click="this.$router.push('/overview')" v-if="token !== null" class="link-user btn-round">
                <font-awesome-icon class="f-icon" :icon="['fa', 'user']" />
              </div>
              <div v-if="token === null" class="login-register-link-wrapper">
                <router-link class="link-login-register" to="/signup">REGISTER</router-link>
                &nbsp; | &nbsp;
                <router-link class="link-login-register" to="/login">LOGIN</router-link>
              </div>
              <div v-if="token !== null" class="wish-cart-wrapper">
                <button @click="this.$router.push('/cart')" class="btn-round">
                  <img src="../../assets/images/icons/cart.webp" alt="">
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>
<script>
export default {
  data() {
    return {
      navigationMainIsVisible: true,
      mobNavigationIsVisible: false,
      scrolledPosition: null,
      token: null,
    }
  },

  props: {
    showTransition: {
      type: Boolean,
      default: true,
    },

    isNavibarTransparent: {
      type: Boolean,
      default: false,
    },
  },

  mounted() {
    this.getUserToken();
    window.addEventListener("scroll", this.handleScroll);
  },
  beforeUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  },

  methods: {
    handleScroll() {
      if (this.showTransition === true) {
        let st = window.pageYOffset || document.documentElement.scrollTop;
        if (st > this.lastScrollTop) {
          this.navigationMainIsVisible = false;
        } else {
          this.navigationMainIsVisible = true;
        }
        this.lastScrollTop = st <= 0 ? 0 : st;
      }

      this.scrolledPosition = document.documentElement.scrollTop;
    },

    getUserToken() {
      if (localStorage.getItem('token') === null || localStorage.getItem('token') === '') {
        this.token = null;
      } else {
        this.token = localStorage.getItem('token');
      }
    }
  }
}
</script>
<style scoped>
.main-fixed {
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
}

.main {
  width: 100%;
  z-index: 10;
}

a {
  color: white;
}

a.router-link-exact-active {
  color: orange;
}

.top {
  width: 100%;
  padding: 20px 0;
  color: white;
  transition: all .3s ease-in-out;
}

.logo-cart-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.img-logo {
  width: 170px;
}

.profile-card-btn-wrapper {
  width: 170px;
  display: flex;
  justify-content: flex-end;
}

.link-user {
  margin-right: 10px;
}

.btn-round,
.btn-sign-in {
  height: 30px;
  border: none;
  background-color: transparent;
}

.btn-round {
  width: 30px;
  border-radius: 50%;
  border: 1px solid white;
  display: flex;
  justify-content: center;
  align-items: center;
}

.btn-round img{
  width: 31px;
}

.btn-sign-in {
  margin-right: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: white;
}

.login-register-link-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.link-login-register {
  color: white;
}

.wish-cart-wrapper {
  margin-left: 10px;
  display: flex;
  justify-content: space-between;
}

.f-icon {
  width: 18px;
  height: 18px;
  color: white;
}

.bottom {
  padding: 20px 0;
}

.router-link-wrapper {
  width: 400px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
}

.btn-bars {
  background-color: transparent;
  border: none;
  display: none;
}

.mobile-navigation-container {
  display: none;
}

.mob-profile-card-btn-wrapper {
  width: 160px;
  display: flex;
  justify-content: center;
}

@media only screen and (max-width: 998px) {

  .profile-card-btn-wrapper,
  .bottom {
    display: none;
  }

  .btn-bars {
    display: block;
  }

  .router-link-wrapper {
    display: none;
  }

  .mobile-navigation-container {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: flex-end;
    position: fixed;
    top: 0;
    right: 0;
    z-index: 20;
  }

  .mobile-navigation {
    width: 500px;
    height: 100vh;
    padding: 20px;
    background-color: black;
    position: relative;
  }

  .btn-close {
    margin: 30px;
    background-color: transparent;
    border: none;
    position: absolute;
    top: 0;
    right: 0;
  }

  .f-icon-close {
    width: 30px;
    height: 30px;
    color: white;
  }

  .routes-wrapper {
    height: 100vh;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .mob-link {
    margin-bottom: 20px;
    color: white;
  }
}

@media only screen and (max-width: 575px) {
  .mobile-navigation {
    width: 100%;
  }
}

.navi-trans-enter-from,
.navi-trans-leave-to {
  top: -150px;
}

.navi-trans-enter-active,
.navi-trans-leave-active {
  transition: all .3s ease;
}

.mob-navi-trans-enter-from,
.mob-navi-trans-leave-to {
  right: -100%;
}

.mob-navi-trans-enter-active,
.mob-navi-trans-leave-active {
  transition: all .5s ease;
}
</style>