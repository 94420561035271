import { createWebHistory, createRouter } from 'vue-router';
import Home from '../views/home-scr.vue';
import Shop from '../views/shop-scr.vue';
import ItemPreview from '../views/item-preview-scr.vue'
import About from '../views/about-scr.vue';
import Contact from '../views/contact-scr.vue';
import PrivacyPolicy from '../views/privacy-policy-scr.vue';
import TermsAndConditions from '../views/t&c-scr.vue';
import Help from '../views/help-scr.vue';
import Blog from '../views/blog-scr.vue';
import Drops from '../views/drops-scr.vue';
import Customize from '../views/customize-scr.vue';
import Cart from '../views/cart-scr.vue';
import Account from '../views/account-scr.vue';
import Overview from '../views/overview-scr.vue';
import Settings from '../views/settings-scr.vue';
import SignUp from '../views/sign-up-scr.vue';
import Login from '../views/login-scr.vue';
import EmailVerification from '../views/email-verification-view.vue';
import PageNotFound from '../views/page-not-found-scr.vue';

const routes = [
    {
        name: 'Home',
        path: '/',
        component: Home,
    },
    
    {
        name: 'Shop',
        path: '/shop',
        component: Shop,
    },

    {
        name: 'Item Preview',
        path: '/shop/item/:itemId',
        component: ItemPreview,
    },

    {
        name: 'About',
        path: '/about',
        component: About,
    },

    {
        name: 'Contact',
        path: '/contact', 
        component: Contact,
    },

    {
        name: 'Privacy Policy',
        path: '/privacy-policy', 
        component: PrivacyPolicy,
    },

    {
        name: 'Terms & Conditions',
        path: '/terms-&-conditions',
        component: TermsAndConditions,
    },

    {
        name: 'Help',
        path: '/help',
        component: Help,
    },

    {
        name: 'Blog',
        path: '/blog',
        component: Blog,
    },

    {
        name: 'Drops',
        path: '/drops',
        component: Drops,
    },

    {
        name: 'Customize',
        path: '/customize',
        component: Customize,
    },

    {
        name: 'Cart',
        path: '/cart',
        component: Cart,
    },

    {
        name: 'Account',
        path: '/profile',
        component: Account,
    },

    {
        name: 'Overview',
        path: '/overview',
        component: Overview,
    },

    {
        name: 'Settings',
        path: '/settings',
        component: Settings,
    },

    {
        name: 'SignUp',
        path: '/signup',
        component: SignUp,
    },

    {
        name: 'Login',
        path: '/login',
        component: Login,
    },

    {
        name: 'Email Verification',
        path: '/email-verification/:verificationCode',
        component: EmailVerification,
    },

    {
        name: 'PageNotFound',
        path: '/:pathMatch(.*)*',
        component: PageNotFound,
    },
    
];

const router = createRouter({
    history: createWebHistory(),
    routes,
    scrollBehavior() {
        return { top: 0 };
    }
})

export default router;