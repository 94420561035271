<template>
    <div>
        <div class="container">
                <div class="customize-design-banner-box">
                    <div class="title-link-wrapper">
                        <div>
                            <p class="title">CUSTOMIZE YOUR <br> OWN DESIGN</p>
                            <router-link v-if="isBtnVisible" to="/customize">
                                Go
                                <font-awesome-icon class="f-icon" :icon="['fa', 'long-arrow-right']" />
                            </router-link>
                        </div>
                    </div>
                </div>
            </div>
    </div>
</template>

<script>
export default {
    props: {
        isBtnVisible: {
            type: Boolean,
            default: true,
        }
    },
}
</script>

<style scoped>
    .customize-design-banner-box {
    padding-bottom: 50%;
    background-color: white;
    background-image: url('../../assets/images/home/customize-banner.png');
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center center;
    position: relative;
}

.customize-design-banner-box .title-link-wrapper {
    width: 100%;
    position: absolute;
    bottom: 0;
}

.customize-design-banner-box .title-link-wrapper div {
    padding: 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.customize-design-banner-box .title-link-wrapper div .title {
    font-size: 50px;
    font-weight: bold;
}

.customize-design-banner-box .title-link-wrapper div a {
    width: 45px;
    padding: 10px 20px;
    background-color: rgba(0, 0, 0, 0.702);
    color: white;
    font-weight: bold;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 20px;
}

@media only screen and (max-width: 998px) {
    .customize-design-banner-box .title-link-wrapper div .title {
        font-size: 30px;
    }
}

@media only screen and (max-width: 768px) {
    .customize-design-banner-box .title-link-wrapper div {
        padding: 10px;
    }
}

@media only screen and (max-width: 575px) {
    .customize-design-banner-box .title-link-wrapper div .title {
        font-size: 13px;
    }

    .customize-design-banner-box {
        height: 40vh;
    }
}
</style>