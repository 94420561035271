<template>
    <div class="bg-gradient">
        <Navigation :showTransition="false" />
        <Loader :loading="isLoading" />
        <NotificationBox :isVisible="notify.isVisible" :type="notify.type" :title="notify.title" :message="notify.message"
            @notificationIsVisible="notifyIsVisible()" />
        <div v-if="isCheckoutPopupIsVisible">
            <CheckoutPopup :itemsData="selectedCartItems" :subTotal="subTotal" :discount="discount" :total="total"
                @closeCheckoutPopup="closeCheckoutPopup()" />
        </div>
        <div class="main container res-px">
            <p class="txt-shopping-cart txt-lg">Shopping Cart</p>
            <div class="select-all-wrapper">
                <input v-model="isSelectAllItems" @change="triggerSelectAll()" type="checkbox" name="" id="">
                <p>Select All</p>
            </div>
            <div class="cart-items-summery-container">
                <div v-if="isLoading === false && cartItems.length === 0" class="items-empty">
                    <img src="../assets/images/empty-null samples/shop-no-items.webp" alt="">
                </div>
                <div></div>
                <div v-if="cartItems.length > 0" class="items-container">
                    <div v-for="(item, index) in cartItems" :key="index" class="items-main-box">
                        <input v-model="item.isSelected" @change="itemSelect(index)" class="inp-item-check" type="checkbox"
                            name="" id="">
                        <div @click="this.$router.push(`/shop/item/${item.item_id}`)" class="items-wrapper">
                            <div class="img-box">
                                <img class="img-item" :src="item.img_path_1" alt="">
                            </div>
                            <div class="items-data-btns-qty-wrapper">
                                <div class="item-data-wrapper">
                                    <p class="txt-title">{{ item.name }}</p>
                                    <p v-if="item.sizes !== 'all'" class="txt-size txt-col-light txt-sm">Size {{ item.size
                                    }}</p>
                                    <p class="txt-price"><span class="txt-sm">LKR</span> {{ Math.round(item.price -
                                        (item.price * (item.discount / 100))).toLocaleString() }}</p>
                                    <p v-if="item.discount !== 0" class="txt-discount"><span
                                            class="txt-del txt-col-light"><span class="txt-sm">LKR </span><span
                                                class="txt-price">{{ item.price }}</span></span><span
                                            class="txt-discount-precentage">-{{ item.discount }}%</span></p>
                                    <p v-if="item.sizes !== 'all'" class="txt-remaining txt-col-light txt-sm">Remaining {{
                                        item.stock }}</p>
                                </div>
                                <div class="qty-del-wish-wrapper">
                                    <div @click.stop class="wishlist-delete-btns-wrapper">
                                        <button class="btn-wishlist">
                                            <font-awesome-icon class="f-icon" :icon="['fa', 'heart']" />
                                        </button>
                                        <button @click="removeItem(index)" class="btn-delete">
                                            <font-awesome-icon class="f-icon" :icon="['fa', 'trash']" />
                                        </button>
                                    </div>
                                    <div @click.stop class="quantity-wrapper">
                                        <button @click="quantityDecrease(index)" class="btn-qty-dec">
                                            <font-awesome-icon class="f-icon" :icon="['fa', 'minus']" />
                                        </button>
                                        <div class="txt-qty txt-sm">{{ item.quantity }}</div>
                                        <button @click="quantityIncrease(index)" class="btn-qty-inc">
                                            <font-awesome-icon class="f-icon" :icon="['fa', 'plus']" />
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="summery-wrapper">
                    <SummeryTable :subTotal="subTotal" :discount="discount" :total="total" @checkout="checkout()" />
                </div>
            </div>
        </div>
        <div class="sm-proceed-to-pay-box res-px">
            <p class="txt-title">Total</p>
            <p class="txt-total"><span class="txt-sm">LKR</span> <br>{{ total === 0 ? '-' : total.toLocaleString() }}</p>
            <button @click="checkout()" class="btn-checkout">Checkout</button>
        </div>
    </div>
</template>

<script>
/* eslint-disable */
import { useHead } from '@vueuse/head';
import Navigation from '../components/common/navigation-com.vue';
import BtnTxtMdRound from '../components/common/btn-txt-md-round-com.vue';
import CheckoutPopup from '../components/common/checkout-popup-com.vue';
import { setToken } from '../utils/helper';
import { viewCart, removeFromCart } from '../services/api';
import NotificationBox from '../components/common/notification-box-com.vue';
import Loader from '../components/common/loading-com.vue';
import SummeryTable from '../components/checkout/summery-table-com.vue'

export default {
    data() {
        return {
            token: null,
            cartItems: [],
            selectedCartItems: [],
            notify: { isVisible: false, type: '', title: '', message: '' },
            isSelectAllItems: false,
            subTotal: 0,
            discount: 0,
            total: 0,
            isCheckoutPopupIsVisible: false,
            isLoading: false
        }
    },

    components: {
        Navigation,
        BtnTxtMdRound,
        CheckoutPopup,
        NotificationBox,
        Loader,
        SummeryTable,
    },

    mounted() {
        this.token = setToken();
        this.loadItems();
        this.setMetaData();
    },

    methods: {
        setMetaData() {
            useHead({
                title: `CART | CUSTOM SNEAKERS`,
                meta: [
                    {
                        name: 'description',
                        content: ``
                    },
                ],
            });
        },

        notifyIsVisible(value) {
            this.notify = { isVisible: value, type: '', title: '', message: '' };
        },

        async loadItems() {
            this.isLoading = true;

            if (this.token !== null) {
                const response = await viewCart(this.token);
                this.cartItems = response.data.map(item => ({ ...item, isSelected: false }));
                this.isLoading = false;
            } else {
                this.isLoading = false;
                this.$router.push('/login');
            }
        },

        async removeItem(index) {
            this.isLoading = true;
            try {
                await removeFromCart(this.token, this.cartItems[index].id);
                if (this.cartItems[index].isSelected === true) {
                    this.subTotal -= this.cartItems[index].price * this.cartItems[index].quantity;
                    this.total -= Math.round(this.cartItems[index].price - (this.cartItems[index].price * (this.cartItems[index].discount / 100)) * this.cartItems[index].quantity);
                    this.discount = this.subTotal - this.total;

                }
                this.cartItems.splice(index, 1);
                this.isLoading = false;
            } catch (error) {
                this.isLoading = false;
                this.notify = { isVisible: true, type: 'error', title: error.response.data.title, message: error.response.data.message };
            }
        },

        quantityDecrease(index) {
            if (this.cartItems[index].quantity > 1) {
                this.cartItems[index].quantity -= 1;
                if (this.cartItems[index].isSelected === true) {
                    this.subTotal -= this.cartItems[index].price;
                    this.total -= Math.round(this.cartItems[index].price - (this.cartItems[index].price * (this.cartItems[index].discount / 100)));
                    this.discount = this.subTotal - this.total;
                    let itemIndex = this.selectedCartItems.findIndex(item => item.id === this.cartItems[index].id);
                    this.selectedCartItems[itemIndex].quantity = this.cartItems[index].quantity;
                }
            }
        },

        quantityIncrease(index) {
            if (this.cartItems[index].sizes !== 'all') {
                if (this.cartItems[index].quantity < this.cartItems[index].stock) {
                    this.cartItems[index].quantity += 1;
                    if (this.cartItems[index].isSelected === true) {
                        this.subTotal += this.cartItems[index].price;
                        this.total += Math.round(this.cartItems[index].price - (this.cartItems[index].price * (this.cartItems[index].discount / 100)));
                        this.discount = this.subTotal - this.total;
                        let itemIndex = this.selectedCartItems.findIndex(item => item.id === this.cartItems[index].id);
                        this.selectedCartItems[itemIndex].quantity = this.cartItems[index].quantity;
                    }
                }
            } else {
                this.cartItems[index].quantity += 1;
                if (this.cartItems[index].isSelected === true) {
                    this.subTotal += this.cartItems[index].price;
                    this.total += Math.round(this.cartItems[index].price - (this.cartItems[index].price * (this.cartItems[index].discount / 100)));
                    this.discount = this.subTotal - this.total;
                    let itemIndex = this.selectedCartItems.findIndex(item => item.id === this.cartItems[index].id);
                    this.selectedCartItems[itemIndex].quantity = this.cartItems[index].quantity;
                }
            }
        },

        itemSelect(index) {
            if (this.cartItems[index].isSelected === true) {
                this.subTotal += this.cartItems[index].price * this.cartItems[index].quantity;
                this.total += Math.round(this.cartItems[index].price - (this.cartItems[index].price * (this.cartItems[index].discount / 100))) * this.cartItems[index].quantity;
                this.discount = this.subTotal - this.total;
                this.selectedCartItems.push({ id: this.cartItems[index].id, userId: this.token, itemId: this.cartItems[index].item_id, itemName: this.cartItems[index].name, quantity: this.cartItems[index].quantity, price: this.cartItems[index].price, discount: this.cartItems[index].discount, size: this.cartItems[index].size });
            } else {
                this.subTotal -= this.cartItems[index].price * this.cartItems[index].quantity;
                this.total -= Math.round(this.cartItems[index].price - (this.cartItems[index].price * (this.cartItems[index].discount / 100))) * this.cartItems[index].quantity;
                this.discount = this.subTotal - this.total;
                let removedItemIndex = this.selectedCartItems.findIndex(item => item.id === this.cartItems[index].id);
                this.selectedCartItems.splice(removedItemIndex, 1);
            }

            //if all items selected set isSelectAllItems to true or else false. 
            if (this.cartItems.every(cartItem => cartItem.isSelected === true)) {
                this.isSelectAllItems = true;
            } else {
                this.isSelectAllItems = false;
            }
        },

        triggerSelectAll() {
            this.subTotal = 0;
            this.discount = 0;
            this.total = 0;
            this.selectedCartItems = [];


            if (this.isSelectAllItems === true) {
                this.cartItems = this.cartItems.map(item => ({ ...item, isSelected: true }));
                for (const cartItem of this.cartItems) {
                    this.subTotal += cartItem.price * cartItem.quantity;
                    this.total += Math.round(cartItem.price - (cartItem.price * (cartItem.discount / 100))) * cartItem.quantity;
                    this.selectedCartItems.push({ id: cartItem.id, userId: this.token, itemId: cartItem.item_id, itemName: cartItem.name, quantity: cartItem.quantity, price: cartItem.price, discount: cartItem.discount, size: cartItem.size });
                }
                this.discount = this.subTotal - this.total;
            } else {
                this.cartItems = this.cartItems.map(item => ({ ...item, isSelected: false }));
            }
        },

        checkout() {
            if (this.subTotal === 0) {
                this.notify = { isVisible: true, type: 'error', title: 'Warning!', message: 'Please select items in the cart.' };
            } else {
                this.isCheckoutPopupIsVisible = true;
            }
        },

        closeCheckoutPopup(value) {
            this.isCheckoutPopupIsVisible = value;
        },
    },
}
</script>

<style scoped>
.txt-col-light {
    color: rgb(160, 160, 160);
}

.txt-del {
    text-decoration: line-through;
}

.main {
    min-height: 100vh;
    padding-top: 20px;
}

.select-all-wrapper {
    margin-top: 10px;
    display: flex;
    align-items: center;
}

.select-all-wrapper input {
    margin-right: 5px;
}

.cart-items-summery-container {
    margin-top: 50px;
    display: flex;
    justify-content: space-between;
}

.items-empty{
    width: 100%;
    display: flex;
    justify-content: center;
}

.items-empty {
    max-width: 100%;
}

.items-container {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.items-main-box {
    width: 100%;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
}

.inp-item-check {
    margin-right: 10px;
    color: var(--primary-col);
}

.items-wrapper {
    max-width: 600px;
    width: 100%;
    flex-shrink: 1;
    padding: 10px;
    border-radius: 5px;
    display: flex;
    background-color: white;
    cursor: pointer;
}

.items-wrapper .img-box {
    width: 100px;
    height: 100px;
    margin-right: 10px;
    flex-shrink: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.items-wrapper .img-box .img-item {
    max-width: 100%;
    max-height: 100%;
}

.items-data-btns-qty-wrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.items-wrapper .item-data-wrapper {
    width: 100%;
    overflow: hidden;
    flex-shrink: 1;
}

.items-wrapper .item-data-wrapper .txt-title {
    font-size: 20px;
    font-weight: bold;
}

.items-wrapper .item-data-wrapper .txt-price {
    font-size: 17px;
    font-weight: 900;
}

.items-wrapper .item-data-wrapper .txt-discount-precentage {
    margin-left: 10px;
    color: red;
}

.items-wrapper .qty-del-wish-wrapper {
    width: 80px;
    margin-left: 10px;
    flex-shrink: 0;
}

.wishlist-delete-btns-wrapper {
    width: 100%;
    margin-bottom: 20px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.wishlist-delete-btns-wrapper .btn-wishlist,
.wishlist-delete-btns-wrapper .btn-delete {
    width: 25px;
    height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    border-radius: 5px;
    background-color: rgb(222, 222, 222);
}

.wishlist-delete-btns-wrapper .btn-wishlist {
    margin-right: 10px;
}

.qty-del-wish-wrapper .quantity-wrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.qty-del-wish-wrapper .quantity-wrapper .btn-qty-dec,
.qty-del-wish-wrapper .quantity-wrapper .btn-qty-inc {
    width: 25px;
    height: 25px;
    border: none;
    flex-shrink: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--primary-col);
}

.qty-del-wish-wrapper .quantity-wrapper .btn-qty-dec {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
}

.qty-del-wish-wrapper .quantity-wrapper .btn-qty-inc {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
}

.qty-del-wish-wrapper .quantity-wrapper .f-icon {
    width: 10px;
    height: 10px;
    color: white;
}

.qty-del-wish-wrapper .quantity-wrapper .txt-qty {
    width: 100%;
    height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: aliceblue;
}

.sm-proceed-to-pay-box {
    display: none;
}

@media only screen and (max-width: 1000px) {

    .items-wrapper {
        max-width: 100%;
    }

    .sm-proceed-to-pay-box {
        height: 80px;
        border-top: 1px solid rgba(0, 0, 0, 0.174);
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;
        background-color: white;
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: sticky;
        bottom: 0;
        left: 0;
    }

    .sm-proceed-to-pay-box .txt-title,
    .sm-proceed-to-pay-box .txt-total {
        font-size: 20px;
        font-weight: bold;
    }

    .sm-proceed-to-pay-box .btn-checkout {
        padding: 15px 30px;
        border: 2px solid var(--primary-col);
        border-radius: 20px;
        background-color: var(--primary-col);
        color: white;
        transition: .5s;
    }

    .sm-proceed-to-pay-box .btn-checkout:hover {
        background-color: white;
        color: var(--primary-col);
    }

}

@media only screen and (max-width: 575px) {
    .items-data-btns-qty-wrapper {
        flex-direction: column;
        align-items: flex-end;
    }

    .items-wrapper .item-data-wrapper .txt-title {
        font-size: 17px;
    }

    .wishlist-delete-btns-wrapper {
        width: 100%;
        margin-bottom: 10px;
    }

    .qty-del-wish-wrapper {
        margin-top: 10px;
    }

    .sm-proceed-to-pay-box {
        height: 60px;
    }

    .sm-proceed-to-pay-box .txt-title,
    .sm-proceed-to-pay-box .txt-total {
        font-size: 17px;
    }

    .sm-proceed-to-pay-box .btn-checkout {
        padding: 10px;
    }
}
</style>