<template>
    <div class="bg-gradient">
        <Loader :loading="isLoading" />
        <Navigation />
        <NotificationBox :isVisible="notify.isVisible" :type="notify.type" :title="notify.title" :message="notify.message"
            @notificationIsVisible="notifyIsVisible()" />
        <div class="main container res-px">
            <CustomizeBanner :isBtnVisible="false" />
            <div class="form-container">
                <div class="form-type-btns-wrapper">
                    <button @click="formTypeSelect('customize')"
                        :class="selectedFormType === 'customize' ? 'btn-form-type-sel' : 'btn-form-type'">CUSTOMIZE</button>
                    <button @click="formTypeSelect('restore')"
                        :class="selectedFormType === 'restore' ? 'btn-form-type-sel' : 'btn-form-type'">RESTORE</button>
                    <button @click="formTypeSelect('pre-owned')"
                        :class="selectedFormType === 'pre-owned' ? 'btn-form-type-sel' : 'btn-form-type'">PRE-OWNED</button>
                </div>
                <div v-if="selectedFormType === 'customize'" class="customize-wrapper">
                    <div class="inp-box-grid">
                        <div class="inp-box">
                            <p class="txt-label">Item</p>
                            <input v-model="item" @focus="dropdonwnTrigger('item', true)"
                                @blur="dropdonwnTrigger('item', false)"
                                :class="errors.item === '' ? 'inp-text' : 'inp-text-error'" type="text" name="" id=""
                                placeholder="Type here to search the item....">
                            <p v-if="errors.item !== ''" class="txt-error txt-sm">{{ errors.item }}</p>
                            <transition name="dropdown">
                                <div v-if="itemsDropdownIsVisible" class="dropdown-box">
                                    <div v-if="isItemsLoading" class="sub-loading-wrapper">
                                        <div class="loader"></div>
                                    </div>
                                    <div v-for="(item, index) in items" :key="index" @click="itemSelect(index)"
                                        class="loadItem-wrapper">
                                        <div class="loadItem-img-box">
                                            <img :src="item.img_path_1" alt="">
                                        </div>
                                        <div class="loadItem-content">
                                            <p class="title">{{ item.name }}</p>
                                            <p v-if="item.brand !== 'all'" class="brand txt-sm">{{ item.brand }}</p>
                                        </div>
                                    </div>
                                </div>
                            </transition>
                        </div>
                        <div class="inp-box">
                            <p class="txt-label">Size</p>
                            <input v-model="size" @focus="dropdonwnTrigger('size', true)"
                                @blur="dropdonwnTrigger('size', false)"
                                :class="errors.item === '' ? 'inp-text' : 'inp-text-error'" type="text" name="" id=""
                                placeholder="select a size" readonly>
                            <p v-if="errors.size !== ''" class="txt-error txt-sm">{{ errors.size }}</p>
                            <transition name="dropdown">
                                <div v-if="itemSizesDropdownIsVisible" class="dropdown-box">
                                    <div v-for="(item, index) in itemSizes" :key="index" @click="sizeSelect(index)"
                                        class="size-select-box">
                                        <p>{{ item.size }}</p>
                                    </div>
                                </div>
                            </transition>
                        </div>
                    </div>
                    <div class="inp-box-grid">
                        <div class="inp-box">
                            <p class="txt-label">Upload your artwork</p>
                            <label for="upload-artwork-img" :class="errors.artwork === '' ? 'lbl-img' : 'lbl-img-error'">
                                <img :src="artworkURL" alt="">
                                <font-awesome-icon v-if="artworkURL === ''" class="f-icon" :icon="['fa', 'file-image']" />
                                <input class="inp-img" type="file" name="" id="upload-artwork-img"
                                    accept=".jpg, .jpeg, .png" @change="artworkImgUpload()" ref="artworkImg">
                            </label>
                            <p v-if="errors.artwork !== ''" class="txt-error txt-sm">{{ errors.artwork }}</p>
                        </div>
                        <div class="finishing-qty-wrapper">
                            <div>
                                <p class="txt-label">Quantity</p><br>
                                <div class="qty-wrapper">
                                    <button @click="quantityDecrease()" class="btn-qty-dec">
                                        <font-awesome-icon class="f-icon" :icon="['fa', 'minus']" />
                                    </button>
                                    <div class="qty-box">
                                        <p class="txt-qty txt-sm">{{ quantity }}</p>
                                    </div>
                                    <button @click="quantityIncrease()" class="btn-qty-inc">
                                        <font-awesome-icon class="f-icon" :icon="['fa', 'plus']" />
                                    </button>
                                </div>
                                <p v-if="errors.quantity !== ''" class="txt-error txt-sm">{{ errors.quantity }}</p>
                            </div>
                            <div class="inp-box">
                                <p class="txt-label">Finishing</p><br>
                                <div class="inp-radio-wrapper">
                                    <input class="inp-radio" v-model="finishing" type="radio" name="finishing" id="matte"
                                        value="Matte">
                                    <label for="matte">Matte</label>
                                </div>
                                <div class="inp-radio-wrapper">
                                    <input class="inp-radio" v-model="finishing" type="radio" name="finishing" id="shine" value="Shine">
                                    <label for="shine">Shine</label>
                                </div>
                                <p v-if="errors.finishing !== ''" class="txt-error txt-sm">{{ errors.finishing }}</p>
                            </div>
                        </div>
                    </div>
                    <div class="inp-box">
                        <p class="txt-label">Message</p>
                        <textarea v-model="message" :class="errors.message === '' ? 'area-text' : 'area-text-error'" name=""
                            id="" rows="10" placeholder="Tell us in detail how you want it"></textarea>
                        <p v-if="errors.message !== ''" class="txt-error txt-sm">{{ errors.message }}</p>
                    </div>
                </div>
                <div v-if="selectedFormType === 'restore'" class="restore-wrapper">
                    <div class="inp-box-grid">
                        <div class="inp-box">
                            <p class="txt-label">Name</p>
                            <input v-model="name" :class="errors.name === '' ? 'inp-text' : 'inp-text-error'" type="text"
                                name="" id="" placeholder="Type here the name">
                            <p v-if="errors.name !== ''" class="txt-error txt-sm">{{ errors.name }}</p>
                        </div>
                        <div class="inp-box">
                            <p class="txt-label">Size</p>
                            <input v-model="size" :class="errors.size === '' ? 'inp-text' : 'inp-text-error'" type="text"
                                name="" id="" placeholder="Enter the size here">
                            <p v-if="errors.size !== ''" class="txt-error txt-sm">{{ errors.size }}</p>
                        </div>
                    </div>
                    <div class="inp-box-grid">
                        <div class="inp-box">
                            <p class="txt-label">Brand</p>
                            <input v-model="brand" :class="errors.brand === '' ? 'inp-text' : 'inp-text-error'" type="text"
                                name="" id="" placeholder="Type here the name">
                            <p v-if="errors.brand !== ''" class="txt-error txt-sm">{{ errors.brand }}</p>
                        </div>
                        <div class="inp-box">
                            <p class="txt-label">Model (optional)</p>
                            <input v-model="model" class="inp-text" type="text" name="" id=""
                                placeholder="Enter the size here">
                        </div>
                    </div>
                    <div class="inp-box-grid">
                        <div class="inp-box">
                            <p class="txt-label">Upload your item</p>
                            <label for="upload-item-img" :class="errors.image === '' ? 'lbl-img' : 'lbl-img-error'">
                                <img :src="imageURL" alt="">
                                <font-awesome-icon v-if="imageURL === ''" class="f-icon" :icon="['fa', 'file-image']" />
                                <input class="inp-img" type="file" name="" id="upload-item-img" accept=".jpg, .jpeg, .png"
                                    @change="imageUpload()" ref="image">
                            </label>
                            <p v-if="errors.image !== ''" class="txt-error txt-sm">{{ errors.image }}</p>
                        </div>
                    </div>
                    <div class="inp-box">
                        <p class="txt-label">Message</p>
                        <textarea v-model="message" :class="errors.message === '' ? 'area-text' : 'area-text-error'" name=""
                            id="" rows="10" placeholder="Tell us in detail how you want it"></textarea>
                        <p v-if="errors.message !== ''" class="txt-error txt-sm">{{ errors.message }}</p>
                    </div>
                </div>
                <div v-if="selectedFormType === 'pre-owned'" class="pre-owned-wrapper">
                    <div class="inp-box-grid">
                        <div class="inp-box">
                            <p class="txt-label">Name</p>
                            <input v-model="name" :class="errors.name === '' ? 'inp-text' : 'inp-text-error'" type="text"
                                name="" id="" placeholder="Type here the name">
                            <p v-if="errors.name !== ''" class="txt-error txt-sm">{{ errors.name }}</p>
                        </div>
                        <div class="inp-box">
                            <p class="txt-label">Size</p>
                            <input v-model="size" :class="errors.size === '' ? 'inp-text' : 'inp-text-error'" type="text"
                                name="" id="" placeholder="Enter the size here">
                            <p v-if="errors.size !== ''" class="txt-error txt-sm">{{ errors.size }}</p>
                        </div>
                    </div>
                    <div class="inp-box-grid">
                        <div class="inp-box">
                            <p class="txt-label">Brand</p>
                            <input v-model="brand" :class="errors.brand === '' ? 'inp-text' : 'inp-text-error'" type="text"
                                name="" id="" placeholder="Type here the name">
                            <p v-if="errors.brand !== ''" class="txt-error txt-sm">{{ errors.brand }}</p>
                        </div>
                        <div class="inp-box">
                            <p class="txt-label">Model (optional)</p>
                            <input v-model="model" class="inp-text" type="text" name="" id=""
                                placeholder="Enter the size here">
                        </div>
                    </div>
                    <div class="inp-box-grid">
                        <div class="inp-box">
                            <p class="txt-label">Upload your item</p>
                            <label for="upload-item-img" :class="errors.image === '' ? 'lbl-img' : 'lbl-img-error'">
                                <img :src="imageURL" alt="">
                                <font-awesome-icon v-if="imageURL === ''" class="f-icon" :icon="['fa', 'file-image']" />
                                <input class="inp-img" type="file" name="" id="upload-item-img" accept=".jpg, .jpeg, .png"
                                    @change="imageUpload()" ref="image">
                            </label>
                            <p v-if="errors.image !== ''" class="txt-error txt-sm">{{ errors.image }}</p>
                        </div>
                        <div class="inp-box">
                            <p class="txt-label">Upload your artwork</p>
                            <label for="upload-artwork-img" :class="errors.artwork === '' ? 'lbl-img' : 'lbl-img-error'">
                                <img :src="artworkURL" alt="">
                                <font-awesome-icon v-if="artworkURL === ''" class="f-icon" :icon="['fa', 'file-image']" />
                                <input class="inp-img" type="file" name="" id="upload-artwork-img"
                                    accept=".jpg, .jpeg, .png" @change="artworkImgUpload()" ref="artworkImg">
                            </label>
                            <p v-if="errors.artwork !== ''" class="txt-error txt-sm">{{ errors.artwork }}</p>
                        </div>
                    </div>
                    <div class="inp-box">
                        <p class="txt-label">Message</p>
                        <textarea v-model="message" :class="errors.message === '' ? 'area-text' : 'area-text-error'" name=""
                            id="" rows="10" placeholder="Tell us in detail how you want it"></textarea>
                        <p v-if="errors.message !== ''" class="txt-error txt-sm">{{ errors.message }}</p>
                    </div>
                </div>
                <div class="submit-btn-wrapper">
                    <BtnTxtMdRound :buttonText="'Submit'" @custom-click="submit()" />
                </div>
            </div>
            <br><br>
        </div>
    </div>
</template>

<script>
/* eslint-disable */
import { useHead } from '@vueuse/head';
import Navigation from '../components/common/navigation-com.vue';
import NotificationBox from '../components/common/notification-box-com.vue';
import Loader from '../components/common/loading-com.vue';
import BtnTxtMdRound from '../components/common/btn-txt-md-round-com.vue';
import CustomizeBanner from '../components/common/customize-design-banner-com.vue';
import { setToken } from '../utils/helper';
import { getItems, getItemData, sumbmitCustomizeForm } from '../services/api';

export default {

    data() {
        return {
            isLoading: false,
            notify: { isVisible: false, type: '', title: '', message: '' },
            token: null,
            selectedFormType: 'customize',
            item: this.$route.query.itemName === undefined ? '' : this.$route.query.itemName,
            selectedItemId: '',
            size: '',
            quantity: 1,
            finishing: '',
            name: '',
            brand: '',
            model: '',
            message: '',
            image: '',
            imageURL: '',
            artwork: '',
            artworkURL: '',
            errors: {
                item: '',
                size: '',
                quantity: '',
                finishing: '',
                name: '',
                brand: '',
                message: '',
                image: '',
                artwork: '',
            },
            isItemsLoading: false,
            items: [],
            itemSizes: [],
            itemsDropdownIsVisible: false,
            itemSizesDropdownIsVisible: false,
            timeoutId: null,
        }
    },

    components: {
        Navigation,
        NotificationBox,
        BtnTxtMdRound,
        Loader,
        CustomizeBanner,
    },

    mounted() {
        this.userAuth();
        this.setMetaData();
        this.getItemDataFromQuery();
    },

    watch: {
        item(val) {
            this.itemsLoad(val);
        }
    },

    methods: {
        setMetaData() {
            useHead({
                title: `CUSTOMIZE | CUSTOM SNEAKERS`,
                meta: [
                    {
                        name: 'description',
                        content: ``
                    },
                ],
            });
        },

        notifyIsVisible(value) {
            this.notify = { isVisible: value, type: '', title: '', message: '' };
        },

        userAuth() {
            this.token = setToken()
            if (this.token === null) {
                this.$router.push('/login');
            }
        },

        async getItemDataFromQuery() {
            const itemId = this.$route.query.id;
            if (itemId !== undefined) {
                this.isItemsLoading = true;
                try {
                    const response = await getItemData(itemId);
                    this.items = [];
                    this.items = [
                        { id: response.data.id, name: response.data.name, brand: response.data.brand, img_path_1: response.data.img_path_1, sizes: JSON.stringify(response.data.sizes) }
                    ];
                    this.itemSelect(0);
                    this.isItemsLoading = false;
                } catch (error) {
                    this.isItemsLoading = false;
                    this.items = [];
                }
            }
        },

        formTypeSelect(type) {
            this.selectedFormType = type;
            this.item = '';
            this.selectedItemId = '';
            this.size = '';
            this.quantity = 1;
            this.finishing = '';
            this.name = '';
            this.brand = '';
            this.model = '';
            this.message = '';
            this.image = '';
            this.imageURL = '';
            this.artwork = '';
            this.artworkURL = '';
            this.errors = {
                item: '',
                size: '',
                quantity: '',
                finishing: '',
                name: '',
                brand: '',
                message: '',
                image: '',
                artwork: '',
            };
        },

        async itemsLoad(val) {
            if (this.item !== '') {
                clearTimeout(this.timeoutId);
                this.timeoutId = setTimeout(async () => {
                    try {
                        this.isItemsLoading = true;
                        const response = await getItems('shoes', 'all', 'all', 'all', val);
                        this.items = [];
                        this.items = response.data;
                        this.isItemsLoading = false;
                    } catch (error) {
                        this.isItemsLoading = false;
                        this.notify = { isVisible: true, type: 'error', title: error.response.data.title, message: error.response.data.message };
                    }
                }, 1000)
            }
        },

        itemSelect(index) {
            this.itemSizes = [];
            this.size = '';
            this.item = this.items[index].name;
            this.selectedItemId = this.items[index].id;
            this.itemSizes = JSON.parse(this.items[index].sizes);
        },

        sizeSelect(index) {
            this.size = this.itemSizes[index].size;
        },

        dropdonwnTrigger(input, isVisible) {
            this.itemsDropdownIsVisible = false;
            this.itemSizesDropdownIsVisible = false;

            if (input === 'item') {
                this.itemsDropdownIsVisible = isVisible;
                this.item = '';
                this.selectedItemId = '';
            } else if (input === 'size') {
                this.itemSizesDropdownIsVisible = isVisible;
            }
        },

        artworkImgUpload() {
            this.artwork = this.$refs.artworkImg.files[0];
            this.artworkURL = URL.createObjectURL(this.artwork);
        },

        imageUpload() {
            this.image = this.$refs.image.files[0];
            this.imageURL = URL.createObjectURL(this.image);
        },

        quantityDecrease() {
            if (this.quantity > 1) {
                this.quantity -= 1;
            }
        },

        quantityIncrease() {
            this.quantity += 1;
        },

        validation() {
            if (this.item === '' || this.selectedItemId === '') {
                this.errors.item = 'Please select an item!';
            } else {
                this.errors.item = '';
            }

            if (this.size === '') {
                this.errors.size = 'Please select a size!';
            } else {
                this.errors.size = '';
            }

            if (this.quantity === '') {
                this.errors.quantity = 'Quantity is required!';
            } else {
                this.errors.quantity = '';
            }

            if (this.finishing === '') {
                this.errors.finishing = 'Please select a finishing type!';
            } else {
                this.errors.finishing = '';
            }

            if (this.name === '') {
                this.errors.name = 'Name is required!';
            } else {
                this.errors.name = '';
            }

            if (this.brand === '') {
                this.errors.brand = 'Brand is required!';
            } else {
                this.errors.brand = '';
            }

            if (this.message === '') {
                this.errors.message = 'Message is required!';
            } else {
                this.errors.message = '';
            }

            if (this.image === '') {
                this.errors.image = 'Image is required!';
            } else {
                this.errors.image = '';
            }

            if (this.artwork === '') {
                this.errors.artwork = 'Artwork is required!';
            } else {
                this.errors.artwork = '';
            }
        },

        async submitApiCall(postData) {
            try {
                const response = await sumbmitCustomizeForm(postData);
                this.notify = { isVisible: true, type: 'success', title: response.data.title, message: response.data.message };
                this.formTypeSelect(this.selectedFormType);
            } catch (error) {
                this.notify = { isVisible: true, type: 'error', title: error.response.data.title, message: error.response.data.message };
            }
        },

        async submit() {
            this.isLoading = true;
            this.validation();

            const postData = new FormData();
            postData.append('userId', this.token);
            postData.append('type', this.selectedFormType);
            postData.append('itemId', this.selectedItemId);
            postData.append('itemName', this.name);
            postData.append('itemSize', this.size);
            postData.append('qty', this.quantity);
            postData.append('brand', this.brand);
            postData.append('model', this.model);
            postData.append('finishing', this.finishing);
            postData.append('message', this.message);
            postData.append('images', this.image);
            postData.append('images', this.artwork);

            if (this.selectedFormType === 'customize') {
                if (this.errors.item === '' && this.errors.size === '' && this.errors.artwork === '' && this.errors.quantity === '' && this.errors.finishing === '' && this.errors.message === '') {
                    await this.submitApiCall(postData);
                }
            } else if (this.selectedFormType === 'restore') {
                if (this.errors.name === '' && this.errors.size === '' && this.errors.brand === '' && this.errors.image === '' && this.errors.message === '') {
                    await this.submitApiCall(postData);
                }
            } else if (this.selectedFormType === 'pre-owned') {
                if (this.errors.name === '' && this.errors.size === '' && this.errors.brand === '' && this.errors.image === '' && this.errors.artwork === '' && this.errors.message === '') {
                    await this.submitApiCall(postData);
                }
            } else {
                this.notify = { isVisible: true, type: 'error', title: 'Unexpected Error!', message: 'Something went wrong. Please try again' };
            }

            this.isLoading = false;
        },
    },
}
</script>

<style scoped>
.main {
    min-height: 100vh;
    padding-top: 95px;
}

.form-container {
    max-width: 1000px;
    margin: 30px auto;
    padding-bottom: 30px;
    background-color: white;
}

.form-type-btns-wrapper {
    margin-bottom: 30px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
}

.btn-form-type {
    height: 40px;
    background-color: rgba(0, 0, 0, 0.054);
    border: 1px solid rgba(0, 0, 0, 0.138);
    font-weight: 900;
}

.btn-form-type-sel {
    height: 40px;
    background-color: white;
    border: 1px solid rgba(0, 0, 0, 0.138);
    font-weight: 900;
}

.customize-wrapper,
.restore-wrapper,
.pre-owned-wrapper {
    padding: 20px;
}

.inp-box-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 20px;
}

.inp-box {
    padding-bottom: 30px;
    display: flex;
    flex-direction: column;
    position: relative;
}

.txt-label {
    margin-bottom: 5px;
}

.inp-text {
    padding-left: 10px;
    height: 30px;
    border: 1px solid rgb(162, 162, 162);
    border-radius: 5px;
}

.inp-text-error {
    padding-left: 10px;
    height: 30px;
    border: 1px solid red;
    border-radius: 5px;
}

.txt-error {
    color: red;
}

.finishing-qty-wrapper {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 20px;
}

.qty-wrapper {
    display: flex;
    align-items: center;
}

.btn-qty-dec,
.btn-qty-inc {
    width: 25px;
    height: 25px;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--primary-col);
}

.btn-qty-dec {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
}

.btn-qty-inc {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
}

.btn-qty-dec .f-icon,
.btn-qty-inc .f-icon {
    width: 10px;
    height: 10px;
    color: white;
}

.qty-box {
    width: 30px;
    height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgb(249, 249, 249);
}

.area-text {
    padding-left: 10px;
    padding-top: 5px;
    border: 1px solid rgb(162, 162, 162);
    border-radius: 5px;
}


.area-text-error {
    padding-left: 10px;
    padding-top: 5px;
    border: 1px solid red;
    border-radius: 5px;
}

.dropdown-box {
    width: 100%;
    max-height: 350px;
    background-color: rgb(250, 250, 250);
    position: absolute;
    top: 56px;
    overflow-x: auto;
    z-index: 1;
}

.loadItem-wrapper {
    padding: 10px;
    display: flex;
    border-bottom: 1px solid rgba(0, 0, 0, 0.138);
    cursor: pointer;
}

.loadItem-img-box {
    width: 90px;
    height: 70px;
    margin-right: 10px;
    flex-shrink: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
}

.loadItem-img-box img {
    max-width: 100%;
    max-height: 100%;
}

.loadItem-content .title {
    margin-bottom: 5px;
}

.size-select-box {
    padding: 5px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.138);
    cursor: pointer;
}

.lbl-img {
    width: 100px;
    height: 100px;
    cursor: pointer;
    border: 2px solid var(--primary-col);
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    position: relative;
}

.lbl-img-error {
    width: 100px;
    height: 100px;
    cursor: pointer;
    border: 2px solid red;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    position: relative;
}

.lbl-img img,
.lbl-img-error img {
    max-width: 100%;
    max-height: 100%;
}

.inp-img {
    width: 0;
    opacity: 0;
    position: absolute;
    z-index: -1;
}

.lbl-img .f-icon {
    width: 40px;
    height: 40px;
    color: rgba(255, 166, 0, 0.499);
}

.lbl-img-error .f-icon {
    width: 40px;
    height: 40px;
    color: red;
}

.inp-radio-wrapper {
    margin-bottom: 5px;
}

.inp-radio-wrapper input {
    margin-right: 5px;
}



.submit-btn-wrapper {
    padding: 0 20px 20px 20px;
    display: flex;
    justify-content: center;
}

.sub-loading-wrapper {
    height: 350px;
    display: flex;
    justify-content: center;
    align-items: center;
}

@media only screen and (max-width: 575px) {
    .inp-box-grid {
        grid-template-columns: repeat(1, 1fr);
    }
}

/*  */

.dropdown-enter-from,
.dropdown-leave-to {
    max-height: 0;
}

.dropdown-enter-active,
.dropdown-leave-active {
    transition: all .3s ease;
}

/*  */
.loader {
    margin: auto;
    border: 7px solid #EAF0F6;
    border-radius: 50%;
    border-top: 7px solid var(--primary-col);
    width: 30px;
    height: 30px;
    animation: spinner 2s linear infinite;
}

@keyframes spinner {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}</style>