<template>
    <div>
        <Navigation :showTransition="false" />
        <Loader :loading="isLoading" />
        <NotificationBox :isVisible="notify.isVisible" :type="notify.type" :title="notify.title" :message="notify.message"
            @notificationIsVisible="notifyIsVisible()" />
        <div class="main bg-gradient">
            <SideNaviBar />
            <div class="overview-container">
                <div class="user-data-wrapper res-px">
                    <div class="profile-img-box">
                        <font-awesome-icon v-if="userData.profileImg === null || userData.profileImg === ''"
                            class="f-icon-user" :icon="['fa', 'user']" />
                        <img v-if="userData.profileImg !== null && userData.profileImg !== ''" :src="userData.profileImg"
                            alt="">
                    </div>
                    <div class="profile-name-email-box">
                        <p>{{ userData.firstName }} {{ userData.lastName }}</p>
                        <p class="txt-user-email txt-sm">{{ userData.userEmail }}</p>
                    </div>
                </div>
                <div class="overview-table-wrapper res-px">
                    <div class="overview-type-wrapper">
                        <button @click="overviewTypeSelect('active')"
                            :class="selectedOverviewType === 'active' ? 'btn-overview-type-active' : 'btn-overview-type'">
                            <img src="../assets/images/overview/active.webp" alt="">
                            <p>Active</p>
                        </button>
                        <button @click="overviewTypeSelect('completed')"
                            :class="selectedOverviewType === 'completed' ? 'btn-overview-type-active' : 'btn-overview-type'">
                            <img src="../assets/images/overview/completed.webp" alt="">
                            <p>Completed</p>
                        </button>
                        <button @click="overviewTypeSelect('customize')"
                            :class="selectedOverviewType === 'customize' ? 'btn-overview-type-active' : 'btn-overview-type'">
                            <img src="../assets/images/overview/submission.webp" alt="">
                            <p>Customize</p>
                        </button>
                    </div>
                    <div class="hor-line"></div>
                    <div class="overview-table-wrapper">
                        <div v-if="selectedOverviewType === 'active'" class="lg-active-container">
                            <div v-if="isItemsLoaded === true && this.itemOrders.length === 0" class="empty-items-wrapper">
                                <img src="../assets/images/empty-null samples/shop-no-items.webp" alt="">
                            </div>
                            <div class="tbl-wrapper">
                                <table v-if="itemOrders.length > 0" class="tbl-lg-overview">
                                    <thead>
                                        <tr>
                                            <th>ID</th>
                                            <th>Ordered Date</th>
                                            <th>Item Name</th>
                                            <th>Price</th>
                                            <th>Discount</th>
                                            <th>Qty</th>
                                            <th>Total</th>
                                            <th>Accepted Date</th>
                                            <th>Delivered Date</th>
                                            <th>Status</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(item, index) in itemOrders" :key="index"
                                            :style="item.status === 'delivered' ? 'background-color: #05740546; cursor: pointer;' : ''"
                                            @click="completeOrder(index)">
                                            <td>{{ item.id }}</td>
                                            <td>{{ item.ordered_date_time.date_string }}</td>
                                            <td>{{ item.item_name }}</td>
                                            <td style="text-align: right;">{{ item.price.toLocaleString() }}</td>
                                            <td class="txt-center">{{ item.discount === 0 ? '-' : item.discount + '%' }}
                                            </td>
                                            <td class="txt-center">{{ item.quantity === null ? '-' : item.quantity }}</td>
                                            <td style="text-align: right;">{{ item.total.toLocaleString() }}</td>
                                            <td :class="item.accepted_date === null ? 'txt-center' : ''">{{
                                                item.accepted_date === null ? '-' : item.accepted_date.date_string }}</td>
                                            <td :class="item.delivered_date === null ? 'txt-center' : ''">{{
                                                item.delivered_date
                                                === null ? '-' : item.delivered_date?.date_string }}</td>
                                            <td
                                                :style="item.status === 'pending' ? 'color: #FFA500; background-color: #ffa60046' : item.status === 'accepted' ? 'color: #1E90FF; background-color: #1e8fff46' : item.status === 'delivered' ? 'color: #2b902b; background-color: #05740546;' : 'color: #FF0000; background-color: #ff000046'">
                                                {{ item.status }}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div v-if="selectedOverviewType === 'completed'" class="lg-completed-container">
                            <div v-if="isItemsLoaded === true && this.itemOrders.length === 0" class="empty-items-wrapper">
                                <img src="../assets/images/empty-null samples/shop-no-items.webp" alt="">
                            </div>
                            <div class="tbl-wrapper">
                                <table v-if="itemOrders.length > 0" class="tbl-lg-overview">
                                    <thead>
                                        <tr>
                                            <th>ID</th>
                                            <th>Ordered Date</th>
                                            <th>Item Name</th>
                                            <th>Price</th>
                                            <th>Discount</th>
                                            <th>Qty</th>
                                            <th>Total</th>
                                            <th>Accepted Date</th>
                                            <th>Delivered Date</th>
                                            <th>Completed Date</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(item, index) in itemOrders" :key="index">
                                            <td>{{ item.id }}</td>
                                            <td>{{ item.ordered_date_time.date_string }}</td>
                                            <td>{{ item.item_name }}</td>
                                            <td style="text-align: right;">{{ item.price.toLocaleString() }}</td>
                                            <td class="txt-center">{{ item.discount === 0 ? '-' : item.discount }}</td>
                                            <td>{{ item.quantity === null ? '-' : item.quantity }}</td>
                                            <td style="text-align: right;">{{ item.total.toLocaleString() }}</td>
                                            <td>{{ item.accepted_date.date_string }}</td>
                                            <td>{{ item.delivered_date.date_string }}</td>
                                            <td>{{ item.completed_date?.date_string }}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div v-if="selectedOverviewType === 'customize'" class="lg-customize-container">
                            <div v-if="isItemsLoaded === true && this.customizeOrders.length === 0"
                                class="empty-items-wrapper">
                                <img src="../assets/images/empty-null samples/shop-no-items.webp" alt="">
                            </div>
                            <div class="tbl-wrapper">
                                <table v-if="customizeOrders.length > 0" class="tbl-lg-overview">
                                    <thead>
                                        <tr>
                                            <th>ID</th>
                                            <th>Submitted Date</th>
                                            <th>Item Name</th>
                                            <th>Type</th>
                                            <th>Size</th>
                                            <th>Qty</th>
                                            <th>Brand</th>
                                            <th>Finishing</th>
                                            <th>Accepted Date</th>
                                            <th>Finished Date</th>
                                            <th>Status</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(item, index) in customizeOrders" :key="index">
                                            <td>{{ item.id }}</td>
                                            <td>{{ item.ordered_date_time.date_string }}</td>
                                            <td>{{ item.item_name }}</td>
                                            <td>{{ item.type }}</td>
                                            <td class="txt-center">{{ item.item_size === null ? '-' : item.item_size }}</td>
                                            <td :class="item.quantity === null ? 'txt-center' : ''">{{ item.quantity ===
                                                null ?
                                                '-' :
                                                item.quantity
                                            }}</td>
                                            <td>{{ item.brand }}</td>
                                            <td :class="item.finishing === null ? 'txt-center' : ''">{{ item.finishing ===
                                                null
                                                ?
                                                '-' : item.finishing }}</td>
                                            <td class="txt-center">{{ item.completion_date === null ? '-' :
                                                item.completion_date.date_string
                                            }}
                                            </td>
                                            <td :class="item.delivery_date === null ? 'txt-center' : ''">{{
                                                item.delivery_date
                                                ===
                                                null ? '-' : item.delivery_date.date_string }}</td>
                                            <td
                                                :style="item.status === 'pending' ? 'color: #FFA500; background-color: #ffa60046;' : item.status === 'accepted' ? 'color: #1E90FF; background-color: #1e8fff46;' : item.status === 'finished' ? 'color: #32CD32; background-color: #32cd3246' : 'color: #FF0000; background-color: #ff000046'">
                                                {{ item.status }}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="sm-overview-container res-px">
                    <div v-if="!smIsItemSelect" class="sm-overview-box">
                        <div class="sm-overview-type-btns-wrapper">
                            <button @click="smOverviewTypeSelectLeftButton()">
                                <font-awesome-icon
                                    :class="selectedOverviewType === 'active' ? 'f-icon-arrow-left-inactive' : 'f-icon-arrow-left'"
                                    :icon="['fa', 'chevron-left']" />
                            </button>
                            <div class="sm-selected-overview-type-title-box">
                                <p>{{ selectedOverviewType }}</p>
                            </div>
                            <button @click="smOverviewTypeSelectRightButton()">
                                <font-awesome-icon
                                    :class="selectedOverviewType === 'customize' ? 'f-icon-arrow-right-inactive' : 'f-icon-arrow-right'"
                                    :icon="['fa', 'chevron-right']" />
                            </button>
                        </div>
                        <div v-if="selectedOverviewType !== 'customize'">
                            <div v-if="isItemsLoaded === true && this.itemOrders.length === 0" class="empty-items-wrapper">
                                <img src="../assets/images/empty-null samples/shop-no-items.webp" alt="">
                            </div>
                            <div v-if="itemOrders.length > 0">
                                <div v-for="(item, index) in itemOrders" :key="index" @click="smGetSelectedItemIndex(index)"
                                    class="sm-hor-line-content-wrapper">
                                    <div class="sm-hor-line"></div>
                                    <div class="sm-name-qty-wrapper">
                                        <p>{{ item.item_name }}</p>
                                        <p style="flex-shrink: 0; width: 80px; text-align: right;">{{ item.quantity === null
                                            ? '-' : 'x ' + item.quantity }}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div v-if="selectedOverviewType === 'customize'">
                            <div v-if="isItemsLoaded === true && this.customizeOrders.length === 0"
                                class="empty-items-wrapper">
                                <img src="../assets/images/empty-null samples/shop-no-items.webp" alt="">
                            </div>
                            <div v-if="customizeOrders.length > 0">
                                <div v-for="(item, index) in customizeOrders" :key="index"
                                    @click="smGetSelectedItemIndex(index)" class="sm-hor-line-content-wrapper">
                                    <div class="sm-hor-line"></div>
                                    <div class="sm-name-qty-wrapper">
                                        <p>{{ item.item_name }}</p>
                                        <p style="flex-shrink: 0; width: 40px; text-align: right;">{{ item.quantity === null
                                            ? '-' : 'x ' + item.quantity }}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- active item data view -->
                    <div v-if="smIsItemSelect && selectedOverviewType === 'active'" class="overview-sel-item-data-box">
                        <p class="txt-sel-item-id-title"><span class="span-sel-item-id-title"># </span> {{
                            itemOrders[smSelectedItemIndex]?.id
                        }}</p>
                        <div class="overview-sel-item-data-types-sel-btns-wrapper">
                            <button @click="smSelectedItemDataTypeSelect('order')"
                                :class="smSelectedItemDataType === 'order' ? 'btn-overview-sel-item-data-types-active' : 'btn-overview-sel-item-data-types'">Order</button>
                            <div></div>
                            <button @click="smSelectedItemDataTypeSelect('status')"
                                :class="smSelectedItemDataType === 'status' ? 'btn-overview-sel-item-data-types-active' : 'btn-overview-sel-item-data-types'">Status</button>
                        </div>
                        <div v-if="smSelectedItemDataType === 'order'" class="sm-sel-item-order-info-tbl-wrapper">
                            <table class="tbl-sm-sel-item-order-info">
                                <thead>
                                    <tr>
                                        <th>Item Name</th>
                                        <th>Qty</th>
                                        <th>Price (Rs)</th>
                                        <th>Discount (%)</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>{{ itemOrders[smSelectedItemIndex].item_name }}</td>
                                        <td>{{ itemOrders[smSelectedItemIndex].quantity }}</td>
                                        <td>{{ itemOrders[smSelectedItemIndex].price.toLocaleString() }}</td>
                                        <td>{{ itemOrders[smSelectedItemIndex].discount === 0 ? '-' :
                                            itemOrders[smSelectedItemIndex].discount }}</td>
                                    </tr>
                                </tbody>
                            </table>
                            <table class="tbl-sm-sel-item-order-total">
                                <thead>
                                    <tr>
                                        <th>Total</th>
                                        <th>Rs. {{ itemOrders[smSelectedItemIndex]?.total.toLocaleString() }}</th>
                                    </tr>
                                </thead>
                            </table>
                        </div>
                        <div v-if="smSelectedItemDataType === 'status'" class="sm-sel-item-status-info">
                            <div class="sm-status-info-tbl-wrapper">
                                <table class="tbl-sm-sel-item-status-info">
                                    <thead>
                                        <tr>
                                            <th>Current Status</th>
                                            <th
                                                :style="itemOrders[smSelectedItemIndex].status === 'pending' ? 'color: #FFA500; background-color: #ffa60046' : itemOrders[smSelectedItemIndex].status === 'accepted' ? 'color: #1E90FF; background-color: #1e8fff46' : itemOrders[smSelectedItemIndex].status === 'delivered' ? 'color: #2b902b; background-color: #05740546;' : 'color: #FF0000; background-color: #ff000046'">
                                                {{ itemOrders[smSelectedItemIndex].status }}</th>
                                        </tr>
                                    </thead>
                                </table>
                            </div>
                            <div class="dates-statusbar-wrapper">
                                <div class="sm-status-dates-wrapper-j-cen">
                                    <div class="sm-status-date-box">
                                        <p>Ordered Date</p>
                                        <div>
                                            <p>{{ itemOrders[smSelectedItemIndex].ordered_date_time.date_string }}</p>
                                            <font-awesome-icon class="f-icon" :icon="['fa', 'calendar-check']" />
                                        </div>
                                    </div>
                                </div>
                                <div v-if="itemOrders[smSelectedItemIndex].accepted_date"
                                    class="sm-status-dates-wrapper-j-cen">
                                    <div class="sm-status-date-box">
                                        <p>Accepted Date</p>
                                        <div>
                                            <p>{{ itemOrders[smSelectedItemIndex].accepted_date?.date_string }}</p>
                                            <font-awesome-icon class="f-icon" :icon="['fa', 'calendar-check']" />
                                        </div>
                                    </div>
                                </div>
                                <div v-if="itemOrders[smSelectedItemIndex].delivered_date"
                                    class="sm-status-dates-wrapper-j-cen">
                                    <div class="sm-status-date-box">
                                        <p>Delivered Date</p>
                                        <div>
                                            <p>{{ itemOrders[smSelectedItemIndex].delivered_date?.date_string }}</p>
                                            <font-awesome-icon class="f-icon" :icon="['fa', 'calendar-check']" />
                                        </div>
                                    </div>
                                </div>
                                <div v-if="itemOrders[smSelectedItemIndex].status !== 'pending'"
                                    class="sm-status-bar-wrapper">
                                    <p>Status Bar</p>
                                    <div class="status-bar-container">
                                        <div class="sm-status-bar-box">
                                            <input type="range" class="date-range-bar" :min="smStatusBar.min"
                                                :max="smStatusBar.max" :value="smStatusBar.value" disabled />
                                        </div>
                                        <div class="sm-status-bar-start-end-txt-box">
                                            <p class="txt-sm">Start Date</p>
                                            <p class="txt-sm">End Date</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <br>
                        <div class="sm-sel-item-box-back-btn-wrapper">
                            <BtnTxtMdRound :buttonText="'Done'" @custom-click="smHideSelectedItemData()" />
                            <BtnTxtMdRound v-if="itemOrders[smSelectedItemIndex].status === 'delivered'" :buttonText="'Complete'" @custom-click="completeOrder(smSelectedItemIndex)" /> <br>
                        </div>
                    </div>
                    <!-- completed item data view -->
                    <div v-if="smIsItemSelect && selectedOverviewType === 'completed'" class="overview-sel-item-data-box">
                        <p class="txt-sel-item-id-title"><span class="span-sel-item-id-title"># </span> {{
                            itemOrders[smSelectedItemIndex]?.id
                        }}</p>
                        <div class="overview-sel-item-data-types-sel-btns-wrapper">
                            <button @click="smSelectedItemDataTypeSelect('order')"
                                :class="smSelectedItemDataType === 'order' ? 'btn-overview-sel-item-data-types-active' : 'btn-overview-sel-item-data-types'">Order</button>
                            <div></div>
                            <button @click="smSelectedItemDataTypeSelect('status')"
                                :class="smSelectedItemDataType === 'status' ? 'btn-overview-sel-item-data-types-active' : 'btn-overview-sel-item-data-types'">Status</button>
                        </div>
                        <div v-if="smSelectedItemDataType === 'order'" class="sm-sel-item-order-info-tbl-wrapper">
                            <table class="tbl-sm-sel-item-order-info">
                                <thead>
                                    <tr>
                                        <th>Item Name</th>
                                        <th>Qty</th>
                                        <th>Price (Rs)</th>
                                        <th>Discount (%)</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>{{ itemOrders[smSelectedItemIndex]?.item_name }}</td>
                                        <td>{{ itemOrders[smSelectedItemIndex]?.quantity }}</td>
                                        <td>{{ itemOrders[smSelectedItemIndex]?.price.toLocaleString() }}</td>
                                        <td>{{ itemOrders[smSelectedItemIndex]?.discount }}</td>
                                    </tr>
                                </tbody>
                            </table>
                            <table class="tbl-sm-sel-item-order-total">
                                <thead>
                                    <tr>
                                        <th>Total</th>
                                        <th>Rs. {{ itemOrders[smSelectedItemIndex]?.total.toLocaleString() }}</th>
                                    </tr>
                                </thead>
                            </table>
                        </div>
                        <div v-if="smSelectedItemDataType === 'status'" class="sm-sel-item-status-info">
                            <div class="sm-status-info-tbl-wrapper">
                                <table class="tbl-sm-sel-item-status-info">
                                    <thead>
                                        <tr>
                                            <th>Current Status</th>
                                            <th>{{ itemOrders[smSelectedItemIndex]?.status }}</th>
                                        </tr>
                                    </thead>
                                </table>
                            </div>
                            <div class="sm-status-dates-wrapper-j-cen">
                                <div class="sm-status-date-box">
                                    <p>Ordered Date</p>
                                    <div>
                                        <p>{{ itemOrders[smSelectedItemIndex].ordered_date_time?.date_string }}</p>
                                        <font-awesome-icon class="f-icon" :icon="['fa', 'calendar-check']" />
                                    </div>
                                </div>
                            </div>
                            <div class="sm-status-dates-wrapper-j-cen">
                                <div class="sm-status-date-box">
                                    <p>Accepted Date</p>
                                    <div>
                                        <p>{{ itemOrders[smSelectedItemIndex].accepted_date?.date_string }}</p>
                                        <font-awesome-icon class="f-icon" :icon="['fa', 'calendar-check']" />
                                    </div>
                                </div>
                            </div>
                            <div class="sm-status-dates-wrapper-j-cen">
                                <div class="sm-status-date-box">
                                    <p>Delivered Date</p>
                                    <div>
                                        <p>{{ itemOrders[smSelectedItemIndex].delivered_date?.date_string }}</p>
                                        <font-awesome-icon class="f-icon" :icon="['fa', 'calendar-check']" />
                                    </div>
                                </div>
                            </div>
                            <div class="sm-status-dates-wrapper-j-cen">
                                <div class="sm-status-date-box">
                                    <p>Completed Date</p>
                                    <div>
                                        <p>{{ itemOrders[smSelectedItemIndex].completed_date?.date_string }}</p>
                                        <font-awesome-icon class="f-icon" :icon="['fa', 'calendar-check']" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="sm-sel-item-box-back-btn-wrapper">
                            <BtnTxtMdRound :buttonText="'Done'" @custom-click="smHideSelectedItemData()" /> <br>
                        </div>
                    </div>
                    <!-- customize item data view -->
                    <div v-if="smIsItemSelect && selectedOverviewType === 'customize'" class="overview-sel-item-data-box">
                        <p class="txt-sel-item-id-title"><span class="span-sel-item-id-title"># </span> {{
                            customizeOrders[smSelectedItemIndex]?.id
                        }}</p>
                        <div class="overview-sel-item-data-types-sel-btns-wrapper">
                            <button @click="smSelectedItemDataTypeSelect('order')"
                                :class="smSelectedItemDataType === 'order' ? 'btn-overview-sel-item-data-types-active' : 'btn-overview-sel-item-data-types'">Order</button>
                            <div></div>
                            <button @click="smSelectedItemDataTypeSelect('status')"
                                :class="smSelectedItemDataType === 'status' ? 'btn-overview-sel-item-data-types-active' : 'btn-overview-sel-item-data-types'">Status</button>
                        </div>
                        <div v-if="smSelectedItemDataType === 'order'" class="sm-sel-item-order-info-tbl-wrapper">
                            <table class="tbl-sm-sel-item-order-info">
                                <thead>
                                    <tr>
                                        <th>Item Name</th>
                                        <th>Type</th>
                                        <th>Brand</th>
                                        <th>Qty</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>{{ customizeOrders[smSelectedItemIndex].item_name }}</td>
                                        <td>{{ customizeOrders[smSelectedItemIndex].type }}</td>
                                        <td>{{ customizeOrders[smSelectedItemIndex].brand }}</td>
                                        <td>{{ customizeOrders[smSelectedItemIndex].quantity === null ? '-' :
                                            customizeOrders[smSelectedItemIndex].quantity }}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div v-if="smSelectedItemDataType === 'status'" class="sm-sel-item-status-info">
                            <div class="sm-status-info-tbl-wrapper">
                                <table class="tbl-sm-sel-item-status-info">
                                    <thead>
                                        <tr>
                                            <th>Current Status</th>
                                            <th :style="customizeOrders[smSelectedItemIndex]?.status === 'pending' ? 'color: #FFA500; background-color: #ffa60046' : customizeOrders[smSelectedItemIndex]?.status === 'accepted' ? 'color: #1E90FF; background-color: #1e8fff46' : customizeOrders[smSelectedItemIndex]?.status === 'finished' ? 'color: #2b902b; background-color: #05740546;' : 'color: #FF0000; background-color: #ff000046'">{{ customizeOrders[smSelectedItemIndex]?.status }}</th>
                                        </tr>
                                    </thead>
                                </table>
                            </div>
                            <div>
                                <div class="sm-status-dates-wrapper-j-sb">
                                    <div v-if="customizeOrders[smSelectedItemIndex].ordered_date_time" class="sm-status-date-box">
                                        <p>Ordered Date</p>
                                        <div>
                                            <p>{{ customizeOrders[smSelectedItemIndex].ordered_date_time.date_string }}</p>
                                            <font-awesome-icon class="f-icon" :icon="['fa', 'calendar-check']" />
                                        </div>
                                    </div>
                                    &nbsp;&nbsp;&nbsp;
                                    <div v-if="customizeOrders[smSelectedItemIndex].completion_date" class="sm-status-date-box">
                                        <p>Completion Date</p>
                                        <div>
                                            <p>{{ customizeOrders[smSelectedItemIndex].completion_date.date_string }}</p>
                                            <font-awesome-icon class="f-icon" :icon="['fa', 'calendar-check']" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="sm-sel-item-box-back-btn-wrapper">
                            <BtnTxtMdRound :buttonText="'Done'" @custom-click="smHideSelectedItemData()" /> <br>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
/* eslint-disable */
import Navigation from '../components/common/navigation-com.vue';
import SideNaviBar from '../components/profile/side-navibar-com.vue';
import { setToken } from '../utils/helper';
import { getUserData, getUserOrders, getCustomizeItems, makeOrderComplete } from '../services/api';
import BtnTxtMdRound from '../components/common/btn-txt-md-round-com.vue';
import Loader from '../components/common/loading-com.vue';
import Swal from 'sweetalert2';
import NotificationBox from '../components/common/notification-box-com.vue';

export default {

    data() {
        return {
            isLoading: false,
            notify: { isVisible: false, type: '', title: '', message: '' },
            isItemsLoaded: false,
            token: null,
            userData: {
                firstName: '',
                lastName: '',
                userEmail: '',
                profileImg: null
            },
            selectedOverviewType: 'active',
            itemOrders: [],
            customizeOrders: [],
            smIsItemSelect: false,
            smSelectedItemDataType: 'order',
            smSelectedItemIndex: null,
            smStatusBar: { min: 0, max: 0, value: 0 },
        }
    },

    components: {
        Navigation,
        SideNaviBar,
        BtnTxtMdRound,
        Loader,
        NotificationBox,
    },

    mounted() {
        this.getUserData();
        this.getUserOrders();
    },

    watch: {
        selectedOverviewType() {
            this.isItemsLoaded = false;
            this.getUserOrders();
        }
    },

    methods: {

        notifyIsVisible(value) {
            this.notify = { isVisible: value, type: '', title: '', message: '' };
        },

        async getUserData() {
            this.token = setToken();
            if (this.token !== null) {
                this.isLoading = true;
                const response = await getUserData(this.token);
                this.userData = {
                    firstName: response.data.first_name,
                    lastName: response.data.last_name,
                    userEmail: response.data.email,
                    profileImg: response.data.img_path,
                }
                this.isLoading = false;
            } else {
                this.isLoading = false;
                this.$router.push('/login');
            }
        },

        async getUserOrders() {
            this.itemOrders = [];
            this.customizeOrders = [];
            this.isLoading = true;
            try {
                if (this.selectedOverviewType !== 'customize') {
                    const response = await getUserOrders(this.selectedOverviewType, this.token);
                    this.itemOrders = response.data;
                    this.isItemsLoaded = true;
                } else {
                    const response = await getCustomizeItems(this.token);
                    this.customizeOrders = response.data;
                    this.isItemsLoaded = true;
                }
            } catch (error) {

            }

            this.isLoading = false;
        },

        overviewTypeSelect(type) {
            this.selectedOverviewType = type;
        },

        smOverviewTypeSelectLeftButton() {
            if (this.selectedOverviewType === 'customize') {
                this.selectedOverviewType = 'completed';
            } else if (this.selectedOverviewType === 'completed') {
                this.selectedOverviewType = 'active';
            }
        },

        smOverviewTypeSelectRightButton() {
            if (this.selectedOverviewType === 'active') {
                this.selectedOverviewType = 'completed';
            } else if (this.selectedOverviewType === 'completed') {
                this.selectedOverviewType = 'customize';
            }
        },

        smSelectedItemDataTypeSelect(type) {
            this.smSelectedItemDataType = type;
        },

        smGetSelectedItemIndex(index) {
            this.smSelectedItemIndex = index;
            this.smIsItemSelect = true;
            if (this.selectedOverviewType === 'active') {
                if (this.itemOrders[index].status !== 'pending' && this.itemOrders[index].status !== 'cancelled') {
                    this.smStatusBar = { min: this.itemOrders[index].ordered_date_time.date_number, max: this.itemOrders[index].expected_delivered_date.date_number, value: new Date().getTime() };
                }
            }
        },

        smHideSelectedItemData() {
            this.smIsItemSelect = false;
            this.smSelectedItemDataType = 'order';
        },

        async completeOrder(index) {
            if (this.itemOrders[index].status === 'delivered') {
                Swal.fire({
                    title: "Order Completion",
                    text: "Did you receive the order?",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "YES",
                    cancelButtonText: "NO",
                }).then(async (result) => {
                    if (result.isConfirmed) {
                        this.isLoading = true;
                        try {
                            this.token = setToken();
                            const postData = {
                                userId: this.token,
                                id: this.itemOrders[index].id,
                            };
                            const response = await makeOrderComplete(postData);
                            this.notify = { isVisible: true, type: 'success', title: response.data.title, message: response.data.message };
                            this.smHideSelectedItemData();
                            this.getUserOrders();
                        } catch (error) {
                            this.notify = { isVisible: true, type: 'error', title: error.response.data.title, message: error.response.data.message };
                        }
                        this.isLoading = false;
                    }
                });
            }
        },
    },
}
</script>

<style scoped>
.main {
    display: flex;
}

.txt-center {
    text-align: center;
}

.overview-container {
    width: 100%;
    min-height: 100vh;
}

.user-data-wrapper {
    height: 50px;
    margin-bottom: 70px;
    padding-top: 30px;
    display: flex;
    background-color: rgba(0, 0, 0, 0.052);
}

.profile-img-box {
    width: 80px;
    height: 80px;
    margin-right: 20px;
    border: 2px solid var(--primary-col);
    border-radius: 50%;
    background-color: white;
    overflow: hidden;
    flex-shrink: 0;
    object-fit: cover;
    display: flex;
    justify-content: center;
    align-items: center;
}

.profile-img-box img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.f-icon-user {
    width: 50px;
    height: 50px;
    color: rgba(0, 0, 0, 0.329);
}

.profile-name-email-box {
    padding-top: 10px;
}

.txt-user-email {
    color: rgb(167, 167, 167);
}

.overview-type-wrapper {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
}

.btn-overview-type {
    height: 55px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    border-bottom: 5px solid transparent;
    background-color: rgba(0, 0, 0, 0.082);
    font-weight: 900;
    transition: all .3s ease;
}

.btn-overview-type:hover {
    background-color: transparent;
}

.btn-overview-type-active {
    height: 55px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    border-bottom: 5px solid var(--primary-col);
    background-color: transparent;
    font-weight: 900;
}

.btn-overview-type img,
.btn-overview-type-active img {
    width: 30px;
    height: 30px;
    margin-right: 10px;
}

.hor-line {
    width: 100%;
    height: 6px;
    background-color: black;
}

.empty-items-wrapper {
    height: 60vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.empty-items-wrapper img {
    max-width: 100%;
}

.overview-table-wrapper {
    margin-top: 50px;
}

.tbl-wrapper {
    width: 100%;
    overflow-x: auto;
}

.tbl-lg-overview {
    border-collapse: collapse;
    margin-bottom: 20px;
}

.tbl-lg-overview th,
.tbl-lg-overview td {
    border-right: 1px solid #ddd;
    padding: 8px;
}

.tbl-lg-overview th:last-child,
.tbl-lg-overview td:last-child {
    border-right: none;
}


.tbl-lg-overview th {
    background-color: #e4e4e4;
}

.sm-overview-container {
    display: none;
}

@media only screen and (max-width: 1200px) {
    .main {
        min-height: auto;
        flex-direction: column;
    }
}

@media only screen and (max-width: 1000px) {

    td,
    th {
        font-size: 13px;
    }
}

@media only screen and (max-width: 768px) {

    .user-data-wrapper,
    .overview-table-wrapper {
        display: none;
    }

    .sm-overview-container {
        margin-top: 20px;
        display: flex;
        justify-content: center;
    }

    .sm-overview-box,
    .overview-sel-item-data-box {
        max-width: 500px;
        width: 100%;
        padding: 30px 10px;
        border-radius: 10px;
        background-color: white;
    }

    .sm-overview-type-btns-wrapper {
        margin-bottom: 50px;
        display: flex;
        justify-content: space-around;
    }

    .sm-overview-type-btns-wrapper button {
        border: none;
        background-color: transparent;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .sm-selected-overview-type-title-box {
        width: 120px;
    }

    .sm-overview-type-btns-wrapper p {
        font-weight: 900;
        font-size: 25px;
        text-align: center;
    }

    .f-icon-arrow-left,
    .f-icon-arrow-right {
        width: 25px;
        height: 25px;
        color: black;
    }


    .f-icon-arrow-left-inactive,
    .f-icon-arrow-right-inactive {
        width: 25px;
        height: 25px;
        color: rgb(182, 182, 182);
    }

    .sm-hor-line {
        width: 100%;
        height: 1px;
        margin: 10px 0;
        background-color: rgba(0, 0, 0, 0.153);
    }

    .sm-hor-line-content-wrapper {
        cursor: pointer;
    }

    .sm-name-qty-wrapper {
        display: flex;
        justify-content: space-between;
        gap: 10px;
    }

    .txt-sel-item-id-title {
        text-align: center;
        font-weight: 900;
    }

    .span-sel-item-id-title {
        color: rgb(175, 175, 175);
    }

    .overview-sel-item-data-types-sel-btns-wrapper {
        height: 50px;
        margin: 30px 0;
        border-radius: 10px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: rgb(253, 243, 225);
        overflow: hidden;
    }

    .btn-overview-sel-item-data-types {
        width: 100%;
        height: 50px;
        border: none;
        background-color: transparent;
        font-weight: 900;
        border-radius: 10px;
        transition: all .3s ease;
    }

    .btn-overview-sel-item-data-types-active {
        width: 100%;
        height: 50px;
        border: none;
        background-color: black;
        color: white;
        font-weight: 900;
        border-radius: 10px;
        transition: all .3s ease;
    }

    .overview-sel-item-data-types-sel-btns-wrapper div {
        width: 1px;
        height: 20px;
        background-color: rgba(0, 0, 0, 0.308);
    }

    .sm-sel-item-order-info-tbl-wrapper {
        margin-top: 20px;
        margin-bottom: 40px;
    }

    .tbl-sm-sel-item-order-info,
    .tbl-sm-sel-item-order-total,
    .tbl-sm-sel-item-status-info {
        border-collapse: collapse;
        width: 100%;
        margin-bottom: 30px;
    }

    .tbl-sm-sel-item-order-info th,
    .tbl-sm-sel-item-order-info td {
        border-bottom: 1px solid #ddd;
        border-right: 1px solid #ddd;
        padding: 8px;
        text-align: center;
    }

    .tbl-sm-sel-item-order-info th:last-child,
    .tbl-sm-sel-item-order-info td:last-child {
        border-right: none;
    }

    .tbl-sm-sel-item-order-total th,
    .tbl-sm-sel-item-order-total th {
        border-top: 1px solid #ddd;
        border-right: 1px solid #ddd;
    }

    .tbl-sm-sel-item-order-total th:last-child,
    .tbl-sm-sel-item-status-info th:last-child {
        padding: 15px 0;
        border-right: none;
    }

    .sm-sel-item-box-back-btn-wrapper {
        display: flex;
        justify-content: center;
        gap: 5px;
    }

    .sm-sel-item-box-back-btn-wrapper div {
        display: flex;
    }

    .sm-status-dates-wrapper-j-sb {
        display: flex;
        justify-content: space-between;
    }

    .sm-status-dates-wrapper-j-cen {
        margin-bottom: 20px;
        display: flex;
        justify-content: center;
    }

    .sm-status-date-box {
        width: 100%;
        margin-bottom: 20px;
    }

    .sm-status-date-box p {
        text-align: center;
    }

    .sm-status-date-box div {
        margin-top: 5px;
        padding: 20px 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: rgba(0, 0, 0, 0.093);
        border-radius: 10px;
    }

    .sm-status-date-box div .f-icon {
        width: 20px;
        height: 20px;
        margin-left: 5px;
        color: var(--primary-col);
    }

    .tbl-sm-sel-item-status-info th {
        border-bottom: 1px solid #ddd;
        border-right: 1px solid #ddd;
    }

    .sm-status-bar-wrapper {
        padding: 10px;
        margin-top: 20px;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .status-bar-container {
        width: 100%;
        padding: 20px 10px;
        background-color: rgba(0, 0, 0, 0.09);
        border-radius: 30px;
    }

    .sm-status-bar-box {
        padding: 0 20px;
        display: flex;
        flex-direction: column;
    }

    .sm-status-bar-start-end-txt-box {
        display: flex;
        justify-content: space-between;
    }

    .date-range-bar {
        -webkit-appearance: none;
        margin-bottom: 10px;
    }

    .date-range-bar::-webkit-slider-runnable-track {
        height: 5px;
        background: rgb(255, 220, 156);
    }

    .date-range-bar::-webkit-slider-thumb {
        appearance: none;
        width: 30px;
        height: 30px;
        background: orange;
        border-radius: 50%;
        border: 6px solid rgb(255, 220, 156);
        transform: translateY(-40%);
    }
}</style>