<template>
    <div>
        <button :class="[buttonColorClass, 'hover-box-shadow']" @click="handleClick()">{{ buttonText }}</button>
    </div>
</template>
<script>
export default {
    props: {
        buttonText: {
            type: String,
            default: 'Click'
        },

        buttonColor: {
            type: String,
            default: 'primary'
        }
    },

    computed: {
        buttonColorClass() {
            return `col-bg-${this.buttonColor}`;
        }
    },

    methods: {
        handleClick() {
            this.$emit('custom-click');
        }
    }
}
</script>
<style scoped>
button {
    padding: 15px 40px;
    border: none;
    border-radius: 20px;
    color: white;
    font-weight: 900;
    border: 2px solid var(--primary-col);
}

button:hover {
    background-color: transparent;
    color: var(--primary-col);
}

.col-bg-red {
    background-color: red;
    color: white;
    border: 2px solid red;
}

.col-bg-red:hover {
    background-color: transparent;
    color: red;
}
</style>