<template>
    <div>
        <transition name="notify">
            <div v-if="isVisible" class="main" :class="type === 'error' ? 'box-error' : 'box-success'">
                <div class="title-close-wrapper">
                    <p class="txt-title">{{ title }}</p>
                    <button @click="closeNotificationBox()">
                        <font-awesome-icon class="f-icon" :icon="['fa', 'close']" />
                    </button>
                </div>
                <p class="txt-content txt-sm">{{ message }}</p>
            </div>
        </transition>
    </div>
</template>
<script>
export default {

    props: {
        isVisible: {
            type: Boolean,
            default: false,
        },
        type: {
            type: String,
            default: '',
        },
        title: {
            type: String,
            default: '',
        },
        message: {
            type: String,
            default: '',
        }
    },

    watch: {
        type() {
            this.autoCloseNotificationBox();
        }
    },

    methods: {
        autoCloseNotificationBox() {
            setTimeout(() => {
                this.closeNotificationBox();
            }, 5000)
        },

        closeNotificationBox() {
            this.$emit('notificationIsVisible', false);
        }
    }
}
</script>
<style scoped>
.main {
    max-width: 280px;
    width: 100%;
    padding: 20px;
    background-color: rgb(0, 185, 0);
    border: 1px solid white;
    border-radius: 10px;
    color: white;
    position: fixed;
    top: 50px;
    right: 50px;
    z-index: 100;
}

.box-error{
    background-color: red;
}

.box-success{
    background-color: rgb(0, 185, 0);;
}

.title-close-wrapper {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
}

.title-close-wrapper button {
    background-color: transparent;
    border: 1px solid transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    transition: all .3s ease;
    flex-shrink: 0;
}

.title-close-wrapper button:hover {
    border: 1px solid white;
}

.title-close-wrapper button .f-icon {
    color: white;
    width: 15px;
    height: 15px;
}

.txt-title {
    margin-bottom: 10px;
}

@media only screen and (max-width: 575px) {
    .main {
        max-width: 250px;
        top: 20px;
        right: 10px;
    }
}

.notify-enter-from,
.notify-leave-to {
  opacity: 0;
  right: -100%;
}

.notify-enter-active,
.notify-leave-active {
  transition: all 1s ease;
}
</style>