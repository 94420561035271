<template>
    <div>
        <div class="main">
            <div class="content">
                <div class="title-back">
                    <slot name="title-back"></slot>
                </div>
                <div class="title-front">
                    <slot name="title-front"></slot>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {

}
</script>

<style scoped>
.main,
.content {
    display: flex;
    justify-content: center;
}

.main {
    margin-top: 40px;
    margin-bottom: 80px;
}

.content {
    width: 100%;
    position: relative;
}

.title-back,
.title-front {
    line-height: 0;
}

.title-back {
    position: absolute;
    font-size: 60px;
    opacity: .2;
}

.title-front {
    font-size: 45px;
}

@media only screen and (max-width: 768px) {
    .title-back {
        font-size: 45px;
    }

    .title-front {
        font-size: 35px;
    }
}

@media only screen and (max-width: 575px) {

    .main {
        margin-top: 20px;
        margin-bottom: 60px;
    }

    .title-back {
        font-size: 20px;
    }

    .title-front {
        font-size: 15px;
    }
}
</style>