<template>
    <div class="skel-main">
        <div v-for="index in 6" :key="index" class="item-box">
            <div class="img skeleton-animation"></div>
            <div class="name skeleton-animation"></div>
            <div class="type skeleton-animation"></div>
        </div>
    </div>
</template>

<script>
export default {

}
</script>

<style scoped>
.skel-main {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    gap: 20px;
    align-items: stretch;
}

.item-box{
    padding: 10px 10px 20px 10px;
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 10px;
}

.img{
    padding-bottom: 80%;
    margin-bottom: 10px;
}

.name{
    width: 90%;
    height: 25px;
    margin-bottom: 5px;
}

.type{
    width: 60%;
    height: 18px;
}

@media only screen and (max-width: 992px) {
    .skel-main {
        grid-template-columns: repeat(3, 1fr);
    }
}

@media only screen and (max-width: 575px) {
    .skel-main {
        grid-template-columns: repeat(2, 1fr);
    }
}
</style>