<template>
    <div>
        <div class="summery-wrapper">
            <p class="txt-summery">Summery</p>
            <div class="summery-container-top">
                <div class="summery-tbl-left">
                    <p>Sub Total</p>
                    <p>Discount</p>
                </div>
                <div class="line-vertical"></div>
                <div class="summery-tbl-right">
                    <p>{{ subTotal === 0 ? '-' : subTotal.toLocaleString() }}</p>
                    <p>{{ discount === 0 ? '-' : '- ' + discount.toLocaleString() }}</p>
                </div>
            </div>
            <div class="line-horizontal"></div>
            <div class="summery-total-wrapper">
                <p>Total</p>
                <p>{{ total === 0 ? '-' : total.toLocaleString() }}</p>
            </div>
            <div class="line-horizontal"></div>
            <div class="checkout-btn-wrapper">
                <BtnTxtMdRound :buttonText="'Proceed to Pay'" @custom-click="checkout()" />
            </div>
        </div>
    </div>
</template>

<script>
import BtnTxtMdRound from '../common/btn-txt-md-round-com.vue';

export default {

    props: {
        subTotal: {
            type: Number,
            default: 0
        },

        discount: {
            type: Number,
            default: 0
        },
        total: {
            type: Number,
            default: 0
        }
    },

    components: {
        BtnTxtMdRound,
    },

    methods: {
        checkout() {
            this.$emit('checkout');
        }
    },
}

</script>

<style scoped>
.summery-wrapper {
    width: 400px;
    padding: 20px 20px 40px 20px;
    border-radius: 20px;
    margin-left: 20px;
    flex-shrink: 0;
    align-self: flex-start;
    background-color: white;
}

.summery-wrapper p {
    margin-bottom: 5px;
}

.summery-wrapper .checkout-btn-wrapper {
    margin-top: 10px;
    display: flex;
    justify-content: center;
}

.txt-summery {
    font-size: 19px;
    font-weight: 900;
    text-align: center;
}

.summery-container-top {
    margin-top: 30px;
    display: flex;
    justify-content: space-between;
}

.summery-tbl-left,
.summery-tbl-right {
    width: 49%;
}

.summery-tbl-right {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.line-vertical {
    width: 1px;
    min-height: 100%;
    background-color: black;
}

.line-horizontal {
    width: 100%;
    height: 1px;
    background-color: black;
}

.summery-total-wrapper {
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
}

@media only screen and (max-width: 1000px) {
    .summery-wrapper {
        display: none;
    }
}

</style>