import axios from 'axios';
import { CONSTANTS } from '../utils/constants'

//user auth
export const register = async (data) => {
    const response = await axios.post(`${CONSTANTS.BASE_URL}/users/register`, data);
    return response;
};

export const login = async (data) => {
    const response = await axios.post(`${CONSTANTS.BASE_URL}/users/login`, data);
    return response;
};

//email verify
export const emailVerify = async (data) => {
    const response = await axios.post(`${CONSTANTS.BASE_URL}/users/email-verify`, data, {
        headers: {
            Authorization: `Bearer ${data.id}`
        }
    });
    return response;
};

//reviews
export const addReview = async (data) => {
    const response = await axios.post(`${CONSTANTS.BASE_URL}/reviews/add-review`, data, {
        headers: {
            Authorization: `Bearer ${data.userId}`
        }
    });
    return response;
};

export const getReviews = async () => {
    const response = await axios.get(`${CONSTANTS.BASE_URL}/reviews/get-reviews`);
    return response;
};

//items
export const getItems = async (category, brand, type, size, searchKeyword) => {
    const response = await axios.get(`${CONSTANTS.BASE_URL}/items/get-items/${category}/${brand}/${type}/${size}/${searchKeyword}`);
    return response;
};

export const getItemData = async (itemId) => {
    const response = await axios.get(`${CONSTANTS.BASE_URL}/items/get-item/${itemId}`);
    return response;
};

export const addFAQ = async (data) => {
    const response = await axios.post(`${CONSTANTS.BASE_URL}/items/add-to-faq`, data, {
        headers: {
            Authorization: `Bearer ${data.userId}`
        }
    });
    return response;
};

export const getFAQ = async (itemId) => {
    const response = await axios.get(`${CONSTANTS.BASE_URL}/items/get-faq/${itemId}`);
    return response;
};

export const getRecommandedItems = async () => {
    const response = await axios.get(`${CONSTANTS.BASE_URL}/items/get-recommanded-items`);
    return response;
}

export const getBannerSliderItems = async () => {
    const response = await axios.get(`${CONSTANTS.BASE_URL}/items/get-banner-slider-items`);
    return response;
}

export const getFeaturedItems = async (type) => {
    const response = await axios.get(`${CONSTANTS.BASE_URL}/items/get-featured-items/${type}`);
    return response;
};

export const getDrops = async () => {
    const response = await axios.get(`${CONSTANTS.BASE_URL}/items/get-drops`);
    return response;
};

//cart
export const addToCart = async (data) => {
    const response = await axios.post(`${CONSTANTS.BASE_URL}/cart/add-to-cart`, data, {
        headers: {
            Authorization: `Bearer ${data.userId}`
        }
    });
    return response;
};

export const viewCart = async (userId) => {
    const response = await axios.get(`${CONSTANTS.BASE_URL}/cart/get-cart`, {
        headers: {
            Authorization: `Bearer ${userId}`
        }
    });
    return response;
};

export const removeFromCart = async (userId, cartId) => {
    const response = await axios.delete(`${CONSTANTS.BASE_URL}/cart/delete-cart-item/${cartId}`, {
        headers: {
            Authorization: `Bearer ${userId}`
        }
    });
    return response;
};

//card payment
export const cardCheckout = async (data) => {
    const response = await axios.post(`${CONSTANTS.BASE_URL}/payment/create-checkout-session`, data, {
        headers: {
            Authorization: `Bearer ${data.userId}`
        }
    });
    return response;
};

//cod, bank deposit checkout
export const bankDepositCodCheckout = async (data, userId) => {
    const response = await axios.post(`${CONSTANTS.BASE_URL}/orders/make-order`, data, {
        headers: {
            Authorization: `Bearer ${userId}`
        }
    });
    return response;
};

//users
export const getUserData = async (userId) => {
    const response = await axios.get(`${CONSTANTS.BASE_URL}/users/get-userdata`, {
        headers: {
            Authorization: `Bearer ${userId}`
        }
    });
    return response;
};

export const updateUserData = async (data) => {
    const response = await axios.patch(`${CONSTANTS.BASE_URL}/users/add-user-data`, data);
    return response;
};

export const changePassword = async (data) => {
    const response = await axios.patch(`${CONSTANTS.BASE_URL}/users/change-password`, data);
    return response;
};

export const deleteAcoount = async (data) => {
    const response = await axios.post(`${CONSTANTS.BASE_URL}/users/delete-account`, data);
    return response;
}

//customize
export const sumbmitCustomizeForm = async (data) => {
    const response = await axios.post(`${CONSTANTS.BASE_URL}/customize/add-customize-order`, data, {
        headers: {
            Authorization: `Bearer ${data.userId}`
        }
    });
    return response;
};

export const getCustomizeItems = async (userId) => {
    const response = await axios.get(`${CONSTANTS.BASE_URL}/customize/get-customize-orders`, {
        headers: {
            Authorization: `Bearer ${userId}`
        }
    });
    return response;
};
//orders
//get user orders
export const getUserOrders = async (type, userId) => {
    const response = await axios.get(`${CONSTANTS.BASE_URL}/orders/get-user-orders/${type}`, {
        headers: {
            Authorization: `Bearer ${userId}`
        }
    });
    return response;
}

//complete order
export const makeOrderComplete = async (data) => {
    const response = await axios.post(`${CONSTANTS.BASE_URL}/orders/complete-order`, data, {
        headers: {
            Authorization: `Bearer ${data.userId}`
        }
    });
    return response;
}

//contact form sumbission
export const contactFormSubmit = async (data) => {
    const response = await axios.post(`${CONSTANTS.BASE_URL}/contact/submit-contact-form`, data);
    return response;
};

//newsletter
export const newsletterSubscribe = async (email) => {
    const response = await axios.get(`${CONSTANTS.BASE_URL}/newsletter/subscribe/${email}`);
    return response;
};