<template>
    <div class="skel-main">
        <div v-for="index in itemCount" :key="index" class="item">
            <div class="img skeleton-animation"></div>
            <div class="name skeleton-animation"></div>
            <div class="type skeleton-animation"></div>
            <div class="price skeleton-animation"></div>
            <div class="price skeleton-animation"></div>
            <div class="remaining skeleton-animation"></div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        itemCount: {
            type: Number,
            default: 6,
        }
    }
}
</script>

<style scoped>
.skel-main {
    margin-bottom: 50px;
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    gap: 20px;
    align-items: stretch;
}

.item {
    padding: 10px;
    background-color: white;
    border-radius: 5px;
}

.img {
    padding-bottom: 80%;
    margin-bottom: 20px;
}

.name {
    height: 20px;
    margin-bottom: 5px;
}

.type {
    width: 50px;
    height: 10px;
    margin-bottom: 5px;
}

.price {
    width: 100px;
    height: 25px;
    margin-bottom: 5px;
}

.remaining {
    width: 70px;
    height: 10px;
}

@media only screen and (max-width: 1200px) {
    .skel-main {
        grid-template-columns: repeat(5, 1fr);
    }
}

@media only screen and (max-width: 1000px) {
    .skel-main {
        grid-template-columns: repeat(4, 1fr);
    }
}

@media only screen and (max-width: 768px) {
    .skel-main {
        grid-template-columns: repeat(3, 1fr);
    }
}

@media only screen and (max-width: 575px) {
    .skel-main {
        grid-template-columns: repeat(2, 1fr);
    }
}
</style>