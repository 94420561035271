<template>
    <div class="item-preview-skel-main res-px">
        <div class="imgs-wrapper">
            <div class="main-img skeleton-animation"></div>
            <div class="sub-imgs-wrapper">
                <div class="sub-img skeleton-animation"></div>
                <div class="sub-img skeleton-animation"></div>
                <div class="sub-img skeleton-animation"></div>
                <div class="sub-img skeleton-animation"></div>
            </div>
        </div>
        <div class="content-wrapper">
            <div class="name skeleton-animation"></div>
            <div class="type skeleton-animation"></div>
            <div class="price skeleton-animation"></div>
            <div class="sizes-wrapper">
                <div class="size skeleton-animation"></div>
                <div class="size skeleton-animation"></div>
                <div class="size skeleton-animation"></div>
                <div class="size skeleton-animation"></div>
                <div class="size skeleton-animation"></div>
            </div>
            <div class="qty skeleton-animation"></div>
            <div class="btns-wrapper">
                <div class="cart skeleton-animation"></div>
                <div class="buy skeleton-animation"></div>
                <div class="customize skeleton-animation"></div>
            </div>
            <div class="customize-psd skeleton-animation"></div>
        </div>
    </div>
</template>
<script>
export default {

}
</script>
<style scoped>
.item-preview-skel-main {
    padding-top: 190px;
    display: flex;
}

.imgs-wrapper {
    width: 40%;
    margin-right: 50px;
    flex-shrink: 0;
}

.main-img {
    padding-bottom: 100%;
    margin-bottom: 30px;
}

.sub-imgs-wrapper {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 20px;
    align-items: stretch;
}

.sub-img {
    padding-bottom: 100%;
}

.content-wrapper {
    width: 100%;
}

.name {
    max-width: 600px;
    height: 60px;
    margin-bottom: 20px;
}

.type {
    max-width: 300px;
    height: 50px;
    margin-bottom: 50px;
}

.price {
    max-width: 250px;
    height: 60px;
}

.sizes-wrapper {
    max-width: 300px;
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 20px;
    align-items: stretch;
}

.size {
    width: 40px;
    height: 40px;
    margin: 50px 0;
}

.qty {
    max-width: 250px;
    height: 30px;
}

.btns-wrapper {
    max-width: 300px;
    margin-top: 50px;
    display: grid;
    grid-template-columns: 1fr 3fr 3fr;
    gap: 5px;
}

.cart,
.buy,
.customize,
.customize-psd {
    border-radius: 5px;
}

.cart {
    padding-bottom: 100%;
}

.customize-psd {
    max-width: 200px;
    margin-top: 5px;
    height: 40px;
}

@media only screen and (max-width: 768px) {
    .item-preview-skel-main {
        padding-top: 90px;
        flex-direction: column;
    }

    .imgs-wrapper {
        max-width: 400px;
        width: 100%;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 50px;
        display: flex;
        flex-direction: column;
    }

    .name {
        height: 30px;
    }

    .type {
        height: 20px;
    }

    .price {
        height: 30px;
    }
}
</style>