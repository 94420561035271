<template>
    <Loader :loading="isLoading" />
    <NotificationBox :isVisible="notify.isVisible" :type="notify.type" :title="notify.title" :message="notify.message"
            @notificationIsVisible="notifyIsVisible()" />
    <transition name="review">
        <div v-if="isVisible" @click="closeAddReview()" class="main">
            <div @click.stop class="review-box">
                <div class="close-wrapper">
                    <button @click="closeAddReview()">
                        <font-awesome-icon class="f-icon" :icon="['fa', 'close']" />
                    </button>
                </div>
                <div class="form">
                    <h3>Rate Us!</h3>
                    <div class="inp-box">
                        <p class="txt-label txt-sm">Rating</p>
                        <div @mouseleave="ratingsSetToDefault()" class="stars-wrapper">
                            <button v-for="(item, index) in ratings" :key="index" @mouseover="hoverRating(index)"
                                @click="selectRating(index)">
                                <font-awesome-icon :class="item.isSelected === true ? 'star-active' : 'star-dissable'"
                                    :icon="['fa', 'star']" />
                            </button>
                        </div>
                        <p v-if="errors.rating !== ''" class="txt-error txt-sm">{{ errors.rating }}</p>
                    </div>
                    <div class="inp-box">
                        <p class="txt-label txt-sm">Message</p>
                        <textarea v-model="message" :class="errors.message === '' ? 'textarea' : 'textarea-error'" name="" id="" rows="10"></textarea>
                        <p v-if="errors.message !== ''" class="txt-error txt-sm">{{ errors.message }}</p>
                    </div>
                    <div class="submit-btn-wrapper">
                        <BtnTxtMdRound :buttonText="'Leave a review'" @custom-click="submit()" />
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
import BtnTxtMdRound from '../../components/common/btn-txt-md-round-com.vue';
import { addReview } from '../../services/api';
import NotificationBox from '../../components/common/notification-box-com.vue';
import Loader from '../../components/common/loading-com.vue';
export default {

    data() {
        return {
            token: null,
            notify: { isVisible: false, type: '', title: '', message: '' },
            isLoading: false,
            ratings: [
                { rating: 1, isSelected: false },
                { rating: 2, isSelected: false },
                { rating: 3, isSelected: false },
                { rating: 4, isSelected: false },
                { rating: 5, isSelected: false },
            ],
            rating: null,
            message: '',
            errors: {
                rating: '',
                message: ''
            }
        }
    },

    props: {
        isVisible: {
            type: Boolean,
            default: true
        }
    },

    components: {
        BtnTxtMdRound,
        NotificationBox,
        Loader,
    },

    methods: {

        notifyIsVisible(value) {
            this.notify = { isVisible: value, type: '', title: '', message: '' };
        },

        setUserToken() {
            this.token = localStorage.getItem('token');
            if(this.token === null) {
                this.$router.push('/login');
            }
        },

        closeAddReview() {
            this.$emit('closeAddReviewPopup', false);
        },

        ratingsSetToDefault() {
            if (this.rating === null) {
                this.ratings.forEach((item) => {
                    item.isSelected = false;
                });
            }
        },

        hoverRating(index) {
            this.ratingsSetToDefault();

            this.ratings.slice(0, index + 1).forEach((item) => {
                item.isSelected = true;
            });
        },

        selectRating(index) {
            this.rating = null;
            this.ratingsSetToDefault();
            this.ratings.slice(0, index + 1).forEach((item) => {
                item.isSelected = true;
            });

            this.rating = this.ratings[index].rating;
        },

        validation() {

            if(this.rating === null) {
                this.errors.rating = 'Please select the rate!';
            } else {
                this.errors.rating = '';
            }

            if(this.message === '') {
                this.errors.message = 'Message is required!';
            } else {
                this.errors.message = '';
            }
        },

        async submit() {
            this.setUserToken();
            this.validation();

            if(this.errors.rating === '' && this.errors.message === '') {
                this.isLoading = true;
                const postData = {
                    userId: this.token,
                    rating: this.rating,
                    message: this.message
                };

                try {
                    const response = await addReview(postData);
                    this.notify = { isVisible: true, type: 'success', title: response.data.title, message: response.data.message };
                    this.isLoading = false;
                } catch (error) {
                    this.notify = { isVisible: true, type: 'error', title: error.response.data.title, message: error.response.data.message };
                    this.isLoading = false;
                }
                this.closeAddReview();
            }
        }
    }
}
</script>

<style scoped>

h3{
    text-align: center;
    margin-bottom: 30px;
}

.main {
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.262);
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    top: 0;
    z-index: 10;
    backdrop-filter: blur(5px);
}

.review-box {
    max-width: 500px;
    width: 100%;
    padding: 10px;
    background-color: white;
    border-radius: 10px;
}

.close-wrapper {
    display: flex;
    justify-content: flex-end;
}

.close-wrapper button,
.stars-wrapper button {
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: transparent;
}

.close-wrapper button .f-icon {
    width: 20px;
    height: 20px;
}

.inp-box {
    margin-bottom: 30px;
    display: flex;
    flex-direction: column;
}

.textarea {
    padding: 10px;
    border: 1px solid rgba(0, 0, 0, 0.26);
    border-radius: 10px;
}

.textarea-error{
    padding: 10px;
    border: 1px solid red;
    border-radius: 10px;
}

.stars-wrapper {
    width: 200px;
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
}

.star-dissable {
    width: 25px;
    height: 25px;
    color: rgba(0, 0, 0, 0.259);
}

.star-active {
    width: 25px;
    height: 25px;
    color: rgb(255, 196, 0);
}

.txt-error{
    color: red;
}

.submit-btn-wrapper{
    padding-bottom: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.review-enter-from,
.review-leave-to {
    opacity: 0;
}

.review-enter-active,
.review-leave-active {
    transition: all .3s ease;
}</style>