<template>
    <div>
        <div class="main">
            <h1 class="col-primary">Oops!<br><span>404 - PAGE NOT FOUND</span></h1><br>
            <p>The page / Item you are looking for might have been removed had its name changed or is temporaily unavailable</p>
            <button class="" @click="this.$router.push('/')">HOME</button>
        </div>
    </div>
</template>

<script>

export default {

}
</script>
<style scoped>
    .main{
        width: 100%;
        height: 100vh;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    button{
        width: 100px;
        height: 45px;
        margin-top: 30px;
        border: 4px solid var(--primary-col);
        border-radius: 10px;
        background-color: var(--primary-col);
        color: white;
        font-weight: 900;
        font-size: 18px;
        transition: all .3s ease;
    }

    button:hover{
        border: 4px solid var(--primary-col);
        color: var(--primary-col);
        background-color: transparent;
    }
    
    h1{
        font-size: 100px;
        text-align: center;
        line-height: 50px;
    }

    h1 span{
        font-size: 30px;
        color: black;
    }

    p{
        max-width: 500px;
        text-align: center;
    }
</style>