<template>
    <div @click="itemClick(id)" class="item hover-box-shadow">
        <div class="img-box">
            <img :src="img" alt="">
        </div>
        <p class="txt-title">{{ name }}</p>
        <p v-if="type !== 'all'" class="txt-type txt-sm">for {{ type }}</p>
        <p class="txt-price"><span class="txt-price-lkr">LKR. </span>{{ Math.round(price - (price * discount / 100)).toLocaleString() }}</p>
        <div class="discount-wrapper" v-if="discount != 0">
            <span class="txt-price-lkr-off">LKR.</span>
            <span class="txt-price-off">{{ price.toLocaleString() }}</span>
            <span class="txt-off-precentage">{{ discount }}% off</span>
        </div>
        <p v-if="remaining !== 'all'" class="txt-remaining txt-sm">{{ remaining }} remaining</p>
    </div>
</template>

<script>
export default {
    props: {
        id: {
            type: String,
            default: '',
        },
        name: {
            type: String,
            default: ''
        },

        type: {
            type: String,
            default: '',
        },

        price: {
            type: Number,
            default: 100
        },

        discount: {
            type: Number,
            default: 0,
        },

        remaining: {
            type: Number,
        },

        img: {
            type: String,
        }
    },

    methods: {
        itemClick(id) {
            this.$router.push(`/shop/item/${id}`);
        }
    },
}
</script>

<style scoped>
.item {
    padding: 10px;
    background-color: white;
    border-radius: 5px;
    cursor: pointer;
}

.img-box {
    width: 100%;
    margin-bottom: 20px;
    padding-bottom: 80%;
    background-color: rgba(0, 0, 0, 0.020);
    position: relative;
}

.img-box img {
    max-width: 100%;
    max-height: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.txt-title {
    margin-bottom: 5px;
    font-weight: bold;
}

.txt-price {
    font-weight: bold;
    font-size: 20px;
}

.txt-price-lkr {
    font-size: 10px;
}

.discount-wrapper {
    margin: 5px 0;
    display: flex;
    align-items: flex-end;
}

.txt-price-off,
.txt-price-lkr-off {
    font-size: 10px;
    font-weight: bold;
    text-decoration: line-through;
    color: rgb(183, 183, 183);
}

.txt-price-off {
    font-size: 20px;
    line-height: 20px;
}

.txt-off-precentage {
    margin-left: 5px;
    color: red;
}

.txt-remaining {
    color: rgb(183, 183, 183);
}

@media only screen and (max-width: 575px) {
    .txt-title {
        font-size: 15px;
    }

    .txt-price-lkr, .txt-price-lkr-off {
        font-size: 8px;
    }

    .txt-price, .txt-price-off{
        font-size: 17px;
    }

    .txt-off-precentage{
        font-size: 10px;
    }
}
</style>