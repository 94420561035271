import { createStore } from 'vuex';

const store = createStore({
  state() {
    return {
      successRegistration: false,
      checkoutItemsUserData: {},
    };
  },
  mutations: {
    setSuccessRegistration(state, payload) {
      state.successRegistration = payload;
    },

    setCheckoutItemsUserData(state, payload) {
      state.checkoutItemsUserData = payload;
    }
  },
});

export default store;