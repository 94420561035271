<template>
    <swiper :slidesPerView="slidePerView" :spaceBetween="30" :pagination="{ dynamicBullets: true, }" :modules="modules"
        :loop="true" :autoplay="{ delay: 5000, disableOnInteraction: false }" class="reviews-swiper">
        <swiper-slide v-for="(item, index) in reviews" :key="index">
            <div class="main">
                <div class="dp-box">
                    <font-awesome-icon v-if="item.img_path === null" class="f-icon-user" :icon="['fa', 'user']" />
                    <img v-if="item.img_path !== null" :src="item.img_path" alt="profile img">
                </div>
                <div class="content-box">
                    <p class="title">{{ item.first_name + ' ' + item.last_name }}</p>
                    <div class="rating-box">
                        <font-awesome-icon v-for="index in item.rating" :key="index" class="f-icon col-primary"
                            :icon="['fa', 'star']" />
                    </div>
                    <div class="divider">
                        <div class="hr-divider" :style="{ backgroundColor: getColor(index) }"></div>
                        <div class="quotation-round-box" :style="{ backgroundColor: getColor(index) }">
                            <img src="../../assets/images/icons/quotation-white.png" alt="">
                        </div>
                        <div class="hr-divider" :style="{ backgroundColor: getColor(index) }"></div>
                    </div>
                    <p class="txt-sm">{{ item.message }}</p>
                </div>
            </div>
        </swiper-slide>
    </swiper>
</template>
<script>
import { Swiper, SwiperSlide } from 'swiper/vue';
import 'swiper/css';
import 'swiper/css/pagination';
import { Pagination, Autoplay } from 'swiper/modules';

export default {

    data() {
        return {
            // reviews: [
            //     { name: 'Joe Biden', rating: 5, img: '', review: 'Lorem ipsum dolor, sit amet consectetur adipisicing elit. Quasi fuga a sunt quaerat alias cupiditate ' },
            //     { name: 'Joe Biden', rating: 5, img: '', review: 'Lorem ipsum dolor, sit amet consectetur adipisicing elit. Quasi fuga a sunt quaerat alias cupiditate voluptate veniam inventore. Ipsam in doloribus velit beatae perferendis unde quis magni inventore animi consectetur!' },
            //     { name: 'Joe Biden', rating: 5, img: '', review: 'Lorem ipsum dolor, sit amet consectetur adipisicing elit. Quasi fuga a sunt quaerat alias cupiditate voluptate veniam inventore. Ipsam in doloribus velit beatae perferendis unde quis magni inventore animi consectetur!' },
            //     { name: 'Joe Biden', rating: 5, img: '', review: 'Lorem ipsum dolor, sit amet consectetur adipisicing elit. Quasi fuga a sunt quaerat alias cupiditate voluptate veniam inventore. Ipsam in doloribus velit beatae perferendis unde quis magni inventore animi consectetur!' },
            //     { name: 'Joe Biden', rating: 5, img: '', review: 'Lorem ipsum dolor, sit amet consectetur adipisicing elit. Quasi fuga a sunt quaerat alias cupiditate voluptate veniam inventore. Ipsam in doloribus velit beatae perferendis unde quis magni inventore animi consectetur!' },
            //     { name: 'Joe Biden', rating: 5, img: '', review: 'Lorem ipsum dolor, sit amet consectetur adipisicing elit. Quasi fuga a sunt quaerat alias cupiditate voluptate veniam inventore. Ipsam in doloribus velit beatae perferendis unde quis magni inventore animi consectetur!' }
            // ],
            modules: [Pagination, Autoplay],
        }
    },

    props: {
        reviews: {
            type: Object,
            default: null,
        }
    },

    components: {
        Swiper,
        SwiperSlide,
    },

    computed: {
        slidePerView() {
            if (window.innerWidth >= 1024) {
                return 3;
            } else if (window.innerWidth >= 768) {
                return 2;
            } else {
                return 1;
            }
        }
    },

    mounted() {
        this.setCardHeight();
        window.addEventListener('resize', this.setCardHeight);
    },

    methods: {
        getColor(index) {
            const colors = ['#fdb400', '#009ad9', '#5eae15'];
            return colors[index % colors.length];
        },

        setCardHeight() {
            const cards = document.querySelectorAll(".reviews-swiper .swiper-slide .content-box");
            const maxCardHeight = Math.max(...Array.from(cards).map((card) => card.clientHeight));
            cards.forEach((card) => {
                card.style.height = maxCardHeight - 100 + "px";
            });
        },
    },

    beforeUnmount() {
        window.removeEventListener('resize', this.setCardHeight);
    },
};
</script>

<style scoped>
.swiper {
    width: 100%;
    height: 100%;
    margin-bottom: 30px;
}

.main {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
}

.dp-box {
    width: 90px;
    height: 90px;
    background-color: rgb(236, 236, 236);
    border: 2px solid white;
    border-radius: 50%;
    z-index: 1;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
}

.dp-box img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.f-icon-user{
    width: 80px;
    height: 80px;
    margin-top: 20px;
    color: rgba(0, 0, 0, 0.15);
}

.content-box {
    padding: 40px;
    padding-top: 60px;
    background-color: white;
    position: relative;
    top: -40px;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    border-bottom-left-radius: 40px;
    border-bottom-right-radius: 40px;
}

.title {
    font-weight: bold;
}

.rating-box {
    width: 120px;
    margin: 20px auto 0 auto;
    display: flex;
    justify-content: space-between;
}

.divider {
    margin: 20px 0;
    display: flex;
    align-items: center;
}

.hr-divider {
    width: 100%;
    height: 1px;
}

.quotation-round-box {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    flex-shrink: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.quotation-round-box img {
    max-width: 60%;
    max-height: 60%;
}
</style>
  