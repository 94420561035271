<template>
    <div>
        <Navigation :showTransition="false" />
        <Loader :loading="isLoading" />
        <NotificationBox :isVisible="notify.isVisible" :type="notify.type" :title="notify.title" :message="notify.message"
            @notificationIsVisible="notifyIsVisible()" />
        <div class="main">
            <div class="filter-wrapper-main">
                <div class="filter-wrapper container res-px">
                    <div class="brand-box">
                        <p class="filter-title">Brand</p>
                        <select v-model="brand" @change="getItemsData()" class="sel-sm" name="" id="">
                            <option value="all">All</option>
                            <option value="nike">NIKE</option>
                            <option value="adidas">Adidas</option>
                        </select>
                    </div>
                    <div class="size-box">
                        <p class="filter-title">Size</p>
                        <select v-model="size" @change="getItemsData()" class="sel-sm" name="" id="">
                            <option value="all">All</option>
                            <option value="32">32</option>
                            <option value="33">33</option>
                            <option value="34">34</option>
                            <option value="35">35</option>
                            <option value="36">36</option>
                            <option value="38">38</option>
                            <option value="40">40</option>
                            <option value="41">41</option>
                            <option value="42">42</option>
                            <option value="43">43</option>
                            <option value="44">44</option>
                            <option value="S">S</option>
                            <option value="M">M</option>
                            <option value="L">L</option>
                            <option value="XL">XL</option>
                            <option value="XXL">XXL</option>
                        </select>
                    </div>
                    <div class="type-box">
                        <p class="filter-title">Type</p>
                        <select v-model="type" @change="getItemsData()" class="sel-sm" name="" id="">
                            <option value="all">All</option>
                            <option value="mens">Mens</option>
                            <option value="womens">Womens</option>
                            <option value="kids">Kids</option>
                        </select>
                    </div>
                    <div class="search-box">
                        <p class="filter-title">Search</p>
                        <div class="search-box-wrapper">
                            <input v-model="searchKeyword" type="text" name="" id=""
                                placeholder="Type your keyword here....">
                            <button v-if="!isSearched" @click="search()">
                                <font-awesome-icon class="f-icon-search" :icon="['fa', 'search']" />
                            </button>
                            <button v-if="isSearched" @click="clearSearch()">
                                <font-awesome-icon class="f-icon-search" :icon="['fa', 'close']" />
                            </button>
                        </div>
                    </div>
                </div>
                <div class="filter-wrapper-navi-main">
                    <div class="filter-wrapper-navi container res-px">
                        <button class="btn-navi-trigger" @click="naviSliderIsVisible = true;">
                            <font-awesome-icon class="f-icon" :icon="['fa', 'sliders']" />
                        </button>
                        <div class="navi-search-box">
                            <input v-model="searchKeyword" class="inp-navi-search" type="text" name="" id=""
                                placeholder="Type your keyword here....">
                            <button v-if="!isSearched" @click="search()" class="btn-navi-search">
                                <font-awesome-icon class="f-icon-search" :icon="['fa', 'search']" />
                            </button>
                            <button v-if="isSearched" @click="clearSearch()" class="btn-navi-search">
                                <font-awesome-icon class="f-icon-search" :icon="['fa', 'close']" />
                            </button>
                        </div>
                    </div>
                    <transition name="navi-slider-trans">
                        <div v-if="naviSliderIsVisible" @click="naviSliderIsVisible = false;" class="filter-slide-navi-box">
                            <div @click.stop class="filter-slide-navi-items-wrapper">
                                <div class="slide-navi-close-btn-wrapper">
                                    <button @click="naviSliderIsVisible = false;" class="btn-close">
                                        <font-awesome-icon class="f-icon" :icon="['fa', 'close']" />
                                    </button>
                                </div>
                                <div class="filter-slide-navi-items">
                                    <div>
                                        <p class="filter-title">Category</p>
                                        <select v-model="category" class="sel-sm" name="" id="">
                                            <option value="all">All</option>
                                            <option value="shoes">Shoes</option>
                                            <option value="pre-designed">Pre-Designed</option>
                                            <option value="slippers">Slippers</option>
                                            <option value="Socks">Socks</option>
                                            <option value="t-shirts">T-Shirts</option>
                                            <option value="jeans">Jeans</option>
                                            <option value="shorts">Shorts</option>
                                            <option value="paintings">Paintings</option>
                                            <option value="others">Others</option>
                                        </select>
                                    </div><br><br>
                                    <div>
                                        <p class="filter-title">Brand</p>
                                        <select v-model="brand" class="sel-sm" name="" id="">
                                            <option value="all">All</option>
                                            <option value="nike">NIKE</option>
                                            <option value="adidas">Adidas</option>
                                        </select>
                                    </div><br><br>
                                    <div>
                                        <p class="filter-title">Size</p>
                                        <select v-model="size" class="sel-sm" name="" id="">
                                            <option value="all">All</option>
                                            <option value="5">5</option>
                                            <option value="6">6</option>
                                            <option value="7">7</option>
                                            <option value="8">8</option>
                                            <option value="9">9</option>
                                            <option value="10">10</option>
                                            <option value="11">11</option>
                                            <option value="12">12</option>
                                            <option value="13">13</option>
                                            <option value="14">14</option>
                                            <option value="15">15</option>
                                            <option value="16">16</option>
                                            <option value="17">17</option>
                                            <option value="18">18</option>
                                            <option value="19">19</option>
                                            <option value="20">20</option>
                                        </select>
                                    </div><br><br>
                                    <div>
                                        <p class="filter-title">Type</p>
                                        <select v-model="type" class="sel-sm" name="" id="">
                                            <option value="all">All</option>
                                            <option value="mens">Mens</option>
                                            <option value="womens">Womens</option>
                                            <option value="kids">Kids</option>
                                        </select>
                                    </div><br><br>
                                    <did class="filter-btn-box">
                                        <BtnTxtMdRound :buttonText="'FILTER'" @custom-click="filterClicked()" />
                                    </did>
                                </div>
                            </div>
                        </div>
                    </transition>
                </div>
                <div class="category-wrapper-main container res-px">
                    <div class="category-wrapper">
                        <button v-for="(item, index) in categories" :key="index" @click="categorySelect(index)"
                            :class="item.isSelected === true ? 'btn-category-selected' : 'btn-category'">{{ item.name
                            }}</button>
                    </div>
                </div>
            </div>
            <div class="bg-gradient">
                <div class="items-wrapper-main container res-px">
                    <ShopItemsLoadingSkeleton :itemCount="30" v-if="loading.isItemsLoading"/>
                    <div v-if="loading.isItemsLoading === false && this.items.length === 0" class="no-items-wrapper">
                        <img src="../assets/images/empty-null samples/shop-no-items.webp" alt="">
                    </div>
                    <div class="items-wrapper">
                        <ItemCard v-for="(item, index) in items" :key="index" :id="item.id" :name="item.name" :type="item.type"
                            :price="item.price" :discount="item.discount" :remaining="item.stock" :img="item.img_path_1" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
/* eslint-disable */
import { useHead } from '@vueuse/head';
import Navigation from '../components/common/navigation-com.vue';
import ItemCard from '../components/common/item-card-com.vue';
import BtnTxtMdRound from '../components/common/btn-txt-md-round-com.vue';
import NotificationBox from '../components/common/notification-box-com.vue';
import { getItems } from '../services/api';
import Loader from '../components/common/loading-com.vue';
import ShopItemsLoadingSkeleton from '../components/skeleton/shop-items-skel.vue';

export default {

    data() {
        return {
            loading: {
                isItemsLoading: false,
            },
            notify: { isVisible: false, type: '', title: '', message: '' },
            category: 'all',
            brand: 'all',
            type: 'all',
            size: 'all',
            searchKeyword: null,
            isSearched: false,
            categories: [
                { name: 'All', value: 'all', isSelected: true },
                { name: 'Shoes', value: 'shoes', isSelected: false },
                { name: 'Pre-Designed', value: 'pre-designed', isSelected: false },
                { name: 'Slippers', value: 'slippers', isSelected: false },
                { name: 'Socks', value: 'socks', isSelected: false },
                { name: 'T-Shirts', value: 't-shirts', isSelected: false },
                { name: 'Jeans', value: 'jeans', isSelected: false },
                { name: 'Shorts', value: 'shorts', isSelected: false },
                { name: 'Paintings', value: 'paintings', isSelected: false },
                { name: 'Others', value: 'others', isSelected: false },
            ],
            naviSliderIsVisible: false,
            items: [],
            isLoading: false,
        }
    },

    components: {
        Navigation,
        ItemCard,
        BtnTxtMdRound,
        NotificationBox,
        Loader,
        ShopItemsLoadingSkeleton,
    },

    mounted() {
        this.getItemsData();
        this.setMetaData();
    },

    watch: {
        searchKeyword(val) {
            if (val === '') {
                this.searchKeyword = null;
                this.getItemsData();
                this.isSearched = false;
            }
        },
    },

    methods: {
        setMetaData() {
            useHead({
                title: `SHOP | CUSTOM SNEAKERS`,
                meta: [
                    {
                        name: 'description',
                        content: ``
                    },
                ],
            });
        },

        notifyIsVisible(value) {
            this.notify = { isVisible: value, type: '', title: '', message: '' };
        },

        categorySelect(index) {
            this.categories = this.categories.map(item => ({ ...item, isSelected: false }));
            this.categories[index].isSelected = true;
            this.category = this.categories[index].value;
            this.getItemsData();
        },

        async getItemsData() {
            this.loading.isItemsLoading = true;
            this.items = [];
            try {
                let response = await getItems(this.category, this.brand, this.type, this.size, this.searchKeyword);
                this.items = response.data;
                this.loading.isItemsLoading = false;
            } catch (error) {
                this.loading.isItemsLoading = false;
                this.notify = { isVisible: true, type: 'error', title: error.response.data.title, message: error.response.data.message };
            }
        },

        filterClicked() {
            this.getItemsData();
            this.naviSliderIsVisible = false;
        },

        search() {
            if (this.searchKeyword !== null && this.searchKeyword !== '') {
                this.getItemsData();
                this.isSearched = true;
            } else {
                this.searchKeyword = null;
            }
        },

        clearSearch() {
            this.isSearched = false;
            this.searchKeyword = null;
            this.getItemsData();
        },
    }
}
</script>

<style scoped>
.filter-wrapper-main {
    background-color: rgb(223, 223, 223);
    position: sticky;
    top: 0;
    z-index: 1;
}

.filter-wrapper {
    padding-top: 20px;
    padding-bottom: 20px;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 30px;
}

.brand-box,
.size-box,
.type-box,
.search-box {
    display: flex;
    flex-direction: column;
}

.filter-title {
    margin-bottom: 10px;
    font-weight: bold;
}

.sel-sm {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    height: 35px;
    padding: 0 10px;
    border: 1px solid rgba(0, 0, 0, 0.148);
    border-radius: 20px;
}

.sel-sm:focus {
    border: 1px solid rgba(0, 0, 0, 0.251);
    outline: none;
}

.filter-type-wrapper {
    height: 35px;
    display: flex;
    align-items: center;
}

.filter-type-wrapper div {
    display: flex;
    align-items: center;
}

.filter-type-wrapper div:nth-child(2) {
    margin: 0 20px
}

.rad-filter-type {
    margin-right: 5px;
}

.filter-wrapper .search-box-wrapper {
    display: flex;
    align-items: flex-end;
}

.filter-wrapper .search-box input {
    width: 100%;
    height: 40px;
    padding-left: 20px;
    border: none;
    outline: none;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;

}

.filter-wrapper .search-box button {
    width: 45px;
    height: 40px;
    border: none;
    flex-shrink: 0;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--primary-col);
}

.filter-wrapper .search-box button .f-icon-search {
    width: 20px;
    height: 20px;
    color: white;
}

.category-wrapper {
    display: grid;
    grid-template-columns: repeat(10, 1fr);
}

.btn-category,
.btn-category-selected {
    width: 100%;
    padding: 5px;
    border: none;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.btn-category {
    background-color: transparent;
}

.btn-category-selected {
    background-color: rgba(255, 255, 255, 0.754)
}

.items-wrapper-main {
    min-height: 100vh;
    padding-top: 30px;
    padding-bottom: 30px;
}

.no-items-wrapper{
    height: 70vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.no-items-wrapper img{
    max-width: 100%;
}

.items-wrapper {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    gap: 20px;
    align-items: stretch;
}

.filter-wrapper-navi {
    display: none;
}

.filter-slide-navi-box {
    display: none;
}

@media only screen and (max-width: 1200px) {
    .filter-wrapper {
        display: none;
    }

    .filter-wrapper-navi {
        padding-top: 20px;
        padding-bottom: 20px;
        display: flex;
        justify-content: space-between;
        background-color: rgba(0, 0, 0, 0.050);
    }

    .filter-wrapper-navi .btn-navi-trigger {
        width: 30px;
        height: 30px;
        border: none;
        border-radius: 5px;
        flex-shrink: 0;
    }

    .navi-search-box {
        max-width: 400px;
        width: 100%;
        height: 30px;
        margin-left: 30px;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .inp-navi-search {
        width: 100%;
        height: 30px;
        padding-left: 10px;
        border: none;
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
    }

    .btn-navi-search {
        width: 30px;
        height: 30px;
        flex-shrink: 0;
        border: none;
        background-color: var(--primary-col);
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .btn-navi-search .f-icon-search {
        color: white;
    }

    .category-wrapper-main {
        display: none;
    }

    .items-wrapper {
        grid-template-columns: repeat(5, 1fr);
    }

    .filter-slide-navi-box {
        height: 100vh;
        display: block;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
    }

    .filter-slide-navi-items-wrapper {
        max-width: 500px;
        height: 100%;
        background-color: white;
    }

    .slide-navi-close-btn-wrapper{
        padding: 40px 20px;
        display: flex;
        justify-content: flex-end;
    }

    .slide-navi-close-btn-wrapper .btn-close {
        background-color: transparent;
        border: none;
    }

    .slide-navi-close-btn-wrapper .btn-close .f-icon {
        width: 20px;
        height: 20px;
    }

    .filter-slide-navi-items {
        padding: 40px 20px;
        display: flex;
        flex-direction: column;
    }

    .filter-slide-navi-items .filter-btn-box {
        display: flex;
        justify-content: center;
    }

    .filter-slide-navi-items div {
        display: flex;
        flex-direction: column;
    }
}

@media only screen and (max-width: 1000px) {
    .items-wrapper {
        grid-template-columns: repeat(4, 1fr);
    }
}

@media only screen and (max-width: 768px) {
    .items-wrapper {
        grid-template-columns: repeat(3, 1fr);
    }
}

@media only screen and (max-width: 575px) {
    .items-wrapper {
        grid-template-columns: repeat(2, 1fr);
        gap: 10px;
    }

    .filter-slide-navi-items-wrapper {
        width: 100%;
    }
}

.navi-slider-trans-enter-from,
.navi-slider-trans-leave-to {
    left: -100%;
}

.navi-slider-trans-enter-active,
.navi-slider-trans-leave-active {
    transition: all .3s ease;
}
</style>