<template>
    <div>
        <Loading v-model:active="isLoading" :is-full-page="fullPage" loader="bars" color="rgb(255, 166, 0)" />
    </div>
</template>

<script>
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/css/index.css';
export default {

    // data() {
    //     return{
    //         isLoading: false,
    //     }
    // },

    props: {
        loading: {
            type: Boolean,
            default: false,
        }
    },

    components: {
        Loading,
    },

    watch: {
        loading(value) {
            this.isLoading = value;
        }
    },
}
</script>

<style scoped></style>