<template>
    <div>
        <Loader :loading="isLoading" />
        <NotificationBox :isVisible="notify.isVisible" :type="notify.type" :title="notify.title" :message="notify.message"
            @notificationIsVisible="notifyIsVisible()" />
        <div class="main">
            <div class="top">
                <div class="top-wrapper container res-px">
                    <p>Get weekly updates, promos & offers by subscribing to our Newsletter!</p>
                    <div class="inp-btn-container">
                        <div>
                            <input v-model="email" :class="errors.email === '' ? 'inp-newsl-subs' : 'inp-newsl-subs-error'" type="text" name="" id="" placeholder="example@gmail.com">
                            <p class="txt-error txt-sm">{{ errors.email }}</p>
                        </div>
                        <button @click="subscribe()" class="hover-box-shadow">Subscribe</button>
                    </div>
                </div>
            </div>
            <div class="bottom">
                <div class="bottom-wrapper container res-px">
                    <div class="footer-link-wrapper">
                        <div class="section">
                            <p class="title">SITE MAP</p>
                            <div class="site-map-items">
                                <div>
                                    <router-link to="/">Home</router-link><br>
                                    <router-link to="/about">About</router-link><br>
                                    <router-link to="/customize">Customize</router-link><br>
                                    <router-link to="/help">Help</router-link>
                                </div>
                                <div>
                                    <router-link to="/blog">Blog</router-link><br>
                                    <router-link to="/contact">Contact</router-link><br>
                                    <router-link to="terms-&-conditions">T&C</router-link><br>
                                    <router-link to="privacy-policy">Privacy Policy</router-link>
                                </div>
                            </div>
                        </div>
                        <div class="section">
                            <p class="title">CONTACT US</p>
                            <a href="tel:+94778116668">077 811 6668</a><br><br>
                            <a href="mailto:info@customsneakers.lk">info@customsneakers.lk</a>
                        </div>
                        <div class="section">
                            <div class="social-logo-wrapper">
                                <div class="social-wrapper">
                                    <a href="https://www.facebook.com/customsneakers.lk" target="_blank">
                                        <img id="icon-fb" src="../../assets/images/icons/fb.png" alt="">
                                    </a>
                                    <a href="https://www.instagram.com/customsneakers.lk" target="_blank">
                                        <img src="../../assets/images/icons/insta.png" alt="">
                                    </a>
                                    <a href="https://www.linkedin.com/company/custiom-sneakers/about/" target="_blank">
                                        <img src="../../assets/images/icons/in.png" alt="">
                                    </a>
                                </div>
                                <router-link to="/">
                                    <img class="logo" src="../../assets/images/logo/logo.webp" alt="">
                                </router-link>
                            </div>
                        </div>
                    </div><br><br>
                    <div class="copyright-dev-wrapper">
                        <a class="txt-dev" href="http://octick.com" target="_blank" rel="noopener noreferrer">Designed &
                            Developed by
                            octick</a>
                        <p class="txt-copyright">&copy; copyright 2022 Custom Sneakers</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { newsletterSubscribe } from '../../services/api';
import NotificationBox from '../../components/common/notification-box-com.vue';
import Loader from '../../components/common/loading-com.vue';

export default {
    data() {
        return {
            notify: { isVisible: false, type: '', title: '', message: '' },
            isLoading: false,
            email: '',
            errors: {
                email: '',
            },
        }
    },

    components: {
        NotificationBox,
        Loader,
    },

    methods: {
        notifyIsVisible(value) {
            this.notify = { isVisible: value, type: '', title: '', message: '' };
        },

        async subscribe() {
            this.isLoading = true;
            if(this.email === '') {
                this.errors.email = 'Email is required!';
            } else {
                this.errors.email = '';
            }

            if(this.errors.email === '') {
                try {
                    const response = await newsletterSubscribe(this.email);
                    this.notify = { isVisible: true, type: 'success', title: response.data.title, message: response.data.message };
                    this.email = '';
                } catch (error) {
                    this.notify = { isVisible: true, type: 'error', title: error.response.data.title, message: error.response.data.message };
                }
            }
            this.isLoading = false;
        },
    },
}

</script>

<style scoped>

.txt-error{
    margin-top: 3px;
    margin-left: 20px;
    color: red;
}

a {
    color: white;
    font-size: 15px;
}

.top {
    background-image: url('../../assets/images/common/bg.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
}

.top-wrapper {
    padding-top: 20px;
    padding-bottom: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.inp-btn-container {
    width: 500px;
    margin-left: 100px;
    display: flex;
}

.inp-btn-container div{
    width: 100%;
}

.inp-newsl-subs:focus {
    outline: none;
    border: none;
}

.inp-newsl-subs{
    width: 100%;
    height: 40px;
    padding-left: 20px;
    border: 1px solid transparent;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
    background: linear-gradient(90deg, rgba(241, 242, 255, 1) 0%, rgba(255, 245, 245, 1) 100%);
}

.inp-newsl-subs-error{
    width: 100%;
    height: 40px;
    padding-left: 20px;
    border: 1px solid red;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
    background: linear-gradient(90deg, rgba(241, 242, 255, 1) 0%, rgba(255, 245, 245, 1) 100%);
}

.inp-btn-container button {
    width: 100px;
    height: 42px;
    border: none;
    flex-shrink: 0;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    background-color: var(--primary-col);
    color: white;
    font-weight: bold;
}

.bottom {
    background-color: black;
    color: white;
}

.bottom-wrapper {
    padding-top: 50px;
    padding-bottom: 30px;
}

.copyright-dev-wrapper {
    display: flex;
    justify-content: space-between;
}

.txt-copyright,
.txt-dev {
    font-size: 15px;
}

.footer-link-wrapper {
    display: flex;
    justify-content: space-between;
}

.title {
    margin-bottom: 30px;
    font-weight: bold;
    font-size: 20px;
    text-align: start;
}

.site-map-items {
    width: 220px;
    display: flex;
    justify-content: space-between;
}

.site-map-items div {
    display: flex;
    flex-direction: column;
}

.social-logo-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.social-wrapper {
    width: 120px;
    display: flex;
    justify-content: space-between;
}

.social-wrapper a {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
}

.social-wrapper a img {
    max-width: 100%;
    max-height: 100%;
}

#icon-fb {
    max-width: 80%;
    max-height: 80%;
}

.logo {
    width: 200px;
    margin-top: 30px;
}

@media only screen and (max-width: 768px) {

    .top-wrapper {
        flex-direction: column;
    }

    .inp-btn-container {
        width: 100%;
        margin-left: 0;
        margin-top: 20px;
    }

    .footer-link-wrapper {
        flex-direction: column;
    }

    .copyright-dev-wrapper {
        flex-direction: column;
        align-items: center;
    }

    .txt-dev {
        margin-bottom: 20px;
    }

    .section {
        margin-bottom: 50px;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .site-map-items div {
        align-items: center;
    }

    .social-logo-wrapper {
        align-items: center;
    }
}
</style>
