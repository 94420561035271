<template>
  <div class="main bg-gradient" id="main-container">
    <Navigation />
    <div class="container res-px">
      <h1 class="title-main">About Us!</h1>
      <div class="content-wrapper py">
        <div class="content">
          <h3 class="title-sub">Who are we?</h3>
          <p class="desc">We are Custom Sneakers we are based in Kandy, Sri Lanka. Our team
            is artistic and creative, passionate about footwear and fashion. We
            offer unique and personalized shoe customization services using high-
            quality materials and innovative techniques. Let us help you create a
            one-of-a-kind pair of shoes that reflect your individual style and
            personality.</p>
          <div class="content-bg-box">
            <img src="../assets/images/about/1-item.webp" alt="">
          </div>
        </div>
        <div class="box-img-right">
          <img class="img-bg" src="../assets/images/about/1-item.webp" alt="">
        </div>
      </div>
      <div class="content-wrapper py">
        <div class="box-img-left">
          <img class="img-bg" src="../assets/images/about/2-item.webp" alt="">
        </div>
        <div class="content">
          <h3 class="title-sub">What we do?</h3>
          <p>At Custom Sneakers, we help you create unique and personalized
             footwear that reflects your style and personality. Whether it's adding
             custom colors, materials, or designs, we make it easy for you to
             express yourself through your shoes</p>
          <div class="content-bg-box">
            <img src="../assets/images/about/2-item.webp" alt="">
          </div>
        </div>
      </div>
      <div class="content-wrapper py">
        <div class="content">
          <h3 class="title-sub">Our vision</h3>
          <p class="desc">Our vision is to empower individuals to express their unique style and
            creativity through custom footwear. We aim to become the go-to
            platform for shoe customization, providing a seamless and enjoyable
            experience for our customers to design and create their dream shoes.
            With our innovative technology and dedication to quality, we strive to
            revolutionize the shoe industry and inspire a new generation of
            fashion-forward individuals.</p>
          <div class="content-bg-box">
            <img src="../assets/images/about/3-item.webp" alt="">
          </div>
        </div>
        <div class="box-img-right">
          <img class="img-bg" src="../assets/images/about/3-item.webp" alt="">
        </div>
      </div>
      <div class="content-wrapper py">
        <div class="box-img-left">
          <img class="img-bg" src="../assets/images/about/4-item.webp" alt="">
        </div>
        <div class="content">
          <h3 class="title-sub">Our Mission</h3>
          <p>Our mission is to provide a platform for individuals to create and wear
             footwear that truly represents who they are. We are committed to
             offering a wide range of customization options, high-quality materials,
             and exceptional customer service to ensure that every customer can
             design their perfect pair of shoes. Through our dedication to
             innovation, sustainability, and inclusivity, we strive to make the world a
             more colorful and expressive place, one shoe at a time.</p>
          <div class="content-bg-box">
            <img src="../assets/images/about/4-item.webp" alt="">
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useHead } from '@vueuse/head';
import Navigation from '../components/common/navigation-com.vue';

export default {

  data() {
    return {

    }
  },

  components: {
    Navigation,
  },

  mounted() {
    this.setMetaData();
  },

  methods: {
    setMetaData() {
      useHead({
        title: `ABOUT | CUSTOM SNEAKERS`,
        meta: [
          {
            name: 'description',
            content: ``
          },
        ],
      });
    },
  }
}
</script>
<style scoped>
.main {
  padding-top: 170px;
}

.container {
  max-width: 1200px;
}

.content-wrapper {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.content {
  position: relative;
}

.content-bg-box {
  display: none;
}

.title-sub {
  margin-bottom: 20px;
}

.box-img-left,
.box-img-right {
  width: 300px;
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.box-img-right {
  margin-left: 20px;
}

.box-img-left {
  margin-right: 20px;
}

.img-bg {
  max-width: 100%;
}

.box-img-left .img-item {
   margin-left:-350px;
}

@media only screen and (max-width: 998px) {

  .box-img-right,
  .box-img-left {
    width: 200px;
  }
}

@media only screen and (max-width: 575px) {

  .box-img-left,
  .box-img-right {
    display: none;
  }

  .content-bg-box {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
    opacity: .3;
  }

  .content-bg-box img {
    max-width: 100%;
    max-height: 100%;
  }

  .title-sub,
  .desc {
    position: relative;
    z-index: 1;
  }
 .content-bg-box .img-item{
   width:50%;
   height:100%;
   margin-left:-200px;
   margin-top:40px
 }

 #item2{
  margin-left:-150px
 }
 
}
</style>