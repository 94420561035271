//set user token
export const setToken = () => {
    if(localStorage.getItem('token') === '' || localStorage.getItem('token') === null) {
        return null;
    } else {
        const token = localStorage.getItem('token');
        return token;
    }
}

//intersection observer
export function createObserver(target, handleIntersectionCallback) {
    const options = {
      root: null,
      rootMargin: '0px',
      threshold: 0,
    };
  
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          if (!entry.target.hasIntersected) {
            entry.target.hasIntersected = true;
            handleIntersectionCallback();
          }
        }
      });
    }, options);
  
    observer.observe(target);
  }