<template>
    <div>
        <Loader :loading="isLoading" />
        <NotificationBox :isVisible="notify.isVisible" :type="notify.type" :title="notify.title" :message="notify.message"
            @notificationIsVisible="notifyIsVisible()" />
        <div @click="close()" class="bg">
            <div @click.stop class="main res-px">
                <div class="title-close-wrapper">
                    <button @click="close()" class="btn-close">
                        <font-awesome-icon class="f-icon" :icon="['fa', 'close']" />
                    </button>
                    <!-- <p class="txt-checkout">Checkout</p> -->
                </div>
                <div class="form-container">
                    <div class="form-inps-box">
                        <div class="inputs-wrapper">
                            <div class="inp-box">
                                <p class="txt-label">First Name</p>
                                <input v-model="firstName" :class="errors.firstName === '' ? 'inp-text' : 'inp-text-error'"
                                    type="text" name="" id="" placeholder="Peter">
                                <p v-if="errors.firstName" class="txt-error txt-sm">{{ errors.firstName }}</p>
                            </div>
                            <div class="spaced-box"></div>
                            <div class="inp-box">
                                <p class="txt-label">Last Name</p>
                                <input v-model="lastName" :class="errors.lastName === '' ? 'inp-text' : 'inp-text-error'"
                                    type="text" name="" id="" placeholder="Last Name">
                                <p v-if="errors.lastName" class="txt-error txt-sm">{{ errors.lastName }}</p>
                            </div>
                        </div>
                        <div class="inputs-wrapper">
                            <div class="inp-box">
                                <p class="txt-label">Phone 1</p>
                                <input v-model="phone1" :class="errors.phone1 === '' ? 'inp-text' : 'inp-text-error'"
                                    type="text" name="" id="" placeholder="07- --- ----">
                                <p v-if="errors.phone1" class="txt-error txt-sm">{{ errors.phone1 }}</p>
                            </div>
                            <div class="spaced-box"></div>
                            <div class="inp-box">
                                <p class="txt-label">Phone 2</p>
                                <input v-model="phone2" :class="errors.phone2 === '' ? 'inp-text' : 'inp-text-error'"
                                    type="text" name="" id="" placeholder="07- --- ----">
                                <p v-if="errors.phone2" class="txt-error txt-sm">{{ errors.phone2 }}</p>
                            </div>
                        </div>
                        <div class="inputs-wrapper">
                            <div class="inp-box">
                                <p class="txt-label">Address Line 1</p>
                                <input v-model="addressLine1"
                                    :class="errors.addressLine1 === '' ? 'inp-text' : 'inp-text-error'" type="text" name=""
                                    id="" placeholder="No: 13/A, Somewhere">
                                <p v-if="errors.addressLine1" class="txt-error txt-sm">{{ errors.addressLine1 }}</p>
                            </div>
                            <div class="spaced-box"></div>
                            <div class="inp-box">
                                <p class="txt-label">Address Line 2</p>
                                <input v-model="addressLine2"
                                    :class="errors.addressLine2 === '' ? 'inp-text' : 'inp-text-error'" type="text" name=""
                                    id="" placeholder="Somewhere">
                                <p v-if="errors.addressLine2" class="txt-error txt-sm">{{ errors.addressLine2 }}</p>
                            </div>
                        </div>
                        <div class="inputs-wrapper">
                            <div class="inp-box">
                                <p class="txt-label">Province</p>
                                <input v-model="province" :class="errors.province === '' ? 'inp-text' : 'inp-text-error'"
                                    type="text" name="" id="" placeholder="Somewhere">
                                <p v-if="errors.province" class="txt-error txt-sm">{{ errors.province }}</p>
                            </div>
                            <div class="spaced-box"></div>
                            <div class="inp-box">
                                <p class="txt-label">District</p>
                                <input v-model="district" :class="errors.district === '' ? 'inp-text' : 'inp-text-error'"
                                    type="text" name="" id="" placeholder="Somewhere">
                                <p v-if="errors.district" class="txt-error txt-sm">{{ errors.district }}</p>
                            </div>
                        </div>
                        <div class="inputs-wrapper">
                            <div class="inp-box">
                                <p class="txt-label">City</p>
                                <input v-model="city" :class="errors.city === '' ? 'inp-text' : 'inp-text-error'" name=""
                                    id="" placeholder="Kandy">
                                <p v-if="errors.city" class="txt-error txt-sm">{{ errors.city }}</p>
                            </div>
                            <div class="spaced-box"></div>
                            <div class="inp-box">
                                <p class="txt-label">Postal Code</p>
                                <input v-model="postalCode"
                                    :class="errors.postalCode === '' ? 'inp-text' : 'inp-text-error'" name="" id=""
                                    placeholder="20000">
                                <p v-if="errors.postalCode" class="txt-error txt-sm">{{ errors.postalCode }}</p>
                            </div>
                        </div>
                        <p class="txt-payment-methods">Select a Payment Method</p>
                        <div class="payment-methods-wrapper">
                            <!-- <div @click="selectPaymentMethod('card')" class="box-payment-method">
                                <img :class="paymentType === 'card' ? 'img-payment-method-selected' : 'img-payment-method'"
                                    src="../../assets/images/checkout/online-pay.png" alt="">
                            </div> -->
                            <div @click="selectPaymentMethod('bank deposit')" class="box-payment-method">
                                <img :class="paymentType === 'bank deposit' ? 'img-payment-method-selected' : 'img-payment-method'"
                                    src="../../assets/images/checkout/bank-deposit.png" alt="">
                            </div>
                            <div @click="selectPaymentMethod('cod')" class="box-payment-method">
                                <img :class="paymentType === 'cod' ? 'img-payment-method-selected' : 'img-payment-method'"
                                    src="../../assets/images/checkout/COD.png" alt="">
                            </div>
                        </div>
                        <p v-if="errors.paymentType" class="txt-error txt-sm">{{ errors.paymentType }}</p>
                        <div v-if="paymentType === 'bank deposit'" class="bank-slip-upload-wrapper">
                            <p class="txt-label">Upload a image of your bank slip (.png, jpg, jpeg)</p>
                            <div class="bank-slip-upload-container">
                                <label for="upload-bank-slip"
                                    :class="errors.imgSlip !== '' ? 'lbl-bank-slip-error' : 'lbl-bank-slip'">
                                    <img :src="imgSlipURL" alt="">
                                    <font-awesome-icon v-if="imgSlipURL === null || ''" class="f-icon"
                                        :icon="['fa', 'plus']" />
                                    <input class="inp-bank-slip" type="file" name="" id="upload-bank-slip"
                                        accept=".jpg, .jpeg, .png" @change="slipUpload()" ref="slip">
                                </label>
                            </div>
                            <p v-if="errors.imgSlip" class="txt-error txt-sm">{{ errors.imgSlip }}</p>
                        </div>
                        <div class="sm-vertical-sized-box"></div>
                    </div>
                    <div class="summery-box">
                        <SummeryTable :subTotal="subTotal" :discount="discount" :total="total" @checkout="proceedToPay()" />
                    </div>
                </div>
            </div>
            <div @click.stop class="sm-proceed-to-pay-box res-px">
                <p class="txt-title">Title</p>
                <p class="txt-total"><span class="txt-sm">LKR</span> <br>{{ total === 0 ? '-' : total.toLocaleString() }}
                </p>
                <button @click="proceedToPay()" class="btn-checkout">Proceed</button>
            </div>
        </div>
    </div>
</template>

<script>
/* eslint-disable */
import SummeryTable from '../checkout/summery-table-com.vue';
import { cardCheckout, bankDepositCodCheckout } from '../../services/api';
import NotificationBox from '../../components/common/notification-box-com.vue';
import Loader from '../../components/common/loading-com.vue';
import { setToken } from '../../utils/helper';

export default {
    data() {
        return {
            token: null,
            notify: { isVisible: false, type: '', title: '', message: '' },
            isLoading: false,
            firstName: '',
            lastName: '',
            phone1: '',
            phone2: '',
            addressLine1: '',
            addressLine2: '',
            province: '',
            district: '',
            city: '',
            postalCode: '',
            paymentType: '',
            imgSlipURL: null,
            imgSlip: null,
            errors: {
                firstName: '',
                lastName: '',
                phone1: '',
                phone2: '',
                addressLine1: '',
                addressLine2: '',
                province: '',
                district: '',
                city: '',
                postalCode: '',
                paymentType: '',
                imgSlip: '',
            },
        }
    },

    props: {

        itemsData: {
            type: Object,
            default: null
        },

        subTotal: {
            type: Number,
            default: 0,
        },

        discount: {
            type: Number,
            default: 0,
        },

        total: {
            type: Number,
            default: 0
        }
    },

    components: {
        SummeryTable,
        NotificationBox,
        Loader,
    },

    mounted() {
    this.token = setToken();
  },

    methods: {

        notifyIsVisible(value) {
            this.notify = { isVisible: value, type: '', title: '', message: '' };
        },

        selectPaymentMethod(type) {
            this.imgSlip = null;
            this.imgSlipURL = null;
            this.paymentType = type;
        },

        close() {
            this.$emit('closeCheckoutPopup', false);
        },

        validation() {
            this.errors.imgSlip = '';

            if (this.firstName === '' || this.firstName === null) {
                this.errors.firstName = 'First name is required!';
            } else {
                this.errors.firstName = '';
            }

            if (this.lastName === '' || this.lastName === null) {
                this.errors.lastName = 'Last name is required!';
            } else {
                this.errors.lastName = '';
            }

            if (this.phone1 === '' || this.phone1 === null) {
                this.errors.phone1 = 'Mobile number is required!';
            } else if (this.phone1.length > 10 || this.phone1.length < 9) {
                this.errors.phone1 = 'Invalid mobile number!';
            } else if (isNaN(this.phone1)) {
                this.errors.phone1 = 'Invalid mobile number!';
            } else {
                this.errors.phone1 = '';
            }

            if (this.phone2 === '' || this.phone2 === null) {
                this.errors.phone2 = 'Mobile number is required!';
            } else if (this.phone2.length > 10 || this.phone2.length < 9) {
                this.errors.phone2 = 'Invalid mobile number!';
            } else if (isNaN(this.phone2)) {
                this.errors.phone2 = 'Invalid mobile number!';
            } else {
                this.errors.phone2 = '';
            }

            if (this.addressLine1 === '' || this.addressLine1 === null) {
                this.errors.addressLine1 = 'Address line 1 is required!';
            } else {
                this.errors.addressLine1 = '';
            }

            if (this.addressLine2 === '' || this.addressLine2 === null) {
                this.errors.addressLine2 = 'Address line 2 is required!';
            } else {
                this.errors.addressLine2 = '';
            }

            if (this.province === '' || this.province === null) {
                this.errors.province = 'Province is required!';
            } else {
                this.errors.province = '';
            }

            if (this.district === '' || this.district === null) {
                this.errors.district = 'District is required!';
            } else {
                this.errors.district = '';
            }

            if (this.city === '' || this.city === null) {
                this.errors.city = 'City is required!';
            } else {
                this.errors.city = '';
            }

            if (this.postalCode === '' || this.postalCode === null) {
                this.errors.postalCode = 'Postal code is required!';
            } else if (isNaN(this.postalCode)) {
                this.errors.postalCode = 'Invalid postal code!';
            } else {
                this.errors.postalCode = '';
            }

            if (this.paymentType === '' || this.paymentType === null) {
                this.errors.paymentType = 'Payment type is required!';
            } else {
                this.errors.paymentType = '';
            }

            if (this.paymentType === 'bank deposit') {
                if (this.imgSlip === '' || this.imgSlip === null) {
                    this.errors.imgSlip = 'Slip image is required!';
                } else {
                    this.errors.imgSlip = '';
                }
            }
        },

        slipUpload() {
            this.imgSlip = this.$refs.slip.files[0];
            this.imgSlipURL = URL.createObjectURL(this.imgSlip);
        },

        async proceedToPay() {
            this.isLoading = true;
            this.validation();
            if(this.token) {
                if (this.errors.firstName === '' && this.errors.lastName === '' && this.errors.phone1 === '' && this.errors.phone2 === '' && this.errors.addressLine1 === '' && this.errors.addressLine2 === '' && this.errors.province === '' && this.errors.district === '' && this.errors.city === '' && this.errors.postalCode === '' && this.errors.paymentType === '' && this.errors.imgSlip === '') {
                let itemsData = JSON.stringify(this.itemsData);
                
                const postData = new FormData();
                postData.append('itemsData', itemsData);
                postData.append('firstName', this.firstName);
                postData.append('lastName', this.lastName);
                postData.append('phone1', this.phone1);
                postData.append('phone2', this.phone2);
                postData.append('addressLine1', this.addressLine1);
                postData.append('addressLine2', this.addressLine2);
                postData.append('province', this.province);
                postData.append('district', this.district);
                postData.append('city', this.city);
                postData.append('postalCode', this.postalCode);
                postData.append('paymentType', this.paymentType);
                postData.append('image', this.imgSlip);

                const postDataCardCheckout = {
                    userId: this.token,
                    itemsData: itemsData,
                    firstName: this.firstName,
                    lastName: this.lastName,
                    phone1: this.phone1,
                    phone2: this.phone2,
                    addressLine1: this.addressLine1,
                    addressLine2: this.addressLine2,
                    province: this.province,
                    district: this.district,
                    city: this.city,
                    postalCode: this.postalCode,
                    paymentType: this.paymentType,
                }

                if (this.paymentType !== 'card') {
                    try {
                        await bankDepositCodCheckout(postData, this.token);
                        this.$router.push('/overview')
                    } catch (error) {
                        this.notify = { isVisible: true, type: 'error', title: error.response.data.title, message: error.response.data.message };
                    }
                } else {
                    try {
                        
                        const response = await cardCheckout(postDataCardCheckout);
                        window.location.href = response.data.url;
                    } catch (error) {
                        this.notify = { isVisible: true, type: 'error', title: error.response.data.title, message: error.response.data.message };
                    }
                }
            }
            }
            this.isLoading = false;
        },
    },
}
</script>
<style scoped>
.inp-text,
.inp-select {
    padding-left: 10px;
    height: 30px;
    border: 1px solid rgb(162, 162, 162);
    border-radius: 5px;
}

.inp-text-error,
.inp-select-error {
    padding-left: 10px;
    height: 30px;
    border: 1px solid red;
    border-radius: 5px;
}

.txt-error {
    color: red;
}

.bg {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.255);
    z-index: 10;
}

.main {
    max-width: 1000px;
    width: 100%;
    height: 80vh;
    padding-top: 20px;
    padding-bottom: 20px;
    border-radius: 20px;
    background-color: white;
}

.title-close-wrapper {
    width: 100%;
    height: 10%;
    position: relative;
}

.btn-close {
    border: none;
    background-color: transparent;
}

.btn-close .f-icon {
    width: 20px;
    height: 20px;
    position: absolute;
    right: 0;
}

.txt-checkout {
    margin-top: 10px;
    font-size: 20px;
    font-weight: bold;
    text-align: center;
}

.form-container {
    width: 100%;
    height: 90%;
    display: flex;
    justify-content: space-between;
    overflow-y: auto;
}

.form-inps-box {
    width: 100%;
}

.inputs-wrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.inp-box {
    width: 100%;
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
}

.txt-label {
    margin-bottom: 5px;
}

.spaced-box {
    width: 20px;
}

.txt-payment-methods {
    margin-top: 20px;
    margin-bottom: 20px;
}

.payment-methods-wrapper {
    width: 100%;
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
}

.bank-slip-upload-wrapper {
    width: 100%;
    margin-top: 30px;
}

.bank-slip-upload-container .f-icon {
    width: 55px;
    height: 55px;
    color: rgba(0, 0, 0, 0.205);
}

.lbl-bank-slip {
    width: 100px;
    height: 100px;
    margin-top: 10px;
    cursor: pointer;
    border: 1px solid rgba(0, 0, 0, 0.201);
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}

.lbl-bank-slip-error {
    width: 100px;
    height: 100px;
    margin-top: 10px;
    margin-bottom: 10px;
    cursor: pointer;
    border: 1px solid red;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}

.lbl-bank-slip img,
.lbl-bank-slip-error img {
    max-width: 100%;
    max-height: 100%;
}

.inp-bank-slip {
    width: 0;
    opacity: 0;
    position: absolute;
    z-index: -1;
}

.box-payment-method {
    width: 33%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.img-payment-method {
    max-width: 90%;
    filter: grayscale(100%);
    transition: .3s;
}

.img-payment-method-selected {
    max-width: 90%;
    filter: grayscale(0%);
    transition: .3s;
}

.img-payment-method:hover {
    filter: grayscale(50%);
}

.sm-proceed-to-pay-box {
    display: none;
}

@media only screen and (max-width: 1000px) {
    .main {
        height: 100vh;
        padding-top: 50px;
        border-radius: 0%;
    }

    .sm-proceed-to-pay-box {
        height: 80px;
        background: linear-gradient(90deg, rgba(241, 242, 255, 1) 0%, rgba(255, 245, 245, 1) 100%);
        border-top: 1px solid rgba(0, 0, 0, 0.179);
        border-top-right-radius: 10px;
        border-top-left-radius: 10px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
    }

    .sm-proceed-to-pay-box .txt-total,
    .sm-proceed-to-pay-box .txt-title {
        font-size: 20px;
        font-weight: bold;
    }

    .sm-proceed-to-pay-box .btn-checkout {
        padding: 15px 30px;
        border: 2px solid var(--primary-col);
        border-radius: 20px;
        background-color: var(--primary-col);
        color: white;
        transition: .5s;
    }

    .sm-proceed-to-pay-box .btn-checkout:hover {
        background-color: white;
        color: var(--primary-col);
    }
}

@media only screen and (max-width: 575px) {

    .title-close-wrapper {
        height: 40px;
    }

    .inputs-wrapper {
        flex-direction: column;
    }

    .sm-vertical-sized-box {
        height: 80px;
    }

    .sm-proceed-to-pay-box .txt-total,
    .sm-proceed-to-pay-box .txt-title {
        font-size: 17px;
    }

    .sm-proceed-to-pay-box .btn-checkout {
        padding: 10px;
    }
}
</style>