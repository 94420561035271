<template>
    <div>
        <Navigation />
        <Loader :loading="isLoading" />
        <div class="main bg-gradient res-px">
            <NotificationBox :isVisible="notify.isVisible" :type="notify.type" :title="notify.title"
                :message="notify.message" @notificationIsVisible="notifyIsVisible()" />
            <div class="form-box res-px py">
                <p class="txt-title">Register</p>
                <div class="inp-box-name">
                    <div>
                        <p class="txt-label">First Name</p>
                        <input v-model="firstName" :class="errors.firstName === '' ? 'inp' : 'inp-error'" type="text" name="" id="" placeholder="Peter">
                        <p v-if="errors.firstName" class="txt-error txt-sm">{{ errors.firstName }}</p>
                    </div>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <div>
                        <p class="txt-label">Last Name</p>
                        <input v-model="lastName" :class="errors.lastName === '' ? 'inp' : 'inp-error'" type="text" name="" id="" placeholder="Parker">
                        <p v-if="errors.lastName" class="txt-error txt-sm">{{ errors.lastName }}</p>
                    </div>
                    
                </div>
                <div class="inp-box">
                    <p class="txt-label">Email</p>
                    <input v-model="email" :class="errors.email === '' ? 'inp' : 'inp-error'" type="email" name="" id="" placeholder="example@gmail.com">
                    <p v-if="errors.email" class="txt-error txt-sm">{{ errors.email }}</p>
                </div>
                <div class="inp-box">
                    <p class="txt-label">Password</p>
                    <input v-model="password" :class="errors.password === '' ? 'inp' : 'inp-error'" :type="isShowPassword === true ? 'text' : 'password'" name="" id=""
                        placeholder="password length more than 6 characters">
                    <p v-if="errors.password" class="txt-error txt-sm">{{ errors.password }}</p>
                    <p class="txt-error txt-sm"></p>
                </div>
                <div class="inp-box">
                    <p class="txt-label">Confirm Password</p>
                    <input v-model="passwordConfirm" :class="errors.passwordConfirm === '' ? 'inp' : 'inp-error'" :type="isShowPassword === true ? 'text' : 'password'" name="" id=""
                        placeholder="confirm password">
                    <p v-if="errors.passwordConfirm" class="txt-error txt-sm">{{ errors.passwordConfirm }}</p>
                    <p class="txt-error txt-sm"></p>
                </div>
                <div class="show-pw-wrapper">
                    <input v-model="isShowPassword" type="checkbox" name="" id="">
                    <p>Show Password</p>
                </div>
                <BtnTxtMdRound :buttonText="'Register'" @custom-click="register()" />
                <div class="auth-opt-link-wrapper">
                    <p>Already have an account?</p>
                    <router-link to="/login" class="col-primary">Login</router-link>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
/* eslint-disable */
import { useHead } from '@vueuse/head';
import Navigation from '../components/common/navigation-com.vue';
import BtnTxtMdRound from '../components/common/btn-txt-md-round-com.vue';
import { register } from '../services/api';
import NotificationBox from '../components/common/notification-box-com.vue';
import Loader from '../components/common/loading-com.vue';

export default {
    data() {
        return {
            firstName: '',
            lastName: '',
            email: '',
            password: '',
            passwordConfirm: '',
            isShowPassword: false,
            errors: {
                firstName: '',
                lastName: '',
                email: '',
                password: '',
                passwordConfirm: '',
            },
            notify: { isVisible: false, type: '', title: '', message: '' },
            isLoading: false,
        }

    },


    components: {
        Navigation,
        BtnTxtMdRound,
        NotificationBox,
        Loader,
    },

    mounted() {
        this.setMetaData();
    },

    methods: {
        setMetaData() {
            useHead({
                title: `REGISTER | CUSTOM SNEAKERS`,
                meta: [
                    {
                        name: 'description',
                        content: ``
                    },
                ],
            });
        },

        validation() {
            if(this.firstName === '') {
                this.errors.firstName = 'First Name is Required!';
            } else {
                this.errors.firstName = '';
            }

            if(this.lastName === '') {
                this.errors.lastName = 'Last Name is Required!';
            } else {
                this.errors.lastName = '';
            }

            if (this.email === '') {
                this.errors.email = 'Email is Required!'
            } else if (!/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(this.email)) {
                this.errors.email = 'Invalid Email Format!';
            } else {
                this.errors.email = '';
            }

            if(this.password === '') {
                this.errors.password = 'Password is Required!';
            } else if(this.password.length < 6) {
                this.errors.password = 'Password Required more than 5 characters!';
            } else {
                this.errors.password = '';
            }

            if(this.passwordConfirm === '') {
                this.errors.passwordConfirm = 'Password Confirmation is Required!';
            } else if(this.passwordConfirm !== this.password) {
                this.errors.passwordConfirm = 'Password Do Not Match!';
            } else {
                this.errors.passwordConfirm = '';
            }
        },

        notifyIsVisible(value) {
            this.notify = { isVisible: value, type: '', title: '', message: '' };
        },

        async register() {
            this.validation();

            if (this.errors.firstName === '' && this.errors.lastName === '' && this.errors.email === '' && this.errors.password === '' && this.errors.passwordConfirm === '') {
                this.isLoading = true;
                try {
                    const postData = { firstName: this.firstName, lastName: this.lastName, email: this.email, password: this.password };
                    const response = await register(postData);
                    localStorage.setItem('token', response.data.token);
                    this.isLoading = false;
                    this.$store.commit('setSuccessRegistration', true);
                    this.$router.push('/');
                } catch (error) {
                    this.isLoading = false;
                    this.notify = { isVisible: true, type: 'error', title: error.response.data.title, message: error.response.data.message };
                }
            }
        },
    },
}
</script>

<style scoped>
.inp {
    padding-left: 10px;
    height: 35px;
    border: 1px solid rgb(203, 203, 203);
    border-radius: 5px;
}

.inp-error{
    padding-left: 10px;
    height: 35px;
    border: 1px solid red;
    border-radius: 5px;
}

.main {
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.form-box {
    max-width: 500px;
    width: 100%;
    margin-top: 100px;
    margin-bottom: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: white;
}

.txt-title {
    font-size: 20px;
    margin-bottom: 40px;
    font-weight: 900;
}

.inp-box-name{
    width: 100%;
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
}

.inp-box-name div{
    width: 100%;
    display: flex;
    flex-direction: column;
}

.inp-box {
    width: 100%;
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
}

.txt-error {
    color: red;
}

.show-pw-wrapper {
    width: 100%;
    margin-bottom: 30px;
    display: flex;
    align-items: center;
}

.show-pw-wrapper input {
    margin-right: 10px;
}

.auth-opt-link-wrapper {
    margin-top: 20px;
    display: flex;
    align-items: center;
}

.auth-opt-link-wrapper p {
    margin-right: 10px;
}</style>