<template>
    <div>
        <Navigation :is-navibar-transparent="true" />
        <MainBanner />
        <NotificationBox :isVisible="notify.isVisible" :type="notify.type" :title="notify.title" :message="notify.message"
            @notificationIsVisible="notifyIsVisible()" />
        <AddReviewPopup @closeAddReviewPopup="closeAddReviewPopup()" :isVisible="addReviewPopup" />
        <div class="intro-box-main py">
            <div class="container res-px">
                <div class="intro-box txt-lg">
                    <div class="box-vl-content">
                        <div class="vl"></div>
                        <p>At CustomSneakers.lk, our passion lies in creating pieces of
                            art and the dissemination of positivity along our journey. We
                            are dedicated to providing a platform that empowers
                            creative individuals to harness their talents and spearhead
                            a revolution in the custom products industry in Sri Lanka.
                            Committed to executing excellence, we eagerly anticipate
                            offering our customers unparalleled value for their
                            investments, driven by the remarkable talent of our fellow
                            Sri Lankans.
                        </p>
                    </div>
                    <div class="intro-img-box">
                        <img src="../assets/images/home/intro-img.webp" alt="">
                    </div>
                </div>
                <!-- <div class="intro-btn-wrapper">
                    <BtnTxtMdRound :buttonText="'Inquire Now'" @custom-click="this.$router.push('/contact')" />
                </div> -->
            </div>
        </div>
        <div class="bg-gradient">
            <div ref="recommandedItems" class="container res-px py">
                <TitleDouble>
                    <template v-slot:title-back>
                        <h2 style="font-family: MontserratExtraBold;">FAVORITES</h2>
                    </template>
                    <template v-slot:title-front>
                        <h2>Favorites</h2>
                    </template>
                </TitleDouble>
                <SamlpesSkeleton v-if="loading.isRecommandedItemsLoading" />
                <Samples v-if="!loading.isRecommandedItemsLoading" :items="recommandedItems" :btnVisible="true"
                    :categoryLink="'/shop'" />
            </div>
            <div class="customize-banner-wrapper">
                <CustomizeBanner />
            </div>
            <div class="step-swiper-wrapper">
                <StepsSwiper />
            </div>
            <!-- <CustomizeBanner /> -->
            <!-- <div class="container res-px py">
                <TitleDouble>
                    <template v-slot:title-back>
                        <h2>FOR MEN</h2>
                    </template>
                    <template v-slot:title-front>
                        <h2>Mens</h2>
                    </template>
                </TitleDouble>
                <Samples :items="forMens" :btnVisible="true" :categoryLink="'/'" />
            </div>
            <div class="container res-px py">
                <TitleDouble>
                    <template v-slot:title-back>
                        <h2>FOR WOMENS</h2>
                    </template>
                    <template v-slot:title-front>
                        <h2>Womens</h2>
                    </template>
                </TitleDouble>
                <Samples :items="forWomens" :btnVisible="true" :categoryLink="'/'" />
            </div>
            <div class="container res-px py">
                <TitleDouble>
                    <template v-slot:title-back>
                        <h2>FOR KIDS</h2>
                    </template>
                    <template v-slot:title-front>
                        <h2>Kids</h2>
                    </template>
                </TitleDouble>
                <Samples :items="forKids" :btnVisible="true" :categoryLink="'/'" />
            </div>
            <CustomizeBanner />
            <div class="container res-px py">
                <StepsSwiper />
            </div>
            <div class="container res-px py">
                <TitleDouble>
                    <template v-slot:title-back>
                        <h2>PRE-DESIGNED</h2>
                    </template>
                    <template v-slot:title-front>
                        <h2>Pre-Designed</h2>
                    </template>
                </TitleDouble>
                <Samples :items="preDesigned" :btnVisible="false" :categoryLink="'/'" />
            </div> -->
            <div ref="reviews" class="reviews-container container res-px py">
                <p class="txt-testimonials">T E S T I M O N I A L S</p>
                <h2>WHAT OUR CUSTOMERS LOVE ABOUT US</h2>
                <div class="hr-line col-bg-primary"></div>
                <ReviewsSkeleton v-if="loading.isReviewsLoading" />
                <Reviews v-if="!loading.isReviewsLoading" :reviews="reviews" />
                <BtnTxtMdRound :buttonText="'Leave a review'" @custom-click="addReviewPopup = true" />
            </div>
        </div>
    </div>
</template>

<script>
/* eslint-disable */
import { useHead } from '@vueuse/head';
import Navigation from '../components/common/navigation-com.vue';
import NotificationBox from '../components/common/notification-box-com.vue';
import MainBanner from '../components/home/banner-com.vue';
import BtnTxtMdRound from '../components/common/btn-txt-md-round-com.vue';
import TitleDouble from '../components/common/title-double-com.vue';
import Samples from '../components/common/samples-com.vue';
import CustomizeBanner from '../components/common/customize-design-banner-com.vue'
import StepsSwiper from '../components/home/steps-swiper-com.vue';
import Reviews from '../components/home/reviews-com.vue';
import AddReviewPopup from '../components/home/add-review-com.vue';
import SamlpesSkeleton from '../components/skeleton/samples-skel.vue';
import ReviewsSkeleton from '../components/skeleton/reviews-skel.vue';
import { getRecommandedItems, getReviews } from '../services/api';
import { createObserver } from '../utils/helper';

export default {

    data() {
        return {
            loading: {
                isRecommandedItemsLoading: true,
                isReviewsLoading: true,
            },
            notify: { isVisible: false, type: '', title: '', message: '' },
            recommandedItems: [],
            reviews: [],
            forMens: [
                { title: 'Nike AF 1LOW', model: 'Cyberpunk', tag: 'New', img: 'shoe-sample.png' },
                { title: 'Nike AF 1LOW', model: 'Cyberpunk', tag: 'Best selling', img: 'shoe-sample.png' },
                { title: 'Nike AF 1LOW', model: 'Cyberpunk', tag: '', img: 'shoe-sample.png' },
                { title: 'Nike AF 1LOW', model: 'Cyberpunk', tag: '', img: 'shoe-sample.png' },
                { title: 'Nike AF 1LOW', model: 'Cyberpunk', tag: '', img: 'shoe-sample.png' },
                { title: 'Nike AF 1LOW', model: 'Cyberpunk', tag: '', img: 'shoe-sample.png' },
            ],
            forWomens: [
                { title: 'Nike AF 1LOW', model: 'Cyberpunk', tag: 'New', img: 'shoe-sample.png' },
                { title: 'Nike AF 1LOW', model: 'Cyberpunk', tag: 'Best selling', img: 'shoe-sample.png' },
                { title: 'Nike AF 1LOW', model: 'Cyberpunk', tag: '', img: 'shoe-sample.png' },
                { title: 'Nike AF 1LOW', model: 'Cyberpunk', tag: '', img: 'shoe-sample.png' },
                { title: 'Nike AF 1LOW', model: 'Cyberpunk', tag: '', img: 'shoe-sample.png' },
                { title: 'Nike AF 1LOW', model: 'Cyberpunk', tag: '', img: 'shoe-sample.png' },
            ],
            forKids: [
                { title: 'Nike AF 1LOW', model: 'Cyberpunk', tag: 'New', img: 'shoe-sample.png' },
                { title: 'Nike AF 1LOW', model: 'Cyberpunk', tag: 'Best selling', img: 'shoe-sample.png' },
                { title: 'Nike AF 1LOW', model: 'Cyberpunk', tag: '', img: 'shoe-sample.png' },
                { title: 'Nike AF 1LOW', model: 'Cyberpunk', tag: '', img: 'shoe-sample.png' },
                { title: 'Nike AF 1LOW', model: 'Cyberpunk', tag: '', img: 'shoe-sample.png' },
                { title: 'Nike AF 1LOW', model: 'Cyberpunk', tag: '', img: 'shoe-sample.png' },
            ],
            preDesigned: [
                { title: 'Nike AF 1LOW', model: 'Cyberpunk', tag: 'New', img: 'shoe-sample.png' },
                { title: 'Nike AF 1LOW', model: 'Cyberpunk', tag: 'Best selling', img: 'shoe-sample.png' },
                { title: 'Nike AF 1LOW', model: 'Cyberpunk', tag: '', img: 'shoe-sample.png' },
                { title: 'Nike AF 1LOW', model: 'Cyberpunk', tag: '', img: 'shoe-sample.png' },
                { title: 'Nike AF 1LOW', model: 'Cyberpunk', tag: '', img: 'shoe-sample.png' },
                { title: 'Nike AF 1LOW', model: 'Cyberpunk', tag: '', img: 'shoe-sample.png' },
            ],
            addReviewPopup: false,
        }
    },

    components: {
        Navigation,
        NotificationBox,
        MainBanner,
        BtnTxtMdRound,
        TitleDouble,
        Samples,
        CustomizeBanner,
        StepsSwiper,
        Reviews,
        AddReviewPopup,
        SamlpesSkeleton,
        ReviewsSkeleton,
    },

    mounted() {
        this.setMetaData();
        this.checkSuccessRegistration();
        createObserver(this.$refs.recommandedItems, () => this.getRecommandedItems());
        createObserver(this.$refs.reviews, () => this.getReviews());
    },

    methods: {
        setMetaData() {
            useHead({
                title: `HOME | CUSTOM SNEAKERS`,
                meta: [
                    {
                        name: 'description',
                        content: ''
                    },
                ],
            });
        },

        notifyIsVisible(value) {
            this.notify = { isVisible: value, type: '', title: '', message: '' };
        },

        checkSuccessRegistration() {
            if (this.$store.state.successRegistration === true) {
                this.notify = { isVisible: true, type: 'success', title: 'Registration Successful', message: 'Thank you for registering! Please check your email to confirm your account.' };
                this.$store.commit('setSuccessRegistration', false);
            }
        },

        closeAddReviewPopup() {
            this.addReviewPopup = false;
        },

        async getRecommandedItems() {
            try {
                this.loading.isRecommandedItemsLoading = true;
                const response = await getRecommandedItems();
                this.recommandedItems = response.data;
                this.loading.isRecommandedItemsLoading = false;
            } catch (error) {
                this.recommandedItems = [];
                this.loading.isRecommandedItemsLoading = false;
            }
        },

        async getReviews() {
            try {
                this.loading.isReviewsLoading = true;
                const response = await getReviews();
                this.reviews = response.data;
                this.loading.isReviewsLoading = false;
            } catch (error) {
                this.loading.isReviewsLoading = false;
            }
        },
    }
}
</script>

<style scoped>

.intro-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.box-vl-content {
    display: flex;
}

.vl {
    width: 3px;
    min-height: 100%;
    margin-right: 20px;
    flex-shrink: 0;
    background-color: var(--primary-col);
}

.box-vl-content p{
    padding: 10px 0;
}

.intro-img-box {
    width: 400px;
    flex-shrink: 0;
}

.intro-img-box img {
    max-width: 100%;
}

.intro-btn-wrapper {
    margin-top: 30px;
    display: flex;
    justify-content: center;
}

.reviews-container {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.txt-testimonials {
    margin-bottom: 20px;
}

.hr-line {
    width: 250px;
    height: 1px;
    margin: 30px 0;
}


.test {
    width: 100%;
    height: 300px;
}

@media only screen and (max-width: 998px) {

    .intro-box{
        flex-direction: column;
    }
    
    .intro-img-box {
        max-width: 400px;
        width: 100%;
        order: 1;
        margin: 0 auto 20px auto;
    }

    .box-vl-content{
        order: 2;
    }
}
</style>