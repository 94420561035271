<template>
    <swiper :effect="'coverflow'" :grabCursor="true" :centeredSlides="true" :slidesPerView="slidePerView" :loop="true"
        :autoplay="{ delay: 1500, disableOnInteraction: false }" :coverflowEffect="{
            rotate: 50,
            stretch: 0,
            depth: 100,
            modifier: 1,
            slideShadows: true,
        }" :pagination="{ dynamicBullets: true, }" :modules="modules" class="steps-swiper">
        <swiper-slide v-for="(item, index) in steps" :key="index">
            <div class="item-box">
                <p class="title">{{ item.title }}</p>
                <div class="img-box">
                    <img :src="require('../../assets/images/home/steps-swiper/' + item.img)" alt="">
                </div>
            </div>
        </swiper-slide>
    </swiper>
</template>
<script>
import { Swiper, SwiperSlide } from 'swiper/vue';
import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/pagination';
import { EffectCoverflow, Pagination, Autoplay } from 'swiper/modules';

export default {

    data() {
        return {
            modules: [EffectCoverflow, Pagination, Autoplay],
            steps: [
                { title: 'PICK YOUR PRODUCT', img: 'product.png' },
                { title: 'DOWNLOAD CUSTOMIZE IMAGE', img: 'ps-logo.png' },
                { title: 'UPLOAD CUSTOMIZED IMAGE', img: 'upload.png' },
                { title: 'ADD TO CART', img: 'cart.png' },
                { title: 'PROCEED WITH PAYMENT', img: 'payment-methods.png' },
                { title: 'PICK YOUR PRODUCT', img: 'product.png' },
                { title: 'DOWNLOAD CUSTOMIZE IMAGE', img: 'ps-logo.png' },
                { title: 'UPLOAD CUSTOMIZED IMAGE', img: 'upload.png' },
                { title: 'ADD TO CART', img: 'cart.png' },
                { title: 'PROCEED WITH PAYMENT', img: 'payment-methods.png' },
            ]
        }
    },

    components: {
        Swiper,
        SwiperSlide,
    },

    computed: {
        slidePerView() {
            if (window.innerWidth >= 768) {
                return 4;
            } else if (window.innerWidth >= 575) {
                return 3;
            } else {
                return 2;
            }
        }
    }
};
</script>

<style scoped>
.swiper {
    width: 100%;
    padding-top: 50px;
    padding-bottom: 50px;
}

.item-box {
    height: 300px;
    padding: 40px 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: white;
}

.title {
    font-weight: bold;
    text-align: center;
}

.img-box {
    width: 100%;
    margin-top: 20px;
    padding-bottom: 60%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    overflow: hidden;
}

.img-box img {
    max-width: 100%;
    max-height: 100%;
    object-fit: cover;
    position: absolute;
    bottom: 0;
}

@media only screen and (max-width: 1200px) {
    .item-box {
        height: 220px;
    }
}

@media only screen and (max-width: 998px) {

    .title{
        font-size: 15px;
    }

    .item-box {
        height: 180px;
    }
}

@media only screen and (max-width: 768px) {
    .item-box {
        height: 180px;
    }
}

@media only screen and (max-width: 575px) {
    .item-box {
        height: 200px;
    }
}

@media only screen and (max-width: 400px) {
    .item-box {
        height: 150px;
    }
}
</style>
  