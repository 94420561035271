import { createApp } from 'vue'
import App from './App.vue'
import './assets/styles/main.css'
import Router from './router/index'
import { createHead } from '@vueuse/head'
import store from './store/index'
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { fas } from '@fortawesome/free-solid-svg-icons';

const head = createHead();
library.add(fas);

createApp(App)
.use(Router)
.use(head)
.use(store)
.component('font-awesome-icon', FontAwesomeIcon)
.mount('#app')
