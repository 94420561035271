<template>
    <div>
        <div class="main">
            <div class="items-row">
                <div v-for="(item, index) in items" :key="index" @click="itemClick(index)"
                    class="item-box hover-box-shadow">
                    <!-- <span v-if="item.tag !== ''" class="txt-tag txt-xs col-bg-primary">{{ item.tag }}</span> -->
                    <div class="content-box">
                        <div class="box-item-img">
                            <img :src="item.img_path_1" alt="">
                        </div>
                        <p class="title col-primary">{{ item.name }}</p>
                        <p v-if="item.brand !== 'all'" class="model txt-sm">{{ item.brand }}</p>
                    </div>
                </div>
            </div>
            <div class="see-all-btn-wrapper">
                <router-link v-if="btnVisible" class="a-see-all col-bg-primary txt-sm hover-box-shadow" :to="categoryLink">
                    See all
                    <div class="round">
                        <font-awesome-icon class="f-icon-close" :icon="['fa', 'arrow-right']" />
                    </div>
                </router-link>
            </div>
        </div>
    </div>
</template>

<script>
export default {

    props: {
        items: {
            type: Object,
            default: null,
        },

        btnVisible: {
            type: Boolean,
            default: false,
        },
        categoryLink: {
            type: String,
            default: '/',
        }
    },

    methods: {
        itemClick(index) {
            this.$router.push(`/shop/item/${this.items[index].id}`);
            setTimeout(() => {
                window.location.reload();
            }, 500);
        },
    },

}
</script>

<style scoped>
.items-row {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    gap: 20px;
    align-items: stretch;
}

.item-box {
    padding: 10px 10px 20px 10px;
    border-radius: 10px;
    cursor: pointer;
    background-color: white;
    position: relative;
}

.txt-tag {
    padding: 1px 5px;
    border-radius: 2px;
    position: absolute;
}

.content-box {
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.box-item-img {
    width: 90%;
    margin: 10px 0 20px 0;
    padding-bottom: 80%;
    position: relative;
    overflow: hidden;
}

.box-item-img img {
    max-width: 100%;
    max-height: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.title,
.model {
    font-weight: bold;
    text-align: center;
}

.title {
    margin-bottom: 5px;
}

.see-all-btn-wrapper {
    display: flex;
    justify-content: flex-end;
}

.a-see-all {
    width: 100px;
    margin-top: 40px;
    padding: 5px 10px;
    border: 2px solid var(--primary-col);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 20px;
    font-weight: 900;
}

.a-see-all .round {
    width: 20px;
    height: 20px;
    margin-left: 5px;
    background-color: white;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.a-see-all:hover {
    background-color: white;
    color: var(--primary-col);
}

@media only screen and (max-width: 992px) {
    .items-row {
        grid-template-columns: repeat(3, 1fr);
    }
}

@media only screen and (max-width: 575px) {
    .items-row {
        grid-template-columns: repeat(2, 1fr);
    }
}
</style>