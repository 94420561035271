<template>
    <div>
     <Navigation /> 
     <SideNavibar />
        <div class="main bg-gradient">
            
        </div>
    </div>
</template>

<script>
import { useHead } from '@vueuse/head';
import Navigation from '../components/common/navigation-com.vue';
import SideNavibar from '../components/profile/side-navibar-com.vue';

export default {
    data() {
        return {

        }
        
    },
    

    components: {
        Navigation,
        SideNavibar,
    },

    mounted() {
        this.setMetaData();
    },

    methods: {
        setMetaData() {
            useHead({
                title: `CART`,
                meta: [
                    {
                        name: 'description',
                        content: ``
                    },
                ],
            });
        },
    },  
}
</script>

<style scoped>

</style>