<template>
    <div class="main">
        <div class="lg-side-wrapper">
            <div class="lg-slide-content">
                <h3 class="pad-com-l">Profile</h3>
                <router-link to="/overview">
                    <div class="pad-com-l" :class="{ 'link-box': true, 'link-box-active': $route.path === '/overview' }">
                        <p>Overview</p>
                    </div>
                </router-link>
                <router-link to="/settings">
                    <div class="pad-com-l" :class="{ 'link-box': true, 'link-box-active': $route.path === '/settings' }">
                        <p>Settings</p>
                    </div>
                </router-link>
            </div>
        </div>
        <div class="sm-side-wrapper">
            <div class="sm-slide-btn-wraper res-px">
                <button @click="smNaviIsVisible = true;">
                    <font-awesome-icon class="f-icon" :icon="['fa', 'bars']" />
                </button>
                <h4>Profile</h4>
                <div></div>
            </div>
            <transition name="navi-trans">
                <div v-if="smNaviIsVisible" @click="smNaviIsVisible = false;" class="sm-side-navigation-main">
                    <div @click.stop class="sm-side-nav-box">
                        <div class="close-btn-wrapper">
                            <button @click="smNaviIsVisible = false;">
                                <font-awesome-icon class="f-icon" :icon="['fa', 'close']" />
                            </button>
                        </div>
                        <h3 class="pad-com-l">Profile</h3>
                        <router-link to="/overview">
                            <div class="pad-com-l"
                                :class="{ 'link-box': true, 'link-box-active': $route.path === '/overview' }">
                                <p>Overview</p>
                            </div>
                        </router-link>
                        <router-link to="/settings">
                            <div class="pad-com-l"
                                :class="{ 'link-box': true, 'link-box-active': $route.path === '/settings' }">
                                <p>Settings</p>
                            </div>
                        </router-link>
                    </div>
                </div>
            </transition>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            smNaviIsVisible: false,
        }
    }
}
</script>

<style scoped>

.main{
    z-index: 2;
}

.pad-com-l {
    padding-left: 30px;
}

.lg-side-wrapper {
    width: 250px;
    flex-shrink: 0;
    background-color: rgba(0, 0, 0, 0.08);
}

.lg-slide-content {
    padding-top: 50px;
    position: sticky;
    top: 0;
}

h3 {
    margin-bottom: 30px;
}

.link-box {
    height: 50px;
    display: flex;
    align-items: center;
    border-left: 10px solid transparent;
    background-color: transparent;
}

.link-box-active {
    height: 50px;
    display: flex;
    align-items: center;
    border-left: 10px solid var(--primary-col);
    background-color: white;
}

.sm-side-wrapper {
    display: none;
}

@media only screen and (max-width: 1200px) {

    h4 {
        margin-right: 30px;
    }

    .lg-side-wrapper {
        display: none;
    }

    .sm-side-wrapper {
        display: block;
    }

    .sm-slide-btn-wraper {
        height: 40px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: rgba(0, 0, 0, 0.079);
    }

    .sm-side-wrapper button {
        width: 30px;
        height: 25px;
        padding: 5px;
        border: none;
        border-radius: 3px;
    }

    .sm-side-navigation-main {
        height: 100vh;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        background-color: transparent;
    }

    .sm-side-nav-box {
        max-width: 300px;
        height: 100%;
        padding-top: 20px;
        background-color: rgb(226, 226, 226);
    }

    .close-btn-wrapper {
        margin-bottom: 30px;
        padding: 10px;
        display: flex;
        justify-content: flex-end;
    }

    .close-btn-wrapper button {
        width: 30px;
        height: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: transparent;
    }

    .close-btn-wrapper button .f-icon {
        width: 25px;
        height: 25px;
    }
}

.navi-trans-enter-from,
.navi-trans-leave-to {
    left: -100%;
}

.navi-trans-enter-active,
.navi-trans-leave-active {
    transition: all .5s ease;
}
</style>