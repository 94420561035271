<template>
    <div class="bg-gradient">
        <Loader :loading="isLoading" />
        <div class="main container res-px">
            <p>{{ result }}</p>
            <router-link v-if="isErr" to="/">Back to home</router-link>
        </div>
    </div>
</template>

<script>
import Loader from '../components/common/loading-com.vue';
import { emailVerify } from '../services/api';
import { setToken } from '../utils/helper';
export default {

    data() {
        return {
            isLoading: false,
            isErr: false,
            result: 'Please wait.....',
        }
    },

    mounted() {
        this.emailVerification();
    },
    
    components: {
        Loader,
    },

    methods: {
        async emailVerification() {
            const id = setToken();
            const verificationCode = this.$route.params.verificationCode;
            if(id !== null) {
                try {
                    const postData = {
                        id: id,
                        token: verificationCode
                    };
                    await emailVerify(postData);
                    this.result = 'Your account has been successfully verified. Redirecting to home....';
                    setTimeout(() => {
                        window.location.href = '/';
                    }, 2000);
                } catch (error) {
                    this.isErr = true;
                    if(error.response.status === 410) {
                        this.result = 'Your account is already verified. No further action is needed. Contact support for assistance.';
                    } else {
                        this.result = 'Verification failed. Contact support if the issue persists.';
                    }
                }
            } else {
                this.result = 'Verification failed. Contact support if the issue persists.';
                this.isErr = true;
            }
        }
    },
}
</script>

<style scoped>
    .main{
        height: 100vh;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    a{
        color: blue;
        margin-top: 30px;
    }
</style>