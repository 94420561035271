<template>
    <div class="bg-gradient">
        <Navigation />
        <div class="main container res-px">
            <div class="content-1">
                <h2 class="head">Privacy Policy</h2>
                <p class="para">Last updated: 28/6/2023</p>
                <p class="paragraph">This Privacy Policy outlines how we collect, use, disclose, and protect the personal
                    information
                    provided by users of the Website
                </p>
            </div>
            <div class="content-2">
                <h2 class="head">Information We Collect</h2>

                <p class="paragraph">1.1 Personal Information: When you browse or make a purchase on our Website, we may
                    collect
                    personal information such as your name, email address, shipping address, billing address, phone
                    number, and payment information. We collect this information solely for the purpose of processing
                    and fulfilling your orders.
                </p>

                <p class="paragraph">1.2 Log Data: We may also collect log data, including your IP address, browser type,
                    operating
                    system, referring/exit pages, and clickstream data. We use this information to analyze trends,
                    administer the Website, track users' movements on the Website, and gather demographic information
                    for aggregate use.
                </p>
            </div>
            <div class="content-2">
                <h2 class="head">Use of Information</h2>

                <p class="paragraph">2.1 Order Processing: We use the personal information you provide to process and
                    fulfill your orders,
                    including verifying your identity, processing payments, arranging shipping, and providing customer
                    support related to your purchase.
                </p>

                <p class="paragraph">2.2 Communication: We may use your email address or phone number to communicate with
                    you
                    regarding your orders, provide updates, respond to inquiries, and send marketing communications if
                    you have opted-in to receive such messages.
                </p>

                <p class="paragraph">2.3 Analytics: We may use aggregated and anonymized information, including log data,
                    for
                    analytical purposes to improve our Website, enhance user experience, and optimize our
                    marketing efforts.
                </p>
            </div>
            <div class="content-2">
                <h2 class="head">Disclosure of Information</h2>

                <p class="paragraph">3.1 Third-Party Service Providers: We may engage trusted third-party service providers,
                    such
                    as payment processors or shipping companies, to assist us in fulfilling orders and providing
                    services to you. These providers have access to the personal information needed to perform
                    their functions but are obligated not to disclose or use it for any other purpose.
                </p>

                <p class="paragraph">3.2 Legal Compliance: We may disclose your personal information if required by law or
                    in
                    response to valid legal requests, such as subpoenas, court orders, or government
                    regulations.
                </p>
            </div>

            <div class="content-2">
                <h2 class="head">Data Security</h2>

                <p class="paragraph">We take reasonable measures to protect your personal information from unauthorized
                    access, alteration, disclosure, or destruction. However, please note that no method of
                    transmission over the Internet or electronic storage is 100% secure. Therefore, we cannot
                    guarantee absolute security.
                </p>
            </div>

            <div class="content-2">
                <h2 class="head">Your Choices</h2>

                <p class="paragraph">5.1 Opt-Out: You may choose not to receive marketing communications from us by
                    following
                    the unsubscribe instructions provided in the emails or by contacting us directly.
                </p>

                <p class="paragraph">5.2 Access and Update: You have the right to access, correct, or delete your personal
                    information held by us. If you would like to review or modify your information, please contact
                    us using the information provided at the end of this Privacy Policy.
                </p>
            </div>
            <div class="content-2">
                <h2 class="head">Your Choices</h2>

                <p class="paragraph">5.1 Opt-Out: You may choose not to receive marketing communications from us by
                    following
                    the unsubscribe instructions provided in the emails or by contacting us directly.
                </p>

                <p class="paragraph">5.2 Access and Update: You have the right to access, correct, or delete your personal
                    information held by us. If you would like to review or modify your information, please contact
                    us using the information provided at the end of this Privacy Policy.
                </p>
            </div>
            <div class="content-2">
                <h2 class="head">Changes to this Privacy Policy</h2>

                <p class="paragraph">We reserve the right to update or modify this Privacy Policy at any time. We will
                    notify you of
                    any changes by posting the revised policy on the Website. It is your responsibility to review
                    this Privacy Policy periodically.
                </p>
            </div>
            <div class="content-2">
                <h2 class="head">Contact Us</h2>

                <p class="paragraph">If you have any questions, concerns, or requests regarding this Privacy Policy, please
                    contact
                    us at info@customsneakers.com.
                </p>
            </div>
        </div>
    </div>
</template>

<script>
import { useHead } from '@vueuse/head';
import Navigation from '../components/common/navigation-com.vue';
export default {

    data() {
        return {

        }
    },

    components: {
        Navigation,
    },

    mounted() {
        this.setMetaData();
    },

    methods: {
        setMetaData() {
            useHead({
                title: `PRIVACY POLICY | CUSTOM SNEAKERS`,
                meta: [
                    {
                        name: 'description',
                        content: ``
                    },
                ],
            });
        },
    }
}
</script>

<style scoped>
.main {
    padding-top: 170px;
    padding-bottom: 50px;
}

.para,
.paragraph {
    font-size: 20px;
    margin-top: 20px;
}

.content-2 {
    padding-top: 50px;
}

@media only screen and (max-width: 768px) {

    .main {
        padding-top: 130px;
    }

    .para,
    .paragraph {
        margin-top: 20px;
    }
}
</style>