<template>
    <div>
        <Navigation :showTransition="false" />
        <Loader :loading="isLoading" />
        <NotificationBox :isVisible="notify.isVisible" :type="notify.type" :title="notify.title" :message="notify.message"
            @notificationIsVisible="notifyIsVisible()" />
        <div class="main bg-gradient">
            <SideNaviBar />
            <div class="warning-profile-container">
                <div v-if="isAccountVerified === 0" class="warning-box">
                    <div>
                        <font-awesome-icon class="f-icon-exclamation" :icon="['fa', 'exclamation-circle']" />
                        <p>Account Not Verified!</p>
                    </div>
                </div>
                <div class="profile-main">
                    <h3>Settings</h3>
                    <div class="profile-container">
                        <div class="profile-img-pw-wrapper">
                            <div class="profile-img-wrapper">
                                <button @click="removeProfileImg()" class="btn-profile-img-rem">
                                    <font-awesome-icon class="f-icon" :icon="['fa', 'trash']" />
                                </button>
                                <label for="upload-profile-img" class="lbl-profile-img">
                                    <img v-if="profileImgURL !== null && profileImgURL !== ''" :src="profileImgURL" alt="">
                                    <font-awesome-icon v-if="profileImgURL === null || profileImg === ''"
                                        class="f-icon-user" :icon="['fa', 'user-plus']" />
                                    <input class="inp-profile-img" type="file" name="" id="upload-profile-img"
                                        accept=".jpg, .jpeg, .png, .webp" @change="profileImgUpload()" ref="profileImg">
                                </label>
                            </div>
                            <p class="txt-email txt-sm">{{ email }}</p>
                            <div class="password-change-box">
                                <div class="hor-line"></div>
                                <p class="txt-sub-t">Change Password</p>
                                <div class="inp-box">
                                    <p class="txt-label">Old password</p>
                                    <input v-model="oldPassword"
                                        :class="errors.oldPassword === '' ? 'inp-reg' : 'inp-error'" type="password" name=""
                                        id="" placeholder="Type your current password here">
                                    <p v-if="errors.oldPassword !== ''" class="txt-error txt-sm">{{ errors.oldPassword }}
                                    </p>
                                </div>
                                <div class="inp-box">
                                    <p class="txt-label">New password</p>
                                    <input v-model="newPassword"
                                        :class="errors.newPassword === '' ? 'inp-reg' : 'inp-error'" type="password" name=""
                                        id="" placeholder="Type your new password here">
                                    <p v-if="errors.newPassword !== ''" class="txt-error txt-sm">{{ errors.newPassword }}
                                    </p>
                                </div>
                                <div class="inp-box">
                                    <p class="txt-label">Confirm password</p>
                                    <input v-model="confirmPassword"
                                        :class="errors.confirmPassword === '' ? 'inp-reg' : 'inp-error'" type="password"
                                        name="" id="" placeholder="Re-type your new password here">
                                    <p v-if="errors.confirmPassword !== ''" class="txt-error txt-sm">{{
                                        errors.confirmPassword
                                    }}
                                    </p>
                                </div>
                                <BtnTxtMdRound :buttonText="'Change Password'" @custom-click="passwordChange()" />
                                <div class="hor-line"></div>
                            </div>
                            <div class="logout-delete-btns-wrapper">
                                <BtnTxtMdRound :buttonText="'Log Out'" @custom-click="logout()" /> <br>
                                <BtnTxtMdRound :buttonText="'Delete Account'" :buttonColor="'red'"
                                    @custom-click="removeAccount()" />
                            </div>
                            <div class="hor-line"></div>
                        </div>
                        <div class="profile-user-data-wrapper">
                            <div class="inp-box">
                                <p class="txt-label">First Name</p>
                                <input v-model="firstName" :class="errors.firstName === '' ? 'inp-reg' : 'inp-error'"
                                    type="text" name="" id="" placeholder="Peter">
                                <p v-if="errors.firstName !== ''" class="txt-error txt-sm">{{ errors.firstName }}</p>
                            </div>
                            <div class="inp-box">
                                <p class="txt-label">Last Name</p>
                                <input v-model="lastName" :class="errors.lastName === '' ? 'inp-reg' : 'inp-error'"
                                    type="text" name="" id="" placeholder="Parker">
                                <p v-if="errors.lastName !== ''" class="txt-error txt-sm">{{ errors.lastName }}</p>
                            </div>
                            <div class="inp-box">
                                <p class="txt-label">Phone 1</p>
                                <input v-model="phone1" class="inp-reg" type="text" name="" id=""
                                    placeholder="07- --- ----">
                            </div>
                            <div class="inp-box">
                                <p class="txt-label">Phone 2</p>
                                <input v-model="phone2" class="inp-reg" type="text" name="" id=""
                                    placeholder="07- --- ----">
                            </div>
                            <div class="inp-box">
                                <p class="txt-label">Address Line 1</p>
                                <input v-model="addressLine1" class="inp-reg" type="text" name="" id=""
                                    placeholder="somewhere">
                            </div>
                            <div class="inp-box">
                                <p class="txt-label">Address Line 2</p>
                                <input v-model="addressLine2" class="inp-reg" type="text" name="" id=""
                                    placeholder="somewhere">
                            </div>
                            <div class="inp-box">
                                <p class="txt-label">Province</p>
                                <input v-model="province" class="inp-reg" type="text" name="" id="" placeholder="Central">
                            </div>
                            <div class="inp-box">
                                <p class="txt-label">District</p>
                                <input v-model="district" class="inp-reg" type="text" name="" id="" placeholder="Kandy">
                            </div>
                            <div class="inp-box">
                                <p class="txt-label">City</p>
                                <input v-model="city" class="inp-reg" type="text" name="" id="" placeholder="Kandy">
                            </div>
                            <div class="inp-box">
                                <p class="txt-label">Postal Code</p>
                                <input v-model="postalCode" class="inp-reg" type="text" name="" id="" placeholder="20000">
                            </div>
                            <div class="user-data-save-btns-wrapper">
                                <BtnTxtMdRound :buttonText="'Save'" @custom-click="saveUserData()" /><br />
                            </div>
                        </div>
                        <div class="sm-password-change-box">
                            <div class="hor-line"></div>
                            <p class="txt-sub-t">Change Password</p>
                            <div class="inp-box">
                                <p class="txt-label">Old password</p>
                                <input v-model="oldPassword" :class="errors.oldPassword === '' ? 'inp-reg' : 'inp-error'"
                                    type="password" name="" id="" placeholder="Type your current password here">
                                <p v-if="errors.oldPassword !== ''" class="txt-error txt-sm">{{ errors.oldPassword }}</p>
                            </div>
                            <div class="inp-box">
                                <p class="txt-label">New password</p>
                                <input v-model="newPassword" :class="errors.newPassword === '' ? 'inp-reg' : 'inp-error'"
                                    type="password" name="" id="" placeholder="Type your new password here">
                                <p v-if="errors.newPassword !== ''" class="txt-error txt-sm">{{ errors.newPassword }}</p>
                            </div>
                            <div class="inp-box">
                                <p class="txt-label">Confirm password</p>
                                <input v-model="confirmPassword"
                                    :class="errors.confirmPassword === '' ? 'inp-reg' : 'inp-error'" type="password" name=""
                                    id="" placeholder="Re-type your new password here">
                                <p v-if="errors.confirmPassword !== ''" class="txt-error txt-sm">{{ errors.confirmPassword
                                }}
                                </p>
                            </div>
                            <div class="password-change-btn-wrapper">
                                <BtnTxtMdRound :buttonText="'Change Password'" @custom-click="passwordChange()" />
                            </div>
                            <div class="hor-line"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
/* eslint-disable */
import { useHead } from '@vueuse/head';
import Navigation from '../components/common/navigation-com.vue';
import BtnTxtMdRound from '../components/common/btn-txt-md-round-com.vue';
import SideNaviBar from '../components/profile/side-navibar-com.vue';
import { setToken } from '../utils/helper';
import NotificationBox from '../components/common/notification-box-com.vue';
import Loader from '../components/common/loading-com.vue';
import { getUserData, updateUserData, changePassword, deleteAcoount } from '../services/api';
import Swal from 'sweetalert2';

export default {

    data() {
        return {
            isLoading: false,
            notify: { isVisible: false, type: '', title: '', message: '' },
            token: null,
            oldPassword: '',
            newPassword: '',
            confirmPassword: '',
            email: '',
            profileImgURL: null,
            profileImg: null,
            firstName: '',
            lastName: '',
            phone1: '',
            phone2: '',
            addressLine1: '',
            addressLine2: '',
            province: '',
            district: '',
            city: '',
            postalCode: '',
            isAccountVerified: true,
            errors: {
                oldPassword: '',
                newPassword: '',
                confirmPassword: '',
                firstName: '',
                lastName: '',
            }
        }
    },

    components: {
        Navigation,
        BtnTxtMdRound,
        SideNaviBar,
        NotificationBox,
        Loader,
    },

    mounted() {
        this.loadUserData();
        this.setMetaData();
    },

    methods: {
        setMetaData() {
            useHead({
                title: `SETTINGS | CUSTOM SNEAKERS`,
                meta: [
                    {
                        name: 'description',
                        content: ``
                    },
                ],
            });
        },

        notifyIsVisible(value) {
            this.notify = { isVisible: value, type: '', title: '', message: '' };
        },

        profileImgUpload() {
            this.profileImg = this.$refs.profileImg.files[0];
            this.profileImgURL = URL.createObjectURL(this.profileImg);
        },

        async loadUserData() {
            this.isLoading = true;
            this.token = setToken();
            if (this.token !== null) {
                try {
                    const response = await getUserData(this.token);
                    this.email = response.data.email;
                    this.profileImgURL = response.data.img_path;
                    this.firstName = response.data.first_name;
                    this.lastName = response.data.last_name;
                    this.phone1 = response.data.phone_1;
                    this.phone2 = response.data.phone_2;
                    this.addressLine1 = response.data.address_line_1;
                    this.addressLine2 = response.data.address_line_2;
                    this.province = response.data.province;
                    this.district = response.data.district;
                    this.city = response.data.city;
                    this.postalCode = response.data.postal_code;
                    this.isAccountVerified = response.data.is_verified;
                } catch (error) {

                }
            } else {
                this.$router.push('/login')
            }
            this.isLoading = false;
        },

        removeProfileImg() {
            this.profileImg = null;
            this.profileImgURL = null;
        },

        clearValidation() {
            const clearErrorObj = Object.fromEntries(
                Object.entries(this.errors).map(([key]) => [key, ''])
            );

            this.errors = clearErrorObj;
        },

        updateUserDataFormValidation() {
            this.clearValidation();

            if (this.firstName === '') {
                this.errors.firstName = 'First name is required!';
            } else {
                this.errors.firstName = '';
            }

            if (this.lastName === '') {
                this.errors.lastName = 'Last name is required!';
            } else {
                this.errors.lastName = '';
            }
        },

        passwordChangeValidation() {
            this.clearValidation();

            if (this.oldPassword === '') {
                this.errors.oldPassword = 'Old password is required!';
            } else {
                this.errors.oldPassword = '';
            }

            if (this.newPassword === '') {
                this.errors.newPassword = 'New password is required!';
            } else if (this.newPassword.length < 6) {
                this.errors.newPassword = 'New password must containg more than 5 characters!'
            } else {
                this.errors.newPassword = '';
            }

            if (this.confirmPassword === '') {
                this.errors.confirmPassword = 'Password confirmation is required!';
            } else if (this.confirmPassword !== this.newPassword) {
                this.errors.confirmPassword = 'Confirmation password is not as same as the new password!'
            } else {
                this.errors.confirmPassword = '';
            }
        },

        removeAccountValidation() {
            this.clearValidation();
            if (this.oldPassword === '') {
                this.errors.oldPassword = 'Old password is required!';
            } else {
                this.errors.oldPassword = '';
            }
        },

        async passwordChange() {
            this.isLoading = true;
            this.passwordChangeValidation();

            if (this.errors.oldPassword === '' && this.errors.newPassword === '' && this.errors.confirmPassword === '') {
                Swal.fire({
                    title: "Are you sure?",
                    text: "Are you sure you want to change your password?",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "Change"
                }).then(async (result) => {
                    if (result.isConfirmed) {
                        try {
                            const postData = {
                                id: this.token,
                                oldPassword: this.oldPassword,
                                newPassword: this.newPassword
                            };
                            const response = await changePassword(postData);
                            this.oldPassword = '';
                            this.newPassword = '';
                            this.confirmPassword = '';
                            this.notify = { isVisible: true, type: 'success', title: response.data.title, message: response.data.message };
                        } catch (error) {
                            this.notify = { isVisible: true, type: 'error', title: error.response.data.title, message: error.response.data.message };
                        }
                    }
                });
            }
            this.isLoading = false;
        },

        async saveUserData() {
            this.isLoading = true;
            this.updateUserDataFormValidation();

            if (this.errors.firstName === '' && this.errors.lastName === '') {
                const postData = new FormData();
                postData.append('id', this.token)
                postData.append('image', this.profileImg);
                postData.append('firstName', this.firstName);
                postData.append('lastName', this.lastName);
                postData.append('phone1', this.phone1);
                postData.append('phone2', this.phone2);
                postData.append('addressLine1', this.addressLine1);
                postData.append('addressLine2', this.addressLine2);
                postData.append('province', this.province);
                postData.append('district', this.district);
                postData.append('city', this.city);
                postData.append('postalCode', this.postalCode);

                try {
                    const response = await updateUserData(postData);
                    this.notify = { isVisible: true, type: 'success', title: response.data.title, message: response.data.message };
                } catch (error) {
                    this.notify = { isVisible: true, type: 'error', title: error.response.data.title, message: error.response.data.message };
                }
            }
            this.isLoading = false;
        },

        logout() {
            this.isLoading = true;
            localStorage.setItem('token', '');
            setTimeout(() => {
                this.isLoading = false;
                this.$router.push('/login');
            }, 1000);
        },

        async removeAccount() {

            this.isLoading = true;
            this.removeAccountValidation();

            if (this.errors.oldPassword === '') {
                Swal.fire({
                    title: "Are you sure?",
                    text: "Your account with order details will be lost and you won't be able to revert this!",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "Yes, delete it!"
                }).then(async (result) => {
                    if (result.isConfirmed) {
                        try {
                            const postData = {
                                id: this.token,
                                password: this.oldPassword,
                            };
                            let response = await deleteAcoount(postData);
                            localStorage.removeItem('token');
                            this.$router.push('/signup');
                        } catch (error) {
                            this.notify = { isVisible: true, type: 'error', title: error.response.data.title, message: error.response.data.message };
                        }
                    }
                });
            }

            this.isLoading = false;
        },
    }
}
</script>

<style scoped>
.txt-error {
    color: red;
}

.hor-line {
    width: 100%;
    height: 1px;
    margin: 20px 0;
    background-color: rgba(0, 0, 0, 0.145);
}

h3 {
    margin-left: 10px;
}

.main {
    display: flex;
}

.warning-profile-container {
    width: 100%;
}

.warning-box {
    padding: 20px 10px;
    background-color: rgba(255, 166, 0, 0.169);
    display: flex;
    justify-content: center;
}

.warning-box div {
    display: flex;
    align-items: center;
}

.f-icon-exclamation {
    width: 20px;
    height: 20px;
    margin-right: 10px;
    color: rgb(255, 140, 0);
}

.warning-box div p {
    color: rgb(255, 140, 0);
}

.profile-main {
    max-width: 1000px;
    width: 100%;
    margin: auto;
    min-height: 100vh;
    padding-top: 50px;
}

.profile-container {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.profile-img-pw-wrapper,
.profile-user-data-wrapper {
    width: 49%;
    padding-top: 20px;
}

.profile-img-pw-wrapper {
    padding-left: 10px;
}

.profile-img-wrapper {
    width: 64px;
    height: 64px;
    margin-bottom: 15px;
    position: relative;
}

.lbl-profile-img {
    width: 60px;
    height: 60px;
    cursor: pointer;
    border: 2px solid var(--primary-col);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    position: relative;
}

.lbl-profile-img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.f-icon-plus {
    width: 20px;
    height: 20px;
    color: var(--primary-col);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.f-icon-user {
    width: 40px;
    height: 40px;
    color: rgba(0, 0, 0, 0.329);
}

.inp-profile-img {
    width: 0;
    opacity: 0;
    position: absolute;
    z-index: -1;
}

.btn-profile-img-rem {
    width: 25px;
    height: 25px;
    background-color: red;
    border: none;
    border-radius: 50%;
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: 1;
}

.btn-profile-img-rem .f-icon {
    width: 13px;
    height: 13px;
    color: white;
}

.password-change-box {
    margin: 50px 0;
}

.txt-sub-t {
    font-weight: 900;
    margin-bottom: 20px;
}

.inp-box {
    max-width: 400px;
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
}

.txt-label {
    margin-bottom: 5px;
}

.inp-reg {
    height: 35px;
    margin-bottom: 3px;
    padding-left: 10px;
    border: 1px solid rgba(0, 0, 0, 0.175);
    border-radius: 10px;
}

.inp-error {
    height: 35px;
    margin-bottom: 3px;
    padding-left: 10px;
    border: 1px solid red;
    border-radius: 10px;
}

.user-data-save-btns-wrapper {
    max-width: 400px;
    margin-bottom: 30px;
    display: flex;
    justify-content: center;
}

.sm-password-change-box {
    display: none;
}

@media only screen and (max-width: 1200px) {

    h3 {
        margin-bottom: 20px;
    }

    .main {
        min-height: auto;
        flex-direction: column;
    }

    .profile-main {
        padding-top: 30px;
    }

    .profile-img-pw-wrapper,
    .profile-user-data-wrapper {
        padding: 0 10px;
    }

}

@media only screen and (max-width: 575px) {

    .warning-box {
        padding: 15px 10px;
    }

    .profile-main {
        padding-top: 20px;
    }

    h3 {
        text-align: center;
    }

    .txt-email {
        margin-bottom: 30px;
        text-align: center;
    }

    .profile-img-wrapper {
        margin-left: auto;
        margin-right: auto;
    }

    .logout-delete-btns-wrapper {
        padding: 0 10px;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
    }

    .profile-container {
        flex-direction: column;
    }

    .profile-img-pw-wrapper,
    .profile-user-data-wrapper {
        width: 100%;
        padding: 0;
    }

    .inp-box {
        max-width: 100%;
        padding: 0 10px;
    }

    .user-data-save-btns-wrapper {
        max-width: 100%;
        padding: 0 10px;
    }

    .password-change-box {
        display: none;
    }

    .password-change-btn-wrapper {
        display: flex;
        justify-content: center;
    }

    .sm-password-change-box {
        display: block;
    }

    .sm-password-change-box .txt-sub-t {
        margin-left: 10px;
    }
}
</style>