<template>
    <div>
        <Navigation />
        <div class="main bg-gradient">
            <div class="empty-items">
                <img src="../assets/images/empty-null samples/shop-no-items.webp" alt="">
            </div>
            <div v-if="condition" class="container res-px py">
                <div class="content-1">
                    <img src="@/assets/images/blog/blog-top-shoe.webp " class="blog-shoe" />
                    <div class="intro-blog">
                        <h2 class="heading">Nike is finally making the self-lacing shoes
                            from Back To The Future!
                        </h2>
                        <div class="blog-paragraph">
                            <p class="para">Nike has announced that it is finaly making a producrion run of its self-lacing
                                shoes first introduced 30years ago, in the future, on 21st Octomber 2015.</p>

                            <p class="para with-fade">The shoes -- which as everyone who made it trough Back To Future Day
                                must know -- were a fun sight gag which featured in the 1989 movie on the feet of marty
                                McFly , aka Michael J.Fox.</p>

                            <button class="read">Read More
                                <font-awesome-icon class="f-icon-close" :icon="['fa', 'arrow-right']" />
                            </button>
                        </div>
                    </div>
                </div>
                <div class="grid-box">
                    <div v-for="(item, index) in items" :key="index" class="content-2">
                        <div class="wrapper-image">
                            <img :src="item.image" :alt="'Image ' + (index + 1)" />
                        </div>
                        <h2 class="heading">{{ item.title }}</h2>
                        <div class="paragraph-new">
                            <p class="para with-fade">{{ item.description }}</p>
                            <button class="read">Read More
                                <font-awesome-icon class="f-icon-close" :icon="['fa', 'arrow-right']" />
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { useHead } from '@vueuse/head';
import Navigation from '../components/common/navigation-com.vue';
export default {

    data() {
        return {
            items: [
                {
                    image: require("@/assets/images/blog/blog-bottom-shoe1.webp"),
                    title: "Nike is finally making the self-lacing shoes from Back To The Future!",
                    description: `Nike has announced that it is finally making a production run of its self-lacing shoes first introduced 30 years ago, in the future, on 21st October 2015.
                The shoes -- which as everyone who made it trough Back To Future Day must know -- were a fun sight gag which featured in the 1989 movie on the feet of Marty McFly, aka Michael J.Fox.`,
                },
                {
                    image: require("@/assets/images/blog/blog-bottom-shoe2.webp"),
                    title: "Nike is finally making the self-lacing shoes from Back To The Future!",
                    description: `Nike has announced that it is finally making a production run of its self-lacing shoes first introduced 30 years ago, in the future, on 21st October 2015.
                The shoes -- which as everyone who made it trough Back To Future Day must know -- were a fun sight gag which featured in the 1989 movie on the feet of Marty McFly, aka Michael J.Fox.`,
                },
                {
                    image: require("@/assets/images/blog/blog-bottom-shoe3.webp"),
                    title: "Nike is finally making the self-lacing shoes from Back To The Future!",
                    description: `Nike has announced that it is finally making a production run of its self-lacing shoes first introduced 30 years ago, in the future, on 21st October 2015.
                The shoes -- which as everyone who made it trough Back To Future Day must know -- were a fun sight gag which featured in the 1989 movie on the feet of Marty McFly, aka Michael J.Fox.`,
                },
                {
                    image: require("@/assets/images/blog/blog-bottom-shoe3.webp"),
                    title: "Nike is finally making the self-lacing shoes from Back To The Future!",
                    description: `Nike has announced that it is finally making a production run of its self-lacing shoes first introduced 30 years ago, in the future, on 21st October 2015.
                The shoes -- which as everyone who made it trough Back To Future Day must know -- were a fun sight gag which featured in the 1989 movie on the feet of Marty McFly, aka Michael J.Fox.`,
                },
                {
                    image: require("@/assets/images/blog/blog-bottom-shoe1.webp"),
                    title: "Nike is finally making the self-lacing shoes from Back To The Future!",
                    description: `Nike has announced that it is finally making a production run of its self-lacing shoes first introduced 30 years ago, in the future, on 21st October 2015.
                The shoes -- which as everyone who made it trough Back To Future Day must know -- were a fun sight gag which featured in the 1989 movie on the feet of Marty McFly, aka Michael J.Fox.`,
                },
                {
                    image: require("@/assets/images/blog/blog-bottom-shoe2.webp"),
                    title: "Nike is finally making the self-lacing shoes from Back To The Future!",
                    description: `Nike has announced that it is finally making a production run of its self-lacing shoes first introduced 30 years ago, in the future, on 21st October 2015.
                The shoes -- which as everyone who made it trough Back To Future Day must know -- were a fun sight gag which featured in the 1989 movie on the feet of Marty McFly, aka Michael J.Fox.`,
                },
                {
                    image: require("@/assets/images/blog/blog-bottom-shoe1.webp"),
                    title: "Nike is finally making the self-lacing shoes from Back To The Future!",
                    description: `Nike has announced that it is finally making a production run of its self-lacing shoes first introduced 30 years ago, in the future, on 21st October 2015.
                The shoes -- which as everyone who made it trough Back To Future Day must know -- were a fun sight gag which featured in the 1989 movie on the feet of Marty McFly, aka Michael J.Fox.`,
                },
                {
                    image: require("@/assets/images/blog/blog-bottom-shoe2.webp"),
                    title: "Nike is finally making the self-lacing shoes from Back To The Future!",
                    description: `Nike has announced that it is finally making a production run of its self-lacing shoes first introduced 30 years ago, in the future, on 21st October 2015.
                The shoes -- which as everyone who made it trough Back To Future Day must know -- were a fun sight gag which featured in the 1989 movie on the feet of Marty McFly, aka Michael J.Fox.`,
                },
                {
                    image: require("@/assets/images/blog/blog-bottom-shoe3.webp"),
                    title: "Nike is finally making the self-lacing shoes from Back To The Future!",
                    description: `Nike has announced that it is finally making a production run of its self-lacing shoes first introduced 30 years ago, in the future, on 21st October 2015.
                The shoes -- which as everyone who made it trough Back To Future Day must know -- were a fun sight gag which featured in the 1989 movie on the feet of Marty McFly, aka Michael J.Fox.`,
                },
                {
                    image: require("@/assets/images/blog/blog-bottom-shoe3.webp"),
                    title: "Nike is finally making the self-lacing shoes from Back To The Future!",
                    description: `Nike has announced that it is finally making a production run of its self-lacing shoes first introduced 30 years ago, in the future, on 21st October 2015.
                The shoes -- which as everyone who made it trough Back To Future Day must know -- were a fun sight gag which featured in the 1989 movie on the feet of Marty McFly, aka Michael J.Fox.`,
                },
                {
                    image: require("@/assets/images/blog/blog-bottom-shoe1.webp"),
                    title: "Nike is finally making the self-lacing shoes from Back To The Future!",
                    description: `Nike has announced that it is finally making a production run of its self-lacing shoes first introduced 30 years ago, in the future, on 21st October 2015.
                The shoes -- which as everyone who made it trough Back To Future Day must know -- were a fun sight gag which featured in the 1989 movie on the feet of Marty McFly, aka Michael J.Fox.`,
                },
                {
                    image: require("@/assets/images/blog/blog-bottom-shoe2.webp"),
                    title: "Nike is finally making the self-lacing shoes from Back To The Future!",
                    description: `Nike has announced that it is finally making a production run of its self-lacing shoes first introduced 30 years ago, in the future, on 21st October 2015.
                The shoes -- which as everyone who made it trough Back To Future Day must know -- were a fun sight gag which featured in the 1989 movie on the feet of Marty McFly, aka Michael J.Fox.`,
                },
            ]
        }
    },

    components: {
        Navigation,
    },

    mounted() {
        this.setMetaData();
    },

    methods: {
        setMetaData() {
            useHead({
                title: `BOLG | CUSTOM SNEAKERS`,
                meta: [
                    {
                        name: 'description',
                        content: ``
                    },
                ],
            });
        },
    }
}
</script>

<style scoped>
.empty-items {
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.empty-items img {
    max-width: 100%;
}

.main {
    min-height: 100vh;
}

.content-1 {
    display: flex;
    margin-top: 100px;
}

.blog-shoe {
    margin-top: 50px;
    max-height: 600px;
    max-width: 600px;
}

img {
    border-radius: 20px;
}

.intro-blog {
    display: flex;
    flex-direction: column;
}

.heading {
    margin-top: 80px;
    margin-left: 50px;
    max-width: 100%;
}

.blog-paragraph {
    margin-left: 50px;
}

.para {
    margin-top: 20px;
}

.read {
    margin-top: 20px;
    border: none;
    font-weight: 800;
    background-color: none;
}

.content-2 {
    max-width: 100%;
}

.content-2 .para {
    font-size: 13px;
    margin-top: 5px
}

.content-2 .heading {
    margin-top: 10px;
    font-size: 18px;
    margin-left: 0px;
}

.grid-box {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 50px;
    row-gap: 70px;
    margin-top: 50px;
    max-width: 100%;
}

.wrapper-image img {
    max-width: 100%;
    width: 100%;
    height: 200px;
    display: block;
    margin: 0;
    object-fit: cover;
}

.wrapper-image {
    max-width: 100%;
    width: 100%;
    display: inline-block;
}

.paragraph-new {
    max-height: 120px;
    max-width: 100%;
    overflow: hidden;
}

.content-2 .para {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
}

.para.with-fade {
    position: relative;
}

.para.with-fade:after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 40px;
    background: linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.7));
    pointer-events: none;
}

@media only screen and (max-width: 575px) {
    .grid-box {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        gap: 20px;
        row-gap: 70px;
        margin-top: 50px;
    }

    .wrapper-image img,
    .wrapper-image {
        width: 100%;
        height: auto;
    }
}

@media only screen and (max-width: 850px) {
    .content-1 {
        display: block;
    }

    img {
        max-width: 100%;
        max-height: 100%;
    }

    .heading,
    .blog-paragraph {
        margin-left: 0px;
        margin-top: 20px;
    }

    .blog-shoe {
        max-height: 100%;
        max-width: 100%;
        margin-top: 0
    }
}

@media only screen and (min-width: 500px) and (max-width: 850px) {
    .grid-box {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 20px;
        row-gap: 70px;
        margin-top: 50px;
    }
}
</style>