<template>
  <div>
    <Navigation />
    <Loader :loading="isLoading" />
    <NotificationBox :isVisible="notify.isVisible" :type="notify.type" :title="notify.title" :message="notify.message"
            @notificationIsVisible="notifyIsVisible()" />
    <div class="main bg-gradient">
      <div class="container res-px">
        <div class="contact-content">
          <div class="content-1 ">
            <h2 class="title">Contact us</h2>
            <p class="para">Need to get in touch with us? Fill the inquiry
              form or make a simple call to us</p>
            <div class="image-call ">
              <font-awesome-icon class="f-icon" :icon="['fa', 'phone']" />
              <h4 class="details">+94 77 811 6668</h4>
            </div>
            <div class="image-message ">
              <font-awesome-icon class="f-icon" :icon="['fa', 'envelope']" />
              <h4 class="details">info@customsneakers.lk</h4>
            </div>
          </div>

          <div class="content-2 py">
            <div class="form ">
              <div class="row">
                <div>
                  <input v-model="firstName" :class="errors.firstName === '' ? 'input' : 'input-error'" type="text" placeholder="First Name">
                  <p v-if="errors.firstName !== ''" class="txt-error txt-sm">{{ errors.firstName }}</p>
                </div>
                <div>
                  <input :class="errors.lastName === '' ? 'input' : 'input-error'" type="text" class="name" id="lname"
                    placeholder="Last Name" v-model="lastName">
                  <p v-if="errors.lastName !== ''" class="txt-error txt-sm">{{ errors.lastName }}</p>
                </div>
              </div>
              <div class="row">
                <div>
                  <input v-model="email" :class="errors.email === '' ? 'input' : 'input-error'" type="email" class="email"
                    placeholder="Email">
                  <p v-if="errors.email !== ''" class="txt-error txt-sm">{{ errors.email }}</p>
                </div>
              </div>
              <div class="row">
                <div>
                  <textarea :class="errors.message === '' ? 'textarea' : 'textarea-error'" id="message" name="message"
                    rows="10" cols="85" placeholder="Type your message here..." v-model="message"></textarea>
                  <p v-if="errors.message !== ''" class="txt-error txt-sm">{{ errors.message }}</p>
                </div>
              </div>
              <div class="row" id="btn">
                <BtnTxtMdRound :buttonText="'Submit'" @custom-click="submitContactForm()" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import { useHead } from '@vueuse/head';
import Navigation from '../components/common/navigation-com.vue';
import BtnTxtMdRound from '../components/common/btn-txt-md-round-com.vue';
import { contactFormSubmit } from '../services/api';
import Loader from '../components/common/loading-com.vue';
import NotificationBox from '../components/common/notification-box-com.vue';
export default {

  data() {
    return {
      isLoading: false,
      notify: { isVisible: false, type: '', title: '', message: '' },
      firstName: '',
      lastName: '',
      email: '',
      message: '',
      errors: {
        firstName: '',
        lastName: '',
        email: '',
        message: '',
      }
    }
  },

  components: {
    Navigation,
    BtnTxtMdRound,
    NotificationBox,
    Loader,
  },

  mounted() {
    this.setMetaData();
  },

  methods: {
    setMetaData() {
      useHead({
        title: `CONTACT | CUSTOM SNEAKERS`,
        meta: [
          {
            name: 'description',
            content: ``
          },
        ],
      });
    },

    notifyIsVisible(value) {
      this.notify = { isVisible: value, type: '', title: '', message: '' };
    },

    validation() {
      if (this.firstName === '') {
        this.errors.firstName = 'First name is Required!';
      } else {
        this.errors.firstName = '';
      }

      if (this.lastName === '') {
        this.errors.lastName = 'Last name is Required!'
      } else {
        this.errors.lastName = '';
      }

      if (this.email === '') {
        this.errors.email = 'Email is Required!'
      } else if (!/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(this.email)) {
        this.errors.email = 'Invalid Email Format!';
      } else {
        this.errors.email = '';
      }

      if (this.message === '') {
        this.errors.message = 'First name is Required!'
      } else {
        this.errors.message = '';
      }
    },

    async submitContactForm() {
      this.validation();

      if (this.errors.firstName === '' && this.errors.lastName === '' && this.errors.email === '' && this.errors.message === '') {
        const postData = {
          firstName: this.firstName,
          lastName: this.lastName,
          email: this.email,
          message: this.message
        }

        try {
          this.isLoading = true;
          const response = await contactFormSubmit(postData);
          this.isLoading = false;
          this.firstName = '';
          this.lastName = '';
          this.email = '';
          this.message = '';
          this.notify = { isVisible: true, type: 'success', title: response.data.title, message: response.data.message };
        } catch (error) {
          this.isLoading = false;
          this.notify = { isVisible: true, type: 'error', title: error.response.data.title, message: error.response.data.message };
        }
      }
    },
  }
}
</script>

<style scoped>
.txt-error {
  margin: 3px 0 0 10px;
  color: red;
}

.container {
  max-width: 1200px;

}

.contact-content {
  display: flex;
  margin-left: 50px;
}

.content-1 {
  margin-top: 50px;
  padding-right: 50px;
}

.title {
  padding-top: 150px;
  font-size: 40px;
}

.para {
  margin-top: 50px;
}

.f-icon {
  height: 40px;
  margin-top: 50px;
}

.image-call,
.image-message {
  display: flex;
  align-items: center;
}

.details {
  margin-top: 40px;
  margin-left: 20px;
}

.form {
  display: flex;
  flex-direction: column;
  margin-top: 220px;
  text-align: right;
  margin-left: 50px;
}

.row {
  margin-top: 20px;
  display: flex;
}

.row div {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.textarea {
  display: block;
  border: 1px solid white;
  border-radius: 20px;
  padding: 10px;
  outline: none;
  box-sizing: border-box;
}

.textarea-error {
  display: block;
  border: 1px solid red;
  border-radius: 20px;
  padding: 10px;
  outline: none;
  box-sizing: border-box;
}

.input {
  padding: 0 10px;
  height: 35px;
  width: 250px;
  border: 1px solid white;
  border-radius: 15px;
  outline: none;
  box-sizing: border-box;
  margin-right: 80px;
}

.input-error {
  padding: 0 10px;
  height: 35px;
  width: 250px;
  border: 1px solid red;
  border-radius: 15px;
  outline: none;
  box-sizing: border-box;
  margin-right: 80px;
}

@media only screen and (max-width: 1090px) {
  .contact-content {
    margin-left: 0px;
  }

  .row {
    display: block;
  }

  input {
    width: 100%;
    margin-right: 0px;
  }

  textarea {
    width: 100%;
    height: 100%;
  }

  #lname {
    margin-top: 20px;
  }

  .form {
    margin-top: 220px
  }

  .container {
    margin-top: -50px
  }

  #btn {
    text-align: center;
  }
}

@media only screen and (max-width: 575px) {

  .main,
  .title {
    padding-top: 30px;
  }

  .contact-content {
    margin-left: 0px;
    display: block;
  }

  .container {
    margin-left: 0px;
    margin-top: 50px;
  }


  .form {
    margin-top: 0px;
    margin-left: 0
  }

  .input, .input-error {
    width: 100%;
  }
}
</style>